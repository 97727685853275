import {
  getCatAction,
  successCatAction,
  getBlogListAction,
  successBlogListAction,
  getHomePageAction,
  successHomepageAction,
  successTermsofuseActionAction,
  getTermsofuseAction,
  getTermAndConditionAction,
  successTermAndConditionAction,
  successaboutusAction,
  getaboutusAction,
  getFaqsAction,
  successFaqsAction,
  getPrivacyPolicyAction,
  successPrivacyPolicyAction,
  getFooterDataAction,
  suscessFooterAction,
  getverifyemailDataAction,
  suscessverifyemailAction,
  suscessforgotpassAction,
  getforgotpassDataAction,
  suscessloginAction,
  getloginDataAction,
  successCreateAccountDataAction,
  getCreateAccountDataAction,
  getForgotPasswordDataAction,
  successForgotPasswordDataAction,
  getItineraryDataAction,
  successItineraryDataAction,
  getFavouritePlacesAction,
  SuccessFavouritePlacesAction,
  getTourListingAction,
  successTourListingAction,
  getCancellationPolicyAction,
  successCancellationPolicy,
  getAdditionalInfoAction,
  successAdditionalInfoPolicy,
  getContaInfoAction,
  successContactInfoAction,
  postUsersSignUpAction,
  successUsersSignUpAction,
  postUsersSignInAction,
  successUsersSignInAction,
  postForgotPasswordAction,
  successForgotPasswordAction,
  postResetPasswordAction,
  postVerifyOtpAction,
  successVerifyOtpAction,
  successPostResetPasswordAction,
  getBlogDataAction,
  successGetBlogAction,
  postCreateBlogAction,
  successCreateBlogAction,
  getAboutDataAction,
  successGetAboutAction,
  postCreateAboutusAction,
  successCreateAboutusAction,
  getLandingDataAction,
  successGetLandingAction,
  successCreateLandingPageAction,
  postCreateLandingPageAction,
  getChatUsersAction,
  successChatUsersAction,
  getChatGroupsAction,
  successChatGroupsAction,
  getChatMessagesAction,
  successChatMessagesAction,
} from "../reduxState/slices/BuddhaJourneySlices";
import { call, all, put, takeLatest } from "redux-saga/effects";
import * as Api from "../services/Api";
// import { blogCardData } from "../Data";
import BlogList from "../Data/BlogList.json";
import homepageData from "../Data/landingpage.json";
import TermsofuseData from "../Data/TermOfUse.json";
import TermsAndConditions from "../Data/TermsAndConditions.json";
import AboutUs from "../Data/AboutUs.json";
import privacyPolicy from "../Data/PrivacyPolicy.json";
import footer from "../Data/Footer.json";
import verifyemailvalue from "../Data/verifyEmail.json";
import forgotpassvalue from "../Data/forgotpass.json";
import loginvalue from "../Data/Login.json";
import CreateAccount from "../Data/CreateAccount.json";
import forgotPassword from "../Data/ForgotPassword.json";
import itinerary from "../Data/Itinerary.json";
import tourPackage from "../Data/tourPackage.json";
import favouritePlaces from "../Data/favouritePlaces.json";
import additionalInfo from "../Data/AdditionalInfo.json";
import contactInfo from "../Data/contact.json";
import faq from "../Data/Faq.json";
import cancellationPolicy from "../Data/cancellationPolicy.json";
// worker Saga: will be fired on USER_FETCH_REQUESTED actions
function* fetchUser(action) {
  try {
    let payloadData = action.payload;
    const response = yield call(Api.fetchCatApiResults, payloadData);
    yield put(successCatAction(response));
    // const user = yield call(Api.fetchUser, action.payload.userId)
    // yield put({ type: 'USER_FETCH_SUCCEEDED', user: user })
  } catch (e) {
    console.log("catch");
  }
}

function* postUsersSignUp(action) {
  try {
    let payloadData = action.payload;
    const response = yield call(Api.postUsersSignUpApiResults, payloadData);
    const data = response.data;
    console.log("01-04-24 08", response);
    if (response.status === 200) {
      const parseDataSignUp = JSON.parse(data);
      yield put(successUsersSignUpAction(parseDataSignUp));
    }
    // yield put(
    //   // showToastAction({ message: response?.message?.message, type: "success" })
    //   showToastAction({ message: "Check your mail for otp", type: "success" })
    // );
  } catch (err) {
    const errData = err.response.data;

    let errDataToObj = JSON.parse(errData);

    // yield put(
    //   showToastAction({
    //     message: errDataToObj?.message?.message,
    //     type: "error",
    //   })
    // );
  }
}
function* getLandingData(action) {
  try {
    let payloadData = action.payload;
    console.log("16-03-24 01", payloadData);
    const response = yield call(Api.getLandingApiResults, payloadData);
    console.log("16-03-24 02", response);
    if (response.status) {
      const data = response.data;
      console.log("16-03-24 03", typeof data);
      yield put(successGetLandingAction(data));
    }
    // const data = response.data;
    // if (response.status === 200) {
    //   let updatedData = JSON.parse(data);
    //   yield put(successUsersFullDetailsAction(updatedData));
    // } else {
    // }
  } catch (err) {
    alert(err);
    // yield put(getResultsError(getCustomError(err)))
  }
}
function* getAboutData(action) {
  try {
    let payloadData = action.payload;
    console.log("16-03-24 01", payloadData);
    const response = yield call(Api.getAboutApiResults, payloadData);
    console.log("16-03-24 02", response);
    if (response.status) {
      const data = response.data;
      console.log("16-03-24 03", data);
      yield put(successGetAboutAction(data));
    }
    // const data = response.data;
    // if (response.status === 200) {
    //   let updatedData = JSON.parse(data);
    //   yield put(successUsersFullDetailsAction(updatedData));
    // } else {
    // }
  } catch (err) {
    alert(err);
    // yield put(getResultsError(getCustomError(err)))
  }
}
function* getBlogData(action) {
  try {
    let payloadData = action.payload;
    const response = yield call(Api.getBlogApiResults, payloadData);
    if (response.status) {
      const data = response.data;
      yield put(successGetBlogAction(data));
    }
    // const data = response.data;
    // if (response.status === 200) {
    //   let updatedData = JSON.parse(data);
    //   yield put(successUsersFullDetailsAction(updatedData));
    // } else {
    // }
  } catch (err) {
    alert(err);
    // yield put(getResultsError(getCustomError(err)))
  }
}
function* postCreateLandingPage(action) {
  try {
    let payloadData = action.payload;
    console.log("16-03-24 101 01", payloadData);
    const response = yield call(
      Api.postCreateLandingPageApiResults,
      payloadData
    );
    console.log("16-03-24 101 02", response);
    const responseObject = JSON.parse(response.data);

    if (response.status === 200) {
      yield put(successCreateLandingPageAction(responseObject));
      // yield put(

      //   showToastAction({ message: "successfully publushed", type: "success" })
      // );
    } else {
      alert(response.status);
    }
  } catch (err) {
    const errData = err.response.data;
    let errDataToObj = JSON.parse(errData);
    // yield put(
    //   showToastAction({ message: errDataToObj.message, type: "error" })
    // );
  }
}
function* postCreateAboutus(action) {
  try {
    let payloadData = action.payload;
    console.log("16-03-24 101 01", payloadData);
    const response = yield call(Api.postCreateAboutusApiResults, payloadData);
    console.log("16-03-24 101 02", response);
    const responseObject = JSON.parse(response.data);

    if (response.status === 200) {
      yield put(successCreateAboutusAction(responseObject));
      // yield put(

      //   showToastAction({ message: "successfully publushed", type: "success" })
      // );
    } else {
      alert(response.status);
    }
  } catch (err) {
    const errData = err.response.data;
    let errDataToObj = JSON.parse(errData);
    // yield put(
    //   showToastAction({ message: errDataToObj.message, type: "error" })
    // );
  }
}
function* postCreateBlog(action) {
  try {
    let payloadData = action.payload;
    console.log("15-03-24 101 01", payloadData);
    const response = yield call(Api.postCreateBlogApiResults, payloadData);
    console.log("15-03-24 101 02", response);
    const responseObject = JSON.parse(response.data);

    if (response.status === 200) {
      yield put(successCreateBlogAction(responseObject));
      // yield put(

      //   showToastAction({ message: "successfully publushed", type: "success" })
      // );
    } else {
      alert(response.status);
    }
  } catch (err) {
    const errData = err.response.data;
    let errDataToObj = JSON.parse(errData);
    // yield put(
    //   showToastAction({ message: errDataToObj.message, type: "error" })
    // );
  }
}
function* postUsersSignIn(action) {
  try {
    let payloadData = action.payload;
    console.log("14-03-24 000 01", payloadData);
    const response = yield call(Api.postUsersSignInApiResults, payloadData);
    const data = response.data;
    console.log("14-03-24 000 02", data);
    if (response.status === 200) {
      let updatedData = JSON.parse(data);
      yield put(successUsersSignInAction(updatedData));
      //
    }
  } catch (err) {
    console.log("01 04 2024 05", err);
    const errData = err.response.data;
    let errDataToObj = JSON.parse(errData);
    console.log("01 04 2024 06", errDataToObj);
    yield put(successUsersSignInAction(errDataToObj));
    // if (payloadData.emailId === "pp@digipank.com") {
    //   yield put(successUsersSignInAction({ token: "tokenfortesing" }));
    //   window.localStorage.setItem(
    //     "token",
    //     "statictokenfortestinganduidesignteamtoken"
    //   );
    // }
    // const errData = err.response.data;
    // let errDataToObj = JSON.parse(errData);
    // yield put(
    //   showToastAction({ message: errDataToObj.message, type: "error" })
    // );
  }
}
function* postForgotPassword(action) {
  try {
    let payloadData = action.payload;
    console.log("15-03-24 02", payloadData);

    const response = yield call(Api.postForgotPasswordApiResults, payloadData);
    console.log("15-03-24 03", payloadData);

    const data = response.data;

    if (response.status === 200) {
      let dataObject = JSON.parse(data);
      yield put(successForgotPasswordAction(dataObject));
      // {
      //   dataObject.status === true
      //     ? yield put(
      //         showToastAction({ message: dataObject.message, type: "success" })
      //       )
      //     : null;
      // }
    } else {
      alert("err");
    }
  } catch (err) {
    // let errorMsg = err.response.data;
    // let errorMsgToObj = JSON.parse(errorMsg);
    // yield put(
    //   showToastAction({ message: errorMsgToObj.message, type: "error" })
    // );
  }
}
function* postVerifyOtp(action) {
  try {
    let payloadData = action.payload;
    const response = yield call(Api.postVerifyOtpApiResults, payloadData);
    const data = response.data;
    if (response.status === 200) {
      let dataObject = JSON.parse(data);
      yield put(successVerifyOtpAction(dataObject));
      // {
      //   dataObject.status === true
      //     ? yield put(
      //         showToastAction({ message: dataObject.message, type: "success" })
      //       )
      //     : null;
      // }
    } else {
      alert("err");
    }
  } catch (err) {
    alert(err);
    // ------
    // yield put(getResultsError(getCustomError(err)))
  }
}
function* changePassword(action) {
  try {
    let payloadData = action.payload;
    const response = yield call(Api.changePasswordApiResults, payloadData);
    const data = response.data;
    // if (response.status === 200) {
    //   yield put(successChangePasswordAction(data));
    //   const changePasswordData = JSON.parse(data);

    //   yield put(
    //     showToastAction({
    //       message: changePasswordData.message,
    //       type: "success",
    //     })
    //   );
    // } else {
    //   alert(response.status);
    // }
  } catch (err) {
    // yield put(getResultsError(getCustomError(err)))
    // const errResponseChangePswd = JSON.parse(err.response.data);
    // yield put(
    //   showToastAction({
    //     message: errResponseChangePswd.message,
    //     type: "error",
    //   })
    // );
  }
}
function* postResetPassword(action) {
  try {
    let payloadData = action.payload;
    const response = yield call(Api.postResetPasswordApiResults, payloadData);
    const data = response.data;
    if (response.status === 200) {
      let updatedData = JSON.parse(data);
      yield put(successPostResetPasswordAction(updatedData));
      // {
      //   updatedData.status === true
      //     ? yield put(
      //         showToastAction({
      //           message: updatedData.message,
      //           type: "success",
      //         })
      //       )
      //     : null;
      // }
    } else {
      alert(response.status);
    }
  } catch (err) {
    alert(err);
    // yield put(getResultsError(getCustomError(err)))
  }
}

function* fetchBlogList() {
  try {
    // let payloadData = action.payload;
    // const response = yield call(Api.fetchCatApiResults, payloadData);
    // console.log(response ,"response")
    yield put(successBlogListAction(BlogList));

    console.log("buddha journey saga bloglist action  called");
    // const user = yield call(Api.fetchUser, action.payload.userId)
    // yield put({ type: 'USER_FETCH_SUCCEEDED', user: user })
  } catch (e) {
    console.log("catch");
  }
}
function* fetchCreateAccountData() {
  console.log("buddha journey saga fetchCreateAccountData");
  try {
    yield put(successCreateAccountDataAction(CreateAccount));
  } catch (e) {
    console.log("catch");
  }
}
function* fetchItineraryData() {
  try {
    yield put(successItineraryDataAction(itinerary));

    console.log("buddha journey saga bloglist action  called");
  } catch (e) {
    console.log("catch");
  }
}
function* fetchForgotPasswordData() {
  try {
    yield put(successForgotPasswordDataAction(forgotPassword));

    console.log("buddha journey saga bloglist action  called");
  } catch (e) {
    console.log("catch");
  }
}
function* fetchHomepageData(action) {
  try {
    console.log(action, "action called for homepagedata");
    yield put(successHomepageAction(homepageData));
  } catch (e) {
    console.log("error catched");
  }
}

function* fetchTermsofuseActionData(action) {
  try {
    console.log(action, "action called for Termsofusedata");
    yield put(successTermsofuseActionAction(TermsofuseData));
  } catch (e) {
    console.log("error catched");
  }
}
function* fetchTermAndConditionActionData(action) {
  try {
    console.log(action, "action called for TermAndCondition");
    yield put(successTermAndConditionAction(TermsAndConditions));
  } catch (e) {
    console.log("error catched");
  }
}
function* fetchaboutusActionData(action) {
  try {
    console.log(action, "action called for TermAndCondition");
    yield put(successaboutusAction(AboutUs));
  } catch (e) {
    console.log("error catched");
  }
}
function* fetchFaqsData(action) {
  try {
    console.log(action, "action called for aboutus");
    yield put(successFaqsAction(faq));
  } catch (error) {
    console.log(error, "error at fetchfaqdata");
  }
}
function* fetchPrivacyPolicyData(action) {
  try {
    console.log(action, "action triggered");
    yield put(successPrivacyPolicyAction(privacyPolicy));
  } catch (e) {
    console.log("error", e);
  }
}
function* fetchFooterData(action) {
  try {
    // console.log("action triggered", action);
    yield put(suscessFooterAction(footer));
  } catch (e) {
    console.log("error", e);
  }
}
function* fetchverifyemailData(action) {
  try {
    console.log("action triggered", action);
    yield put(suscessverifyemailAction(verifyemailvalue));
  } catch (e) {
    console.log("error", e);
  }
}
function* fetchforgotpassData(action) {
  try {
    console.log("action triggered", action);
    yield put(suscessforgotpassAction(forgotpassvalue));
  } catch (e) {
    console.log("error", e);
  }
}
function* fetchloginData(action) {
  try {
    console.log("action triggered", action);
    yield put(suscessloginAction(loginvalue));
  } catch (e) {
    console.log("error", e);
  }
}
function* fetchFavouritePlacesData(action) {
  try {
    yield put(SuccessFavouritePlacesAction(favouritePlaces));
  } catch (e) {
    console.log("error", e);
  }
}
function* fetchTourListingData(action) {
  console.log("saga fetchTourListingData called", action);
  try {
    yield put(successTourListingAction(tourPackage));
  } catch (e) {
    console.log("error", e);
  }
}
function* fetchCancellationPolicyData(action) {
  try {
    yield put(successCancellationPolicy(cancellationPolicy));
  } catch (e) {
    console.log("error", e);
  }
}
function* fetchAdditionalInfoData(action) {
  try {
    yield put(successAdditionalInfoPolicy(additionalInfo));
  } catch (e) {
    console.log("error", e);
  }
}
function* fetchContactInfoData(action) {
  console.log("called saga contact");
  try {
    yield put(successContactInfoAction(contactInfo));
  } catch (e) {
    console.log("error", e);
  }
}
function* fetchChatUsers(action) {
  try {
    const payloadData = action.payload;
    const response = yield call(Api.getChatUsers, payloadData);

    if (response.status) {
      const data = response.data;
      yield put(successChatUsersAction(data));
    } else {
      alert(response.status);
    }
  } catch (err) {
    alert(err);
  }
}
function* fetchChatGroups(action) {
  try {
    const payloadData = action.payload;
    const response = yield call(Api.getChatGroups, payloadData);

    if (response.status) {
      const data = response.data;
      yield put(successChatGroupsAction(data));
    } else {
      alert(response.status);
    }
  } catch (err) {
    alert(err);
  }
}
function* fetchChatMessages(action) {
  try {
    const payloadData = action.payload;
    const response = yield call(Api.getChatMessages, payloadData);
    console.log("response.data ===>", response);

    if (response.status) {
      const data = response.data;
      yield put(successChatMessagesAction(data));
    } else {
      alert(response.status);
    }
  } catch (err) {
    alert(err);
  }
}
/*
  Alternatively you may use takeLatest.

  Does not allow concurrent fetches of user. If "USER_FETCH_REQUESTED" gets
  dispatched while a fetch is already pending, that pending fetch is cancelled
  and only the latest one will be run.
*/
function* BuddhaJourneySaga() {
  yield all([
    takeLatest(postCreateLandingPageAction.type, postCreateLandingPage),
    takeLatest(postCreateAboutusAction.type, postCreateAboutus),
    takeLatest(postCreateBlogAction.type, postCreateBlog),
    takeLatest(getBlogDataAction.type, getBlogData),
    takeLatest(getAboutDataAction.type, getAboutData),
    takeLatest(getLandingDataAction.type, getLandingData),
    takeLatest(postVerifyOtpAction.type, postVerifyOtp),
    takeLatest(postResetPasswordAction.type, postResetPassword),
    takeLatest(postForgotPasswordAction.type, postForgotPassword),
    takeLatest(postUsersSignInAction.type, postUsersSignIn),
    takeLatest(postUsersSignUpAction.type, postUsersSignUp),
    takeLatest(getCatAction.type, fetchUser),
    takeLatest(getBlogListAction.type, fetchBlogList),
    takeLatest(getCreateAccountDataAction.type, fetchCreateAccountData),
    takeLatest(getItineraryDataAction.type, fetchItineraryData),
    takeLatest(getForgotPasswordDataAction.type, fetchForgotPasswordData),
    takeLatest(getHomePageAction.type, fetchHomepageData),
    takeLatest(getTermsofuseAction.type, fetchTermsofuseActionData),
    takeLatest(getTermAndConditionAction.type, fetchTermAndConditionActionData),
    takeLatest(getTermAndConditionAction.type, fetchTermAndConditionActionData),
    takeLatest(getaboutusAction.type, fetchaboutusActionData),
    takeLatest(getFaqsAction.type, fetchFaqsData),
    takeLatest(getPrivacyPolicyAction.type, fetchPrivacyPolicyData),
    takeLatest(getFooterDataAction.type, fetchFooterData),
    takeLatest(getverifyemailDataAction.type, fetchverifyemailData),
    takeLatest(getforgotpassDataAction.type, fetchforgotpassData),
    takeLatest(getloginDataAction.type, fetchloginData),
    takeLatest(getFavouritePlacesAction.type, fetchFavouritePlacesData),
    takeLatest(getTourListingAction.type, fetchTourListingData),
    takeLatest(getCancellationPolicyAction.type, fetchCancellationPolicyData),
    takeLatest(getAdditionalInfoAction.type, fetchAdditionalInfoData),
    takeLatest(getContaInfoAction.type, fetchContactInfoData),
    takeLatest(getChatUsersAction.type, fetchChatUsers),
    takeLatest(getChatGroupsAction.type, fetchChatGroups),
    takeLatest(getChatMessagesAction.type, fetchChatMessages),
  ]);
}
export default BuddhaJourneySaga;
