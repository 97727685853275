import TermAndPolicy from "../../components/Reusable/TermAndPolicy/TermAndPolicy";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPrivacyPolicyAction } from "../../reduxState/slices/BuddhaJourneySlices";
const PrivacyPolicy = () => {
  const dispatch = useDispatch();
  const [privacyData] = useState([]);
  useEffect(() => {
    //Privacy Policy page api call
    dispatch(getPrivacyPolicyAction());
  }, [dispatch]);
  const stateValue = useSelector(
    (data) => data.buddhaJourneySlice.privacyPolicy.privacyPolicyData
  );

  if (typeof stateValue.heading !== "string") {
    return <div>...loading</div>;
  } else {
    privacyData[0] = stateValue;
    return <TermAndPolicy arrayName={privacyData} />;
  }
};

export default PrivacyPolicy;
