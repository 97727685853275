import { combineReducers } from "redux";
import { BuddhaJourneySlice } from "./slices/BuddhaJourneySlices";
import { connectRouter } from "connected-react-router";

export function createRootReducer(history) {
    return combineReducers({
      router: connectRouter(history),
      buddhaJourneySlice: BuddhaJourneySlice.reducer,
    });
  }
  