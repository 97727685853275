import { Box, Typography, Button, CardMedia } from "@mui/material";
import couponImg from "../../assets/images/couponImg.svg";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ShareIcon from "@mui/icons-material/Share";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import "./CouponModal.scss";
import { useState } from "react";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: "55%",
  bgcolor: "background.paper",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
};
const CouponModal = () => {
  const [couponCode,setCouponCode]=useState('XXX-XXX-XXX')
    const handleGenerate=()=>{
      const coupon = Math.floor(Math.random() * 900000000) + 100000000;
      const formattedCoupon = coupon.toString().replace(/(\d{3})(\d{3})(\d{3})/, '$1-$2-$3');
      setCouponCode(formattedCoupon);
    }
  return (
    <Box className="couponBlock">
      <Box sx={style}>
        <Box sx={{ textAlign: "center" }}>
          <Typography variant="footerHead" color="primary">
            Coupon For Your Package
          </Typography>
        </Box>
        <Box className="hurryTextBlock">
          <Typography variant="boldSixteen" color="#000">
            Hurry! Limited Coupon for first 20 users
          </Typography>
        </Box>
        <Box className="clickMeBtn">
          <Button variant="outlined" onClick={handleGenerate}>Generate Coupon</Button>
        </Box>
        <Box className="picHeading">
          <Box>
            <Typography variant="latoSemiBoldTwentyFive" color="#000">
              Coupon Code
            </Typography>
          </Box>
          <Box className="iconBlock">
          <Box mr={1}>
              <ContentCopyIcon />
            </Box>
            <Box mr={1}>
              <FileDownloadIcon />
            </Box>
            <Box>
              <ShareIcon />
            </Box>
          </Box>
        </Box>
        <Box className="imgBlock">
          <CardMedia image={couponImg} title="BuddhaJourney" component="img" />

          <Box className="theCoupon">
            <Typography color="#fff">{couponCode}</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default CouponModal;
