import React from "react";
import "./BlogAdCard.scss";
import { Box, Button, CardMedia, Typography } from "@mui/material";
import blogbottomimg from "../../../assets/images/blogbottomimg.png";
import { Link } from "react-router-dom";
const BlogAdCard = (props) => {
  const { title, btnTitle, image } = props;
  return (
    <Box className="BlogAdCard">
      <Box className="image_wrapper">
        <CardMedia component="img" image={image} />
        <Box className="center_text">
          <Box>
            <Typography variant="semiBoldSixtyEight">{title}</Typography>
            <Box className="topMargin">
              <Link to="/faqs#contact-us">
                <Button className="bookNow" variant="formButton">
                  {btnTitle}
                </Button>
              </Link>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
BlogAdCard.defaultProps = {
  image: blogbottomimg,
  title: "Book your desired destination now",
  btnTitle: "Book Now",
};
export default BlogAdCard;
