import React, { useEffect,useState} from "react";
import "./CancellationPolicy.scss";
import TermAndPolicy from "../../components/Reusable/TermAndPolicy/TermAndPolicy"
import { useDispatch, useSelector } from "react-redux";
import { getCancellationPolicyAction } from '../../reduxState/slices/BuddhaJourneySlices';
const CancellationPolicy = () => {
  const dispatch = useDispatch();
  const [cancellationData] = useState([]);

  useEffect(() => {
    //TermsofuseData page api call
    dispatch(getCancellationPolicyAction());
  }, [dispatch]);
  const stateValue = useSelector(
    (data) => data.buddhaJourneySlice.cancellationPolicy.cancellationPolicyData
  );
  if (typeof stateValue.heading !== "string") {
    return <div>...loading</div>;
  } else {
    cancellationData[0] = stateValue;
    return <TermAndPolicy arrayName={cancellationData} />;
  }
}

export default CancellationPolicy