import { Box, CardMedia, Typography } from "@mui/material";
import React from "react";
import DrawerAppBar from "../../components/Global/Navbar/Navbar";
import "./BlogFive.scss";
import Carousel from "react-multi-carousel";
import Footer from "../../components/Global/Footer/Footer";
import blogFiveImage from "../../assets/images/blogFiveImage.png";
import IndianDestCard from "../../components/Reusable/IndianDestCard/IndianDestCard";
import BlogAdCard from "../../components/Reusable/BlogAdCard/BlogAdCard";
import { blogCardData, responsiveIndianCard } from "../../Data.js";
import CenterText from "../../components/Reusable/CenterText/CenterText";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const BlogFive = () => {
  const stateValue = useSelector(
    (e) => e.buddhaJourneySlice.blogList.blogListValue
  );
  const { id } = useParams();
  return (
    <Box>
      <Box className="blogFiveBlock">
        <DrawerAppBar />
        <Box className="titleWrapper">
          <Typography
            variant="boldFourtyEight"
            color="secondary"
            className="title"
          >
            {/* Lorem Ipsum is simply dummy text of the printing */}
            {stateValue.cardData[id].CardHeading}
          </Typography>
        </Box>
        <Box className="paraOne">
          <Typography variant="semiBoldTwentyFour">
          {stateValue.cardData[id].paraOne}
          </Typography>
        </Box>
        <Box>
          <CardMedia
            image={blogFiveImage}
            title="BuddhaJourney"
            // height={height}
            component="img"
          />
        </Box>
        <Box className="paraTwo">
          <Typography variant="semiBoldTwentyFour">
          {stateValue.cardData[id].paraTwo}

          </Typography>
        </Box>
        <Box>
          <Typography variant="semiBoldTwentyFour">
          {stateValue.cardData[id].paraThree}

          </Typography>
        </Box>
        <CenterText />
        <Box className="bloglistMarginBottom">
          <Carousel responsive={responsiveIndianCard}>
            {blogCardData?.cardData.map((element) => {
              return (
                <Box>
                  <IndianDestCard
                    key={element.id}
                    img={element.img}
                    para={element.para}
                    Rating={element.Rating}
                    CardHeading={element.CardHeading}
                    isTrue={element.isTrue}
                  />
                </Box>
              );
            })}
          </Carousel>
        </Box>
        <Box className="blogAd">
          <BlogAdCard />
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};

export default BlogFive;
