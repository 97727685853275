import React from "react";
import { Box, Typography } from "@mui/material";
import "./SignInSignup.scss";
import useWindowDimensions from "../../components/Reusable/CustomHooks/useWindowDimensions";
import logo from "../../assets/images/logo.png";

import { useSelector } from "react-redux";
import { red } from "@mui/material/colors";
import { useNavigate } from "react-router-dom";
const SignInSignup = () => {
  const createAccount = useSelector(
    (e) => e.buddhaJourneySlice.createAccountData.createAccountValue
  );
  const { height } = useWindowDimensions();
  console.log("height", height);
  let navigate = useNavigate();
  const onLogoClick = () => {
    navigate("/");
  };
  return (
    <Box className="signinSignup" sx={{ height: `${height}px` }}>
      <Box
        className="logo"
        sx={{ display: { xs: "none", md: "block" }, cursor: "pointer" }}
      >
        <img
          className="avatar"
          src={logo}
          alt="Buddha Logo"
          onClick={() => onLogoClick()}
        />
      </Box>
      <Box className="textBlock">
        <Box>
          <Typography variant="boldFourtyEightWhite" sx={{ color: "white" }}>
            {createAccount?.quoteHeading
              ? createAccount?.quoteHeading
              : "Keep It Special"}
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography className="subheadingClass">
            {createAccount?.quoteHeading
              ? createAccount?.quote
              : "Capture The Best Memory In a Unique Way."}
          </Typography>
        </Box>
      </Box>
    </Box>
    // </Box>
  );
};

export default SignInSignup;
