import temple from "../src/assets/images/temple.jpg";
import sarnath from "../src/assets/images/sarnath.png";
import shravasti from "../src/assets/images/shravasti.jpg";
import andaman from "../src/assets/images/andaman.jpg";
import kashmir from "../src/assets/images/kashmir.jpg";
import gujrat from "../src/assets/images/gujrat.jpg";
import kerla from "../src/assets/images/kerla.jpg";
import himachal from "../src/assets/images/himachal.jpg";
import resortone from "./assets/images//resortone.png";
import resorttwo from "./assets/images/resorttwo.png";
import blogOneImg from "./assets/images/blogOneImg.png";
import blogOneBody from "./assets/images/blogOneBody.png";
import blogtwoimg from "./assets/images/blogtwoimg.png";
import blogthreeimg from "./assets/images/blogthreeimg.png";
import mapimg from "./assets/images/mapimg.png";
import privacypolicy from "./assets/images/privacypolicy.png";
import car from "./assets/images/car.svg";
import ballon from "./assets/images/ballon.svg";
import dollar from "./assets/images/dollar.svg";
import time from "./assets/images/time.svg";
import termofuse from "./assets/images/termofuse.svg";
import policy from "./assets/images/policy.svg";

export const aboutUsData = {
  pageName: "About us",
  textData: [
    "Welcome to our travel website, where unforgettable adventures await you!",
    "We are passionate about creating extraordinary experiences and helping you explore the world’s most captivating destinations. With our extensive knowledge and expertise, we curate remarkable itineraries that cater to your unique interests and preferences. Whether you’re seeking cultural immersion, natural wonders, or thrilling adventures, we have something for everyone.",
    "Our dedicated team of travel experts ensures seamless planning, personalized attention, and impeccable service throughout your journey.Trust us to guide you through the enchanting landscapes, vibrant cultures, and hidden gems of our handpicked destinations. ",
    "Embark on a transformative travel experience with us and create memories that will last a lifetime. Let your wanderlust take flight and let us be your trusted companion on this remarkable journey.Welcome to our travel website, where unforgettable adventures await you!We are passionate about creating extraordinary experiences and helping you explore the world’s most captivating destinations. With our extensive knowledge and expertise, we curate remarkable itineraries that cater to your unique interests and preferences. Whether you’re seeking cultural immersion, natural wonders, or thrilling adventures, we have something for everyone.",
    "Our dedicated team of travel experts ensures seamless planning, personalized attention, and impeccable service throughout your journey.Trust us to guide you through the enchanting landscapes, vibrant cultures, and hidden gems of our handpicked destinations.",
    "",
  ],
};
export const featureCardData = [
  {
    id: 1,
    img: car,
    heading: "Private Transport",
    para: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.",
  },
  {
    id: 2,
    img: ballon,
    heading: "Special Activities",
    para: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.",
  },
  {
    id: 3,
    img: dollar,
    heading: "Value for Money",
    para: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.",
  },
  {
    id: 4,
    img: time,
    heading: "Optimal Timing",
    para: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.",
  },
];
export const adventureHeadingData = {
  id: 1,
  title: "Adventure Is The Best Therapy",
  description:
    "Adventure is like medicine for the soul , feel better and help us grow.  Explore new places and try new things, brings us joy and makes us stronger. Adventure is the best way to heal and feel happy.",
};
export const indianCardData = {
  id: 1,
  title: "Trending Indian Destination",
  description:
    "Experience of India's trending destinations, where ancient traditions intertwine with modern charm, offering a rich tapestry of cultural richness and breathtaking natural beauty for travelers to indulge in.offering a rich tapestry of cultural richness and breathtaking natural beauty for travelers to indulge in.offering a rich tapestry of cultural richness and breathtaking natural beauty for travelers to indulge in.",
  viewMore: "View All Tours",
  cardData: [
    {
      id: 1,
      img: temple,
      CardHeading: "BodhGaya",
      Rating: 4.8,
      para: "Bodhgaya is a special city in India where Gautama Buddha became enlightened. Many people visit the famous Mahabodhi Temple there to find peace and wisdom. The calm atmosphere and strong Buddhist history make Bodhgaya a meaningful place to visit.",
      rate: "10,000",
      days: "3 Nights 4 Days",
      priceAndBook: true,
    },
    {
      id: 2,
      img: sarnath,
      CardHeading: "Sarnath",
      Rating: 4.8,
      para: "Bodhgaya is a special city in India where Gautama Buddha became enlightened. Many people visit the famous Mahabodhi Temple there to find peace and wisdom. The calm atmosphere and strong Buddhist history make Bodhgaya a meaningful place to visit.",
      rate: "10,000",
      days: "3 Nights 4 Days",
      priceAndBook: true,
    },
    {
      id: 3,
      img: shravasti,
      CardHeading: "Shravasti",
      Rating: 4.8,
      para: "Bodhgaya is a special city in India where Gautama Buddha became enlightened. Many people visit the famous Mahabodhi Temple there to find peace and wisdom. The calm atmosphere and strong Buddhist history make Bodhgaya a meaningful place to visit.",
      rate: "10,000",
      days: "3 Nights 4 Days",
      priceAndBook: true,
    },
    {
      id: 4,
      img: sarnath,
      CardHeading: "Sarnath",
      Rating: 4.8,
      para: "Bodhgaya is a special city in India where Gautama Buddha became enlightened. Many people visit the famous Mahabodhi Temple there to find peace and wisdom. The calm atmosphere and strong Buddhist history make Bodhgaya a meaningful place to visit.",
      rate: "10,000",
      days: "3 Nights 4 Days",
      priceAndBook: true,
    },
    {
      id: 5,
      img: temple,
      CardHeading: "BodhGaya",
      Rating: 4.8,
      para: "Bodhgaya is a special city in India where Gautama Buddha became enlightened. Many people visit the famous Mahabodhi Temple there to find peace and wisdom. The calm atmosphere and strong Buddhist history make Bodhgaya a meaningful place to visit.",
      rate: "10,000",
      days: "3 Nights 4 Days",
      priceAndBook: true,
    },
    {
      id: 6,
      img: sarnath,
      CardHeading: "Sarnath",
      Rating: 4.8,
      para: "Bodhgaya is a special city in India where Gautama Buddha became enlightened. Many people visit the famous Mahabodhi Temple there to find peace and wisdom. The calm atmosphere and strong Buddhist history make Bodhgaya a meaningful place to visit.",
      rate: "10,000",
      days: "3 Nights 4 Days",
      priceAndBook: true,
    },
    {
      id: 7,
      img: shravasti,
      CardHeading: "Shravasti",
      Rating: 4.8,
      para: "Bodhgaya is a special city in India where Gautama Buddha became enlightened. Many people visit the famous Mahabodhi Temple there to find peace and wisdom. The calm atmosphere and strong Buddhist history make Bodhgaya a meaningful place to visit.",
      rate: "10,000",
      days: "3 Nights 4 Days",
      priceAndBook: true,
    },
    {
      id: 8,
      img: sarnath,
      CardHeading: "Sarnath",
      Rating: 4.8,
      para: "Bodhgaya is a special city in India where Gautama Buddha became enlightened. Many people visit the famous Mahabodhi Temple there to find peace and wisdom. The calm atmosphere and strong Buddhist history make Bodhgaya a meaningful place to visit.",
      rate: "10,000",
      days: "3 Nights 4 Days",
      priceAndBook: true,
    },
  ],
};
export const holidayCardData = {
  id: 1,
  title: "Trending Holidays Destinations",
  description:
    "Experience of India's trending destinations, where ancient traditions intertwine with modern charm, offering a rich tapestry of cultural richness and breathtaking natural beauty for travelers to indulge in.offering a rich tapestry of cultural richness and breathtaking natural beauty for travelers to indulge in.offering a rich tapestry of cultural richness and breathtaking natural beauty for travelers to indulge in.",
  viewMore: "View All Tours",
  cardData: [
    {
      id: 1,
      im: andaman,
      destination: "ANDAMAN",
      price: "10,900",
    },
    {
      id: 2,
      im: gujrat,
      destination: "GUJRAT",
      price: "10,900",
    },
    {
      id: 3,
      im: kerla,
      destination: "KERLA",
      price: "10,900",
    },
    {
      id: 4,
      im: kashmir,
      destination: "KASHMIR",
      price: "10,900",
    },
    {
      id: 5,
      im: himachal,
      destination: "HIMACHAL",
      price: "10,900",
    },
    {
      id: 6,
      im: gujrat,
      destination: "GUJRAT",
      price: "10,900",
    },
    {
      id: 7,
      im: kerla,
      destination: "ANDAMAN",
      price: "10,900",
    },
    {
      id: 8,
      im: kashmir,
      destination: "kASHMIR",
      price: "10,900",
    },
    {
      id: 9,
      im: himachal,
      destination: "HIMACHAL",
      price: "10,900",
    },
    {
      id: 10,
      im: andaman,
      destination: "ANDAMAN",
      price: "10,900",
    },
    {
      id: 1,
      im: andaman,
      destination: "ANDAMAN",
      price: "10,900",
    },
    {
      id: 2,
      im: gujrat,
      destination: "GUJRAT",
      price: "10,900",
    },
    {
      id: 3,
      im: kerla,
      destination: "KERLA",
      price: "10,900",
    },
    {
      id: 4,
      im: kashmir,
      destination: "KASHMIR",
      price: "10,900",
    },
    {
      id: 5,
      im: himachal,
      destination: "HIMACHAL",
      price: "10,900",
    },
    {
      id: 6,
      im: gujrat,
      destination: "GUJRAT",
      price: "10,900",
    },
    {
      id: 7,
      im: kerla,
      destination: "ANDAMAN",
      price: "10,900",
    },
    {
      id: 8,
      im: kashmir,
      destination: "kASHMIR",
      price: "10,900",
    },
    {
      id: 9,
      im: himachal,
      destination: "HIMACHAL",
      price: "10,900",
    },
    {
      id: 10,
      im: andaman,
      destination: "ANDAMAN",
      price: "10,900",
    },
    {
      id: 11,
      im: andaman,
      destination: "ANDAMAN",
      price: "10,900",
    },
    {
      id: 12,
      im: gujrat,
      destination: "GUJRAT",
      price: "10,900",
    },
    {
      id: 13,
      im: kerla,
      destination: "KERLA",
      price: "10,900",
    },
    {
      id: 14,
      im: kashmir,
      destination: "KASHMIR",
      price: "10,900",
    },
    {
      id: 15,
      im: himachal,
      destination: "HIMACHAL",
      price: "10,900",
    },
    {
      id: 16,
      im: gujrat,
      destination: "GUJRAT",
      price: "10,900",
    },
    {
      id: 17,
      im: kerla,
      destination: "ANDAMAN",
      price: "10,900",
    },
    {
      id: 18,
      im: kashmir,
      destination: "kASHMIR",
      price: "10,900",
    },
  
  ],
};
export const specialHolidayCardData = {
  id: 1,
  title: "Special Holidays Destinations",
  description:
    "Experience of India's trending destinations, where ancient traditions intertwine with modern charm, offering a rich tapestry of cultural richness and breathtaking natural beauty for travelers to indulge in.offering a rich tapestry of cultural richness and breathtaking natural beauty for travelers to indulge in.offering a rich tapestry of cultural richness and breathtaking natural beauty for travelers to indulge in.",
  viewMore: "View All Tours",
  cardData: [
    {
      id: 1,
      im: andaman,
      destination: "ANDAMAN",
      price: "10,900",
    },
    {
      id: 2,
      im: gujrat,
      destination: "GUJRAT",
      price: "10,900",
    },
    {
      id: 3,
      im: kerla,
      destination: "KERLA",
      price: "10,900",
    },
    {
      id: 4,
      im: kashmir,
      destination: "KASHMIR",
      price: "10,900",
    },
    {
      id: 5,
      im: himachal,
      destination: "HIMACHAL",
      price: "10,900",
    },
    {
      id: 6,
      im: gujrat,
      destination: "GUJRAT",
      price: "10,900",
    },
    {
      id: 7,
      im: kerla,
      destination: "ANDAMAN",
      price: "10,900",
    },
    {
      id: 8,
      im: kashmir,
      destination: "kASHMIR",
      price: "10,900",
    },
    {
      id: 9,
      im: himachal,
      destination: "HIMACHAL",
      price: "10,900",
    },
    {
      id: 10,
      im: andaman,
      destination: "ANDAMAN",
      price: "10,900",
    },
  ],
};
export const blogCardData = {
  id: 1,
  title: "Blogs",
  description:
    "Experience a captivating journey with our engaging articles, practical tips, and inspiring stories. Let our blog fuel your desire for exploration, ensuring that your upcoming adventure transforms into a cherished and extraordinary lifetime memory. Immerse yourself and let wanderlust take you on an unforgettable ride.",
  viewMore: "View All Blogs",
  cardData: [
    {
      id: 1,
      img: blogOneBody,
      CardHeading: "BodhGaya",
      Rating: 4.8,
      para: "Bodhgaya is a special city in India where Gautama Buddha became enlightened. Many people visit the famous Mahabodhi Temple there to find peace and wisdom. The calm atmosphere and strong Buddhist history make Bodhgaya a meaningful place to visit.",
      rate: "10,000",

      blogHeading: "Lorem Ipsum is simply dummy text of the printing",
      isTrue: false,
      firstImage: blogOneImg,
      paraOne:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
      paraTwo:
        "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like",
      paraThree:
        "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, ",
    },
    {
      id: 2,
      img: sarnath,
      CardHeading: "Sarnath",
      Rating: 4.8,
      para: "Bodhgaya is a special city in India where Gautama Buddha became enlightened. Many people visit the famous Mahabodhi Temple there to find peace and wisdom. The calm atmosphere and strong Buddhist history make Bodhgaya a meaningful place to visit.",
      rate: "10,000",

      isTrue: false,
      firstImage: blogtwoimg,
      blogHeading: "Lorem Ipsum is simply dummy text of the printing",
      paraOne:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
      paraTwo:
        "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like",
      paraThree:
        "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, ",
    },
    {
      id: 3,
      img: mapimg,
      CardHeading: "Shravasti",
      Rating: 4.8,
      para: "Bodhgaya is a special city in India where Gautama Buddha became enlightened. Many people visit the famous Mahabodhi Temple there to find peace and wisdom. The calm atmosphere and strong Buddhist history make Bodhgaya a meaningful place to visit.",
      rate: "10,000",

      isTrue: false,
      firstImage: blogthreeimg,
      blogHeading: "Lorem Ipsum is simply dummy text of the printing",
      paraOne:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
      paraTwo:
        "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like",
      paraThree:
        "Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, ",
    },
    {
      id: 4,
      img: sarnath,
      CardHeading: "Sarnath",
      Rating: 4.8,
      para: "Bodhgaya is a special city in India where Gautama Buddha became enlightened. Many people visit the famous Mahabodhi Temple there to find peace and wisdom. The calm atmosphere and strong Buddhist history make Bodhgaya a meaningful place to visit.",
      rate: "10,000",

      isTrue: false,
    },
    {
      id: 5,
      img: temple,
      CardHeading: "BodhGaya",
      Rating: 4.8,
      para: "Bodhgaya is a special city in India where Gautama Buddha became enlightened. Many people visit the famous Mahabodhi Temple there to find peace and wisdom. The calm atmosphere and strong Buddhist history make Bodhgaya a meaningful place to visit.",
      rate: "10,000",

      isTrue: false,
    },
    {
      id: 6,
      img: sarnath,
      CardHeading: "Sarnath",
      Rating: 4.8,
      para: "Bodhgaya is a special city in India where Gautama Buddha became enlightened. Many people visit the famous Mahabodhi Temple there to find peace and wisdom. The calm atmosphere and strong Buddhist history make Bodhgaya a meaningful place to visit.",
      rate: "10,000",

      isTrue: false,
    },
    {
      id: 7,
      img: shravasti,
      CardHeading: "Shravasti",
      Rating: 4.8,
      para: "Bodhgaya is a special city in India where Gautama Buddha became enlightened. Many people visit the famous Mahabodhi Temple there to find peace and wisdom. The calm atmosphere and strong Buddhist history make Bodhgaya a meaningful place to visit.",
      rate: "10,000",

      isTrue: false,
    },
    {
      id: 8,
      img: sarnath,
      CardHeading: "Sarnath",
      Rating: 4.8,
      para: "Bodhgaya is a special city in India where Gautama Buddha became enlightened. Many people visit the famous Mahabodhi Temple there to find peace and wisdom. The calm atmosphere and strong Buddhist history make Bodhgaya a meaningful place to visit.",
      rate: "10,000",

      isTrue: false,
    },
  ],
};
export const additionalInfoData = [
  {
    title1: "additional package",
    paragraph: [
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting,remaining essentially unchanged.",
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting,remaining essentially unchanged.",
    ],
    title2: "additional 2",
    titlePoints: [
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry",
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry",
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry",
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry",
    ],
    cardInfo: [
      {
        title: "Shilma Manala Home Resort",
        rating: 5,
        location: "Near one Road, Manali, Himachal Pradesh - 171007",
        img: resortone,
      },
      {
        title: "Kasol Manala Home Resort",
        rating: 4,
        location: "Near two Road, Manali, Himachal Pradesh - 171007",
        img: resorttwo,
      },
      {
        title: " Manala Home Resort",
        rating: 5,
        location: "Near three Road, Manali, Himachal Pradesh - 171007",
        img: resortone,
      },
      {
        title: "Surya Home Resort",
        rating: 4,
        location: "Near Mall Road, Manali, Himachal Pradesh - 171007",
        img: resorttwo,
      },
    ],
    _id: "65268aa57e785577571249b8",
  },
];

export const itineraryData = [
  {
    day: 1,
    title: "Cab from Delhi To Manali",
    events: ["Check in"],
    subTitle: "Other benefits on Arrival",
    subTitlePoints: ["Hotel", "Breakfast", "Dinner"],
    paragraph: [
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
    ],
    _id: "65268aa57e785577571249b5",
    image: [],
  },
  {
    day: 2,
    title: "Book The hotels",
    events: ["Check in"],
    subTitle: "Other benefits on Arrival",
    subTitlePoints: ["Hotel", "Breakfast", "Dinner"],
    paragraph: [
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
    ],
    _id: "65268aa57e785577571249b6",
    image: [],
  },
  {
    day: 3,
    title: "Last step",
    events: ["Check Out"],
    subTitle: "Other benefits on Arrival",
    subTitlePoints: ["Hotel", "Breakfast", "Dinner"],
    paragraph: [
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
    ],
    _id: "65268aa57e785577571249b7",
    image: [],
  },
];
export const responsiveIndianCard = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1600 },
    items: 4,
  },
  desktopMini: {
    breakpoint: { max: 1600, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
export const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1600 },
    items: 6,
  },
  desktopMini: {
    breakpoint: { max: 1600, min: 1024 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
export const privacyPolictData = [
  {
    id: 1,
    image: privacypolicy,
    heading: "Privacy Policy",
    readOurPolicy:
      "Read our Privacy Policy to learn more about your rights and responsibilities as a Buddha Journey user.",
    dateUpdated: "Updated March 9, 2024",
    headingParagraphData: [
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    ],
    subHeading: "Sub Header Lorem Epsum",
    subHeadingParagraphData: [
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like",
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like)",
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like)",
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like)",
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like)",
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like)",
    ],
  },
];
export const termOfUse = [
  {
    id: 1,
    image: privacypolicy,
    heading: "Term of Use",
    readOurPolicy:
      "Read our Privacy Policy to learn more about your rights and responsibilities as a Buddha Journey user.",
    dateUpdated: "Updated March 9, 2024",
    headingParagraphData: [
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    ],
    subHeading: "Sub Header Lorem Epsum",
    subHeadingParagraphData: [
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like",
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like)",
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like)",
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like)",
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like)",
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like)",
    ],
  },
];
export const termAndCondition = [
  {
    id: 1,
    image: termofuse,
    heading: "Term & Conditions",
    readOurPolicy:
      "Read our Privacy Policy to learn more about your rights and responsibilities as a Buddha Journey user.",
    dateUpdated: "Updated March 9, 2024",
    headingParagraphData: [
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    ],
    subHeading: "Sub Header Lorem Epsum",
    subHeadingParagraphData: [
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like",
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like)",
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like)",
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like)",
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like)",
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like)",
    ],
  },
];
export const cancellationPolicy = [
  {
    id: 1,
    image: policy,
    heading: "Cancellation Policy",
    readOurPolicy:
      "Read our Cancellation Policy to learn more about your rights and responsibilities as a Buddha Journey user.",
    dateUpdated: "Updated March 9, 2024",
    headingParagraphData: [
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    ],
    subHeading: "Sub Header Lorem Epsum",
    subHeadingParagraphData: [
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like",
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like)",
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like)",
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like)",
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like)",
      "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose (injected humour and the like)",
    ],
  },
];
export const developersData = [
  {
    id: 9,
    name: "Harmeet",
    role: "",
    image: "https://buddhajourney-bucket.s3.ap-south-1.amazonaws.com/WhatsApp+Image+2024-03-09+at+17.27.18.jpeg",
  },
  {
    id: 5,
    name: "Dhiral",
    role: "",
    image: "https://buddhajourney-bucket.s3.ap-south-1.amazonaws.com/Dhiral.jpg",
  },
  {
    id: 1,
    name: "Rahul",
    role: "",
    image: "https://buddhajourney-bucket.s3.ap-south-1.amazonaws.com/ra1.jpeg",
  },
  {
    id: 6,
    name: "Cherry",
    role: "",
    image: "https://buddhajourney-bucket.s3.ap-south-1.amazonaws.com/WhatsApp+Image+2024-04-02+at+15.18.27.jpeg",
  },
  {
    id: 7,
    name: "Audhrya",
    role: "",
    image: "https://buddhajourney-bucket.s3.ap-south-1.amazonaws.com/audi.jpeg",
  },
  {
    id: 8,
    name: "Aman",
    role: "",
    image: "https://buddhajourney-bucket.s3.ap-south-1.amazonaws.com/aman.jpeg",
  },
  {
    id: 10,
    name: "Vertika",
    role: "",
    image: "https://buddhajourney-bucket.s3.ap-south-1.amazonaws.com/WhatsApp+Image+2024-03-24+at+09.18.39+(2).jpeg",
  },
  {
    id: 2,
    name: "Anil",
    role: "",
    image: "",
  },
  // Add more developer data as needed
];
