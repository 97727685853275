import React, { useState, useEffect } from "react";
import { Box, Modal, Tab } from "@mui/material";
import DrawerAppBar from "../../components/Global/Navbar/Navbar";
import ChatAttachmentModal from "../../Modal/ChatAttachmentModal/ChatAttachmentModal";
import Footer from "../../components/Global/Footer/Footer";
import "./ChatPage.scss";
import UserHeader from "./ChatComponents/UserHeader/UserHeader";
import PropTypes from "prop-types";

import GroupCard from "./ChatComponents/GroupCard/GroupCard";
import ChatHeader from "./ChatComponents/ChatHeader/ChatHeader";
import FooterChat from "./ChatComponents/FooterChat/FooterChat";
import LoadMessageCard from "./ChatComponents/LoadMessageCard/LoadMessageCard";
import ChatField from "./ChatField/ChatField";
import ChatInfoModal from "../../Modal/ChatModuleModal/ChatInfoModal";
import { useDispatch, useSelector } from "react-redux";
import {
  getAdminChatFlagAction,
  getAdminGroupChatAction,
  getChatUsersAction,
  getChatAttachmentFlagAction,
  getDialogFlagAction,
  getChatGroupsAction,
  getChatMessagesAction,
} from "../../reduxState/slices/BuddhaJourneySlices";
import Tabs from "@mui/material/Tabs";
import UserChatModal from "../../Modal/UserChatModal/UserChatModal";

import io from "socket.io-client";
const socket = io("http://localhost:3005/");

// const groupsData = [
//   { id: 1, name: "General Discussion", participantCount: 4 },
//   { id: 2, name: "Design Team", participantCount: 8 },
//   { id: 3, name: "Developers", participantCount: 6 },
// ];
// const userData = [
//   { id: 1, name: "Jhon", participantCount: "Available" },
//   { id: 2, name: "Jack", participantCount: "Available" },
//   { id: 3, name: "Sturt", participantCount: "Available" },
// ];
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Box>
  );
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const ChatPage = () => {
  const dispatch = useDispatch();
  const DialogFlagAction = useSelector((e) => e.buddhaJourneySlice.dialogFlag);
  const adminChatFlag = useSelector((e) => e.buddhaJourneySlice.adminChatFlag);
  const chatUsers = useSelector((e) => e.buddhaJourneySlice.chatUsers);
  const chatGroups = useSelector((e) => e.buddhaJourneySlice.chatGroups);

  const chatAttachmentFlag = useSelector(
    (e) => e.buddhaJourneySlice.chatAttachmentFlag
  );
  const [value, setValue] = React.useState(0);

  const handleClose = () => {
    dispatch(getDialogFlagAction(false));
    dispatch(getAdminGroupChatAction({ AdminChatGroup: { createGroup: {} } }));
  };
  const handleClose1 = () => {
    dispatch(getAdminChatFlagAction(false));
  };
  const handleClose2 = () => {
    dispatch(getChatAttachmentFlagAction(false));
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [room, setRoom] = useState("");

  useEffect(() => {
    dispatch(getChatUsersAction());
    dispatch(getChatGroupsAction());
  }, []);

  useEffect(() => {
    socket.on("connect", () => {
      console.log("connected");
    });

    socket.on("connect_error", (err) => {
      console.log(err);
    });

    socket.on("error", (err) => {
      console.log("err" + err);
    });

    return () => {
      socket.off("joined");
    };
  }, []);

  useEffect(() => {
    console.log("room ===>", room);
    if (room) {
      const payload = {
        chatId: room,
      };
      dispatch(getChatMessagesAction(payload));
    }
  }, [room]);
  const handleGroupSelect = (groupId) => {
    console.log("socket.connected===>", socket.connected);
    if (socket && socket.connected) {
      setRoom(groupId);
      socket.emit("join", { room: groupId });
    }
  };
  return (
    <>
      {DialogFlagAction && (
        <Modal
          open={DialogFlagAction}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{ overflow: "auto" }}
        >
          <ChatInfoModal />
        </Modal>
      )}
      {adminChatFlag && (
        <Modal
          open={adminChatFlag}
          onClose={handleClose1}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{ overflow: "auto" }}
        >
          <UserChatModal />
        </Modal>
      )}
      {chatAttachmentFlag && (
        <Modal
          open={chatAttachmentFlag}
          onClose={handleClose2}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{ overflow: "auto" }}
        >
          <ChatAttachmentModal />
        </Modal>
      )}
      <Box className="chatpage">
        <DrawerAppBar />
        <Box className="main_chatPage">
          <Box className="left_chat_content">
            <UserHeader />
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab
                    label="Groups"
                    {...a11yProps(0)}
                    sx={{ textTransform: "none", fontSize: "20px" }}
                  />
                  <Tab
                    label="User"
                    {...a11yProps(1)}
                    sx={{ textTransform: "none", fontSize: "20px" }}
                  />
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                {chatGroups?.map((group) => (
                  <GroupCard
                    key={group._id}
                    groupName={group.chatName}
                    participantCount={1}
                    onSelect={() => handleGroupSelect(group._id)}
                  />
                ))}
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                {chatUsers?.map((user) => (
                  <GroupCard
                    key={user._id}
                    groupName={user.firstName + " " + user.lastName}
                    participantCount={0}
                  />
                ))}
              </CustomTabPanel>
            </Box>
          </Box>
          <Box className="right_chat_content">
            <ChatHeader selectedRoom={room} />
            <LoadMessageCard />
            <ChatField selectedRoom={room} socket={socket} />
            <ChatField />
            <Box className="chat_user_right">
              <Box className="userChat" mb={30}>
                <ChatField />
              </Box>
            </Box>
            {/* <FooterChat /> */}
            <FooterChat socket={socket} selectedRoom={room} />
          </Box>
        </Box>
      </Box>
      <Footer />
    </>
  );
};

export default ChatPage;
