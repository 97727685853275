import * as API from "../utility/ApiHelper";

// const API_ENDPOINT_SERVER3000 = "http://localhost:3000";
const API_ENDPOINT_SERVER3000 = "http://13.234.104.214:3000";

const API_ENDPOINT = "https://catfact.ninja/fact";

export const fetchCatApiResults = () => {
  console.log("api called");

  return API.get(API_ENDPOINT);
};
export const getBlogApiResults = (bodyData) => {
  const url = `${API_ENDPOINT_SERVER3000}/v1/blog/`;
  return API.get(url, bodyData);
};
export const getAboutApiResults = (bodyData) => {
  const url = `${API_ENDPOINT_SERVER3000}/v1/aboutus/`;
  return API.get(url, bodyData);
};
export const getLandingApiResults = (bodyData) => {
  const url = `${API_ENDPOINT_SERVER3000}/v1/landingpage/`;
  return API.get(url, bodyData);
};
export const postCreateBlogApiResults = (data) => {
  let body = data.bodyData;
  let token = data.token;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const url = `${API_ENDPOINT_SERVER3000}/v1/blog/`;
  return API.post(url, body, config);
};
export const postCreateLandingPageApiResults = (data) => {
  let body = data.bodyData;
  let token = data.token;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const url = `${API_ENDPOINT_SERVER3000}/v1/landingpage/`;
  return API.post(url, body, config);
};
export const postCreateAboutusApiResults = (data) => {
  let body = data.bodyData;
  let token = data.token;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const url = `${API_ENDPOINT_SERVER3000}/v1/aboutus/`;
  return API.post(url, body, config);
};
export const postUsersSignUpApiResults = (bodyData) => {
  console.log("14-03-24 04", bodyData);
  const url = `${API_ENDPOINT_SERVER3000}/v1/users/signup`;
  return API.post(url, bodyData);
};
export const postUsersSignInApiResults = (bodyData) => {
  const url = `${API_ENDPOINT_SERVER3000}/v1/security/authenticate/`;
  return API.post(url, bodyData);
};
export const postForgotPasswordApiResults = (bodyData) => {
  const url = `${API_ENDPOINT_SERVER3000}/v1/security/forgotpassword`;
  return API.post(url, bodyData);
};
export const postVerifyOtpApiResults = (bodyData) => {
  const url = `${API_ENDPOINT_SERVER3000}/v1/security/verifyotp`;
  return API.post(url, bodyData);
};
export const postResetPasswordApiResults = (bodyData) => {
  const url = `${API_ENDPOINT_SERVER3000}/v1/security/resetpassword`;
  return API.post(url, bodyData);
};
export const changePasswordApiResults = (data) => {
  let body = data.bodyData;
  let token = data.token;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const url = `${API_ENDPOINT_SERVER3000}/v1/security/changePassword`;
  return API.post(url, body, config);
};
export const getChatUsers = (bodyData) => {
  const url = `${API_ENDPOINT_SERVER3000}/v1/users/chat`;
  return API.get(url, bodyData);
};
export const getChatGroups = (bodyData) => {
  const url = `${API_ENDPOINT_SERVER3000}/v1/chat/`;
  return API.get(url, bodyData);
};
export const getChatMessages = (bodyData) => {
  const url = `${API_ENDPOINT_SERVER3000}/v1/message/${bodyData.chatId}`;
  console.log("getChatMessages url ===>", url);
  return API.get(url, bodyData);
};
