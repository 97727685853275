import { Box, CardMedia, Typography } from "@mui/material";
import React from "react";
import DrawerAppBar from "../../components/Global/Navbar/Navbar";
import "./BlogSeven.scss";
import Carousel from "react-multi-carousel";
import Footer from "../../components/Global/Footer/Footer";
import blogSevenLeftImg from "../../assets/images/blogSevenLeftImg.png";
import IndianDestCard from "../../components/Reusable/IndianDestCard/IndianDestCard";
import BlogAdCard from "../../components/Reusable/BlogAdCard/BlogAdCard";
import { blogCardData, responsiveIndianCard } from "../../Data.js";
import CenterText from "../../components/Reusable/CenterText/CenterText";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const BlogSeven = () => {
  const stateValue = useSelector(
    (e) => e.buddhaJourneySlice.blogList.blogListValue
  );
  const { id } = useParams();
  return (
    <Box>
      <Box className="blogSeven">
        <DrawerAppBar />
        <Box className="imgAndText">
          <Box className="imageBlock">
            <CardMedia
              image={blogSevenLeftImg}
              title="BuddhaJourney"
              component="img"
            />
          </Box>
          <Box className="TextBlock">
            <Box className="headingBlock">
              <Typography variant="boldFourtyEightWhite" color="primary">
              {stateValue.cardData[id].blogHeading }
              </Typography>
            </Box>
            <Box className="paraBlock">
              <Typography color="secondary" variant="regularTwentyFour">
              {stateValue.cardData[id].paraOne }
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box className="imgAndTextLeft">
          <Box className="imageBlock">
            <CardMedia
              image={blogSevenLeftImg}
              title="BuddhaJourney"
              component="img"
            />
          </Box>
          <Box className="TextBlock">
            <Box className="paraBlock">
              <Typography color="secondary" variant="regularTwentyFour">
              {stateValue.cardData[id].paraTwo }
              </Typography>
            </Box>
            <Box className="paraBlock">
              <Typography color="secondary" variant="regularTwentyFour">
              {stateValue.cardData[id].paraThree }
              </Typography>
            </Box>
          </Box>
        </Box>
        <CenterText />
        <Box className="bloglistMarginBottom">
          <Carousel responsive={responsiveIndianCard}>
            {blogCardData?.cardData.map((element) => {
              return (
                <Box>
                  <IndianDestCard
                    key={element.id}
                    img={element.img}
                    para={element.para}
                    Rating={element.Rating}
                    CardHeading={element.CardHeading}
                    isTrue={element.isTrue}
                  />
                </Box>
              );
            })}
          </Carousel>
        </Box>
        <Box className="blogAd">
          <BlogAdCard />
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};

export default BlogSeven;
