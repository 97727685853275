import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import "./LandingPageCreation.scss";
import InputField from "../ComponentsForCreation/InputField/InputField";
import ImageUpload from "../ComponentsForCreation/ImageUpload/ImageUpload";
import DropdownField from "../ComponentsForCreation/DropdownField/DropdownField";
import DescriptionField from "../ComponentsForCreation/DescriptionField/DescriptionField";
import TitleBox from "../ComponentsForCreation/TitleBox/TitleBox";
import { Form, Formik } from "formik";
let imgUrlCollection = [];

const LandingPageCreation = () => {
  const [dataFromChild, setDataFromChild] = useState(null);

  const handleDataFromChild = (data) => {
    console.log("10-04-24 000 02", data);
    setDataFromChild(data);
  };
  useEffect(() => {
    console.log("10-04-24 000 02", dataFromChild);
    if (dataFromChild) {
      imgUrlCollection.push(dataFromChild);
    }
    console.log("10-04-24 000 01", imgUrlCollection);
  }, [dataFromChild]);

  return (
    <Box className="landingCreation">
      <Box>
        <Typography variant="semiBoldThirtyTwo"> Landing Page</Typography>
      </Box>
      <Box className="viewAndManage">
        <Typography>View and manage all post Here</Typography>
      </Box>

      <Formik
        initialValues={{
          maintitle: "",
          IndianDestinationTitle: "",
          viewMore: "",
          indDesc: "",
          CardHeading: "",
          Rating: "",
          days: "",
          viewMore1: "",
          rate: "",
          para: "",
          holidayTitle: "",
          viewMore2: "",
          holidesc: "",
          destination: "",
          price: "",
          specialHoliTitle: "",
          viewMore3: "",
          speDesc: "",
          speDestination: "",
          spePrice: "",
          blogTitle: "",
          viewMore4: "",
          blogDestination: "",
          blogPrice: "",
          blogDesc: "",
          blogDesc1: "",
          chooseTitle: "",
          authorName: "",
          choosedesc: "",
          vedioTitle: "",
          vedioLink: "",
          vedioDesc: "",
        }}
        // validationSchema={userDetailValidationSchema}
        onSubmit={(values) => {
          console.log("values11", values);
          const landingData = {
            emailId: values.email,
            password: values.password,
          };
          // dispatch(postUsersSignInAction(loginData));
        }}
      >
        <Form>
          <Box className="mainTitle">
            <Typography variant="boldSixteen">
              Add Main Title and Image
            </Typography>
            <InputField id="maintitle" name="maintitle" label="Title" />
            <ImageUpload imgurl={handleDataFromChild} />
          </Box>
          <Box className="mainTitle">
            <Typography variant="boldSixteen">
              Add Trending Indian Destination
            </Typography>
            <Box className="InputFieldAndDropdown">
              <InputField
                id="IndianDestinationTitle"
                name="IndianDestinationTitle"
                variant="outlined"
                label="Title"
              />
              <DropdownField
                id="viewMore"
                label="View All Link"
                name="viewMore"
                variant="outlined"
              />
            </Box>
            <DescriptionField
              id="indDesc"
              label="Description"
              name="indDesc"
              variant="outlined"
            />

            <TitleBox title="Trending Destination Items" />
            <ImageUpload imgurl={handleDataFromChild} />
            <Box className="inputFieldsSize">
              <InputField
                id="CardHeading"
                name="CardHeading"
                variant="outlined"
                label="Write Destination Place here"
              />
              <InputField
                id="Rating"
                name="Rating"
                variant="outlined"
                label="Give Rating"
              />
              <InputField
                id="days"
                name="days"
                variant="outlined"
                label="Days and Nights Here"
              />
            </Box>
            <Box className="inputFieldsViewLink">
              <DropdownField
                id="viewMore1"
                label="View All Link"
                name="viewMore1"
                variant="outlined"
              />
              <InputField
                id="rate"
                name="rate"
                variant="outlined"
                label="Enter Price Range Here"
              />
            </Box>
            <DescriptionField
              id="para"
              label="Description"
              name="para"
              variant="outlined"
              rows={1}
            />
          </Box>
          <Box className="mainTitle">
            <Typography variant="boldSixteen">
              Add Trending Holiday Destination
            </Typography>
            <Box className="InputFieldAndDropdown">
              <InputField
                id="holidayTitle"
                name="holidayTitle"
                variant="outlined"
                label="Title"
              />
              <DropdownField
                id="viewMore2"
                name="viewMore2"
                label="View All Link"
                variant="outlined"
              />
            </Box>
            <DescriptionField
              id="holidesc"
              label="Description"
              name="holidesc"
              variant="outlined"
            />

            <TitleBox title="Trending Destination Items" />
            <ImageUpload imgurl={handleDataFromChild} />
            <Box className="inputFieldsSize">
              <InputField
                id="destination"
                name="destination"
                variant="outlined"
                label="Write Destination Place here"
              />
              <InputField
                id="price"
                name="price"
                variant="outlined"
                label="Enter Price Range Here"
              />
            </Box>
          </Box>
          <Box className="mainTitle">
            <Typography variant="boldSixteen">
              Add Special Holiday Destination
            </Typography>
            <Box className="InputFieldAndDropdown">
              <InputField
                id="specialHoliTitle"
                name="specialHoliTitle"
                variant="outlined"
                label="Title"
              />
              <DropdownField
                id="viewMore3"
                name="viewMore3"
                variant="outlined"
                label="View All Link"
              />
            </Box>
            <DescriptionField
              id="speDesc"
              name="speDesc"
              variant="outlined"
              label="Description"
            />

            <TitleBox title="Trending Destination Items" />
            <ImageUpload imgurl={handleDataFromChild} />
            <Box className="inputFieldsSize">
              <InputField
                id="speDestination"
                name="speDestination"
                variant="outlined"
                label="Write Destination Place here"
              />
              <InputField
                id="spePrice"
                name="spePrice"
                variant="outlined"
                label="Enter Price Range Here"
              />
            </Box>
          </Box>
          <Box className="mainTitle">
            <Typography variant="boldSixteen">Add Video Here</Typography>
            <Box className="InputFieldAndDropdown">
              <InputField
                id="vedioTitle"
                name="vedioTitle"
                variant="outlined"
                label="Title"
              />
              <Box className="vedioLink">
                <InputField
                  id="vedioLink"
                  name="vedioLink"
                  variant="outlined"
                  label="Add Here Video link"
                />
              </Box>
            </Box>
            <Box className="vedioDesc">
              <DescriptionField
                id="vedioDesc"
                label="Description"
                name="vedioDesc"
                variant="outlined"
              />
              {/* <Box className="vedioSection"> */}
              <video
                className="vedioSection"
                controls
                autoPlay
                // muted
                loop
                poster="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/images/BigBuckBunny.jpg"
              >
                <source
                  src="https://buddhajourney-bucket.s3.ap-south-1.amazonaws.com/Traller.mov"
                  type="video/mp4"
                />
              </video>
              {/* </Box> */}
            </Box>
          </Box>
          <Box className="mainTitle">
            <Typography variant="boldSixteen">Add Blogs Here</Typography>
            <Box className="InputFieldAndDropdown">
              <InputField
                id="blogTitle"
                name="blogTitle"
                variant="outlined"
                label="Title"
              />
              <DropdownField
                id="viewMore4"
                name="viewMore4"
                label="View All Link"
                variant="outlined"
              />
            </Box>
            <DescriptionField
              id="blogDesc1"
              name="blogDesc1"
              variant="outlined"
              label="Description"
            />

            <TitleBox title="Trending Destination Items" />
            <ImageUpload imgurl={handleDataFromChild} />
            <Box className="inputFieldsSize">
              <InputField
                id="blogDestination"
                name="blogDestination"
                variant="outlined"
                label="Write Destination Place here"
              />
              <InputField
                id="blogPrice"
                name="blogPrice"
                variant="outlined"
                label="Give Ratings"
              />
            </Box>
            <DescriptionField
              id="blogDesc"
              name="blogDesc"
              variant="outlined"
              label="Description"
              rows={1}
            />
          </Box>
          <Box className="mainTitle">
            <Typography variant="boldSixteen">
              Add Why To Choose Here
            </Typography>
            <InputField
              id="chooseTitle"
              name="chooseTitle"
              variant="outlined"
              label="Title"
            />
            <ImageUpload imgurl={handleDataFromChild} />
            <TitleBox title="Trending Destination Items" />
            <Box className="inputFieldsSize">
              <InputField
                id="authorName"
                name="authorName"
                variant="outlined"
                label="Author Name"
              />
              <Box className="autherDesc">
                <DescriptionField
                  id="choosedesc"
                  label="Description"
                  name="choosedesc"
                  variant="outlined"
                  rows={1}
                />
              </Box>
            </Box>
          </Box>
          <Box className="submit_reset">
            <Button
              sx={{
                backgroundColor: "white",
                color: "#0a9e88",
                border: "1px solid #0a9e88",
              }}
              type="reset"
              variant="formButton"
            >
              Reset
            </Button>
            <Button type="submit" variant="formButton">
              Submit
            </Button>
          </Box>
        </Form>
      </Formik>
    </Box>
  );
};
export default LandingPageCreation;
