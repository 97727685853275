import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { createBrowserHistory } from "history";
import { configureStore } from "@reduxjs/toolkit";
import { createRootReducer } from "./Reducer";
import createSagaMiddleware from "redux-saga";
import { rootSagas } from "../middlewareSaga/Saga";
const sagaMiddleware = createSagaMiddleware();

export const history = createBrowserHistory();

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(
  persistConfig,
  createRootReducer(history)
);

export function createStore() {
  const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(sagaMiddleware),
  });

  const persistor = persistStore(store);

  return { store, persistor };
}

const { store, persistor } = createStore();
sagaMiddleware.run(rootSagas);

export { persistor, store };
export default store;
