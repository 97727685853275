import { Box } from "@mui/material";
import Footer from "../../components/Global/Footer/Footer";
import DrawerAppBar from "../../components/Global/Navbar/Navbar";
import Heading from "../../components/Reusable/Heading/Heading";
import HolidayDestCard from "../../components/Reusable/HolidayDestCard/HolidayDestCard";
import IndianDestCard from "../../components/Reusable/IndianDestCard/IndianDestCard";
import ImageBg from "../../components/Reusable/ImageBg/ImageBg";
import Testimonials from "../../components/Reusable/Testimonials/Testimonials";
import ContactUs from "../../components/Reusable/ContactUs/ContactUs";
import { indianCardData, holidayCardData, blogCardData } from "../../Data.js";
import bg from "../../assets/images/bg.png";
import "./LandingPage.scss";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import { responsiveIndianCard, responsive } from "../../Data.js";
import AddNewDestination from "./component/AddNewDestination/AddNewDestination";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getHomePageAction,
  getBlogListAction,
} from "../../reduxState/slices/BuddhaJourneySlices";
const LandingPage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    //landing page api call
    dispatch(getHomePageAction());
  }, [dispatch]);
  const stateValue = useSelector(
    (e) => e.buddhaJourneySlice.homepage.homepageData
  );
  console.log("15-03-24 001 08", window.location.href);
  function testClick() {
    dispatch(getBlogListAction());
  }

  if (typeof stateValue.title !== "string") {
    return null;
  }

  return (
    <Box className="landingPageBlock">
      <DrawerAppBar />
      <ImageBg
        image={bg}
        headingText={stateValue.title}
        placeHolder={stateValue.searchLabel}
      />
      <Box className="leftRigtSpacing">
        <Heading
          headingDestination={stateValue.indianDestination.title}
          subText={stateValue.indianDestination.description}
          buttonText={stateValue.indianDestination.viewMore}
          isVisibleViewLink={true}
        />

        <Carousel responsive={responsiveIndianCard} itemClass="carouselClass">
          {stateValue.indianDestination?.cardData.map((element, index) => {
            return (
              <IndianDestCard
                key={element.id}
                img={indianCardData.cardData[index].img}
                CardHeading={element.CardHeading}
                Rating={element.Rating}
                days={element.days}
                para={element.para}
                rate={element.rate}
                priceAndBook={element.priceAndBook}
              />
            );
          })}
        </Carousel>

        <Heading
          headingDestination={stateValue.holidayCardData.title}
          subText={stateValue.holidayCardData.description}
          buttonText={stateValue.holidayCardData.viewMore}
          isVisibleViewLink={true}
        />

        <Carousel responsive={responsive}>
          {stateValue.holidayCardData?.cardData.map((element, index) => {
            return (
              <HolidayDestCard
                key={element.id}
                im={holidayCardData.cardData[index].im}
                destination={element.destination}
                price={element.price}
                howerbtnText="BOOK NOW"
              />
            );
          })}
        </Carousel>

        <Heading
          headingDestination={stateValue.specialHolidayCardData.title}
          subText={stateValue.specialHolidayCardData.description}
          buttonText={stateValue.specialHolidayCardData.viewMore}
          isVisibleViewLink={true}
        />

        <Carousel responsive={responsive}>
          {stateValue.specialHolidayCardData?.cardData.map((element, index) => {
            return (
              <HolidayDestCard
                key={element.id}
                im={holidayCardData.cardData[index].im}
                destination={element.destination}
                price={element.price}
                howerbtnText="Explore"
              />
            );
          })}
        </Carousel>

        <Heading
          headingDestination={stateValue.videoData.title}
          subText={stateValue.videoData.description}
          isVisibleViewLink={false}
        />
        <AddNewDestination />

        <Box>
          <video
            style={{ borderRadius: "10px" }}
            controls
            autoPlay
            width="100%"
            height="auto"
            // muted
            loop
            poster="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/images/BigBuckBunny.jpg"
          >
            <source
              src="https://buddhajourney-bucket.s3.ap-south-1.amazonaws.com/Traller.mov"
              type="video/mp4"
            />
          </video>
        </Box>

        <Heading
          headingDestination={stateValue.blogCardData.title}
          subText={stateValue.blogCardData.description}
          buttonText={stateValue.blogCardData.viewMore}
          isVisibleViewLink={true}
        />

        <Carousel responsive={responsiveIndianCard}>
          {stateValue.blogCardData?.cardData.map((element, index) => {
            return (
              <Box onClick={() => testClick()}>
                <Link
                  to={`/blogs/${index}/${element.blogFormat}`}
                  className="linkForBlogs"
                >
                  <IndianDestCard
                    key={element.id}
                    img={blogCardData.cardData[index].img}
                    para={element.para}
                    Rating={element.Rating}
                    CardHeading={element.CardHeading}
                    isTrue={element.isTrue}
                  />
                </Link>
              </Box>
            );
          })}
        </Carousel>
        <Box mt={10} mb={10}>
          <Testimonials />
        </Box>
      </Box>
      <Box mb={10}>
        <ContactUs />
      </Box>
      <Footer />
    </Box>
  );
};

export default LandingPage;
