import { Box, CardMedia, Typography } from "@mui/material";
// import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import "./ChatField.scss";
import { useDispatch, useSelector } from "react-redux";
import AWS from "aws-sdk";

const ChatField = ({ socket, selectedRoom }) => {
  const chatMessages = useSelector((e) => e.buddhaJourneySlice.chatMessages);
  const dataLogin = useSelector((r) => r.buddhaJourneySlice.users);
  const userLoggedIn = dataLogin?.SignInResponseValue?.data?.userLoggedIn?._id;
  const [filteredMessages, setFilteredMessages] = React.useState([]);

  useEffect(() => {
    console.log("selectedRoom ===>", selectedRoom);
    if (selectedRoom) {
      setFilteredMessages(
        chatMessages.filter((msg) => msg.chatId?._id === selectedRoom)
      );
    }
  }, [selectedRoom, chatMessages]);

  // useEffect(() => {
  //   console.log("inside useEffect socket");

  //   if (socket) {
  //     socket.on("messageReceived", (data) => {
  //       console.log("data messagereceived ===>", data.chatId._id, selectedRoom);
  //       if (data.chatId._id === selectedRoom) {
  //         setFilteredMessages((prevMessages) => [...prevMessages, data]);
  //       }
  //     });
  //     socket.on("chat-failed", (data) => {
  //       console.log("data chat-failed ===>", data);
  //     });
  //   }
  // }, [selectedRoom]);

  useEffect(() => {
    if (socket) {
      socket.on("messageReceived", handleIncomingMessage);
      socket.on("chat-failed", handleChatFailed);

      return () => {
        socket.off("messageReceived", handleIncomingMessage);
        socket.off("chat-failed", handleChatFailed);
      };
    }
  }, [socket, selectedRoom]);

  useEffect(() => {
    console.log("filteredMessages ===>", filteredMessages);
  }, [filteredMessages]);

  const handleIncomingMessage = (data) => {
    if (data.chatId._id === selectedRoom) {
      console.log("New message received in room:", selectedRoom);
      setFilteredMessages((prevMessages) => [...prevMessages, data]);
    }
  };

  const handleChatFailed = (data) => {
    console.log("Chat failed:", data);
  };

  return filteredMessages.map((msg) => (
    // const ChatField = () => {
    //   const file = useSelector((e) => e.buddhaJourneySlice.attachFileData);
    //   console.log("27-03-24 03", file);

    //   return (
    <>
      {/* {msg.sender?._id != userLoggedIn && ( */}
        <Box className="chat_field">
          <Box className="user_img">
            <CardMedia className="imageBox" image="" title="yellow" />
          </Box>
          <Box className="chat_box">
            <Typography variant="subtitle1" gutterBottom>
              <span
                style={{
                  fontWeight: "bold",
                  color: "#0A9E88",
                  marginLeft: "10px",
                }}
              >
                {msg.sender?.firstName}
              </span>
            </Typography>
            <Typography variant="body1" gutterBottom>
              <span
                style={{
                  color: "#6E7191",
                  marginLeft: "10px",
                }}
              >
                {"msg.content"}
              </span>
            </Typography>
          </Box>
        </Box>
        <p>hi</p>
      {/* )} */}
      {/* {msg.sender?._id == userLoggedIn && ( */}
        <Box className="chat_user_right">
          <Box className="userChat">
            <Box className="chat_field">
              <Box className="user_img">
                <CardMedia className="imageBox" image="" title="yellow" />
              </Box>
              <Box className="chat_box">
                <Typography variant="subtitle1" gutterBottom>
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "#0A9E88",
                      marginLeft: "10px",
                    }}
                  >
                    {"You"}
                  </span>
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <span
                    style={{
                      color: "#6E7191",
                      marginLeft: "10px",
                    }}
                  >
                    {"msg.content"}
                  </span>
                </Typography>
              </Box>
            </Box>
          </Box>
          {/* <Box className="chat_box">
          <Typography variant="subtitle1" gutterBottom>
            <span
              style={{
                fontWeight: "bold",
                color: "#0A9E88",
                marginLeft: "10px",
              }}
            >
              Brad Tolbert
            </span>
          </Typography>
          <Typography variant="body1" gutterBottom>
            <span
              style={{
                color: "#6E7191",
                marginLeft: "10px",
              }}
            >
              hi @Clint Durham and @Amellie Tatou
              <Box className="attach_chat">
                {file && (
                  <CardMedia
                    className="imageBox"
                    component="img"
                    alt="img"
                    src={file}
                    title="img"
                    sx={{
                      padding: "10px",
                    }}
                  />
                )}
              </Box>{" "}
              <Box className="attach_chat">
                {file && file.fileType === "Image" && (
                  <CardMedia
                    className="imageBox"
                    image={file.url}
                    title="Image"
                  />
                )}
              </Box>
            </span>
          </Typography> */}
        </Box>
        <p>fbfgb</p>
      {/* )} */}
    </>
  ));
};

export default ChatField;
