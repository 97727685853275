import React from "react";
import { TextField, Box } from "@mui/material";
import { useField } from "formik";
import * as Yup from "yup";
import "./MyTextField.scss";

const MyTextField = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <>
      <TextField
        {...field}
        {...props}
        className="inputWidthHelp"
        // variant="filled"
        label={label}
      />
      {meta.touched && meta.error ? (
        <Box className="errorhelp">{meta.error}</Box>
      ) : null}
    </>
  );
};

// Define a validation schema outside of the component
const validationSchema = Yup.object({
  fullName: Yup.string().required("This field is required"),
  subject: Yup.string().max(50, "Must be 15 characters or less").notRequired(),
  email: Yup.string()
    .email("Please enter a valid email")
    .required("This field is required"),

  mobile: Yup.string()
    .required("Mobile number is required")
    .matches(/^\d{10}$/, {
      message: "Please enter a valid 10-digit mobile number",
      excludeEmptyString: false,
    })
    .required("Please enter a valid 10-digit mobile number"),

  comment: Yup.string().max(300, "Must be 15 characters or less").notRequired(),
});

export { MyTextField, validationSchema };
