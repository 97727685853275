import { Box } from "@mui/material";
import React, { useEffect } from "react";
import DrawerAppBar from "../../components/Global/Navbar/Navbar";
import TourSelectedCard from "../../components/Reusable/TourSelectedCard/TourSelectedCard";
import HolidayDestCard from "../../components/Reusable/HolidayDestCard/HolidayDestCard";
import { holidayCardData } from "../../Data";
import Heading from "../../components/Reusable/Heading/Heading";
import "./FavouritePlace.scss";
import Footer from "../../components/Global/Footer/Footer";
import favourite from "../../assets/images/favourite.svg";
import { useDispatch, useSelector } from "react-redux";
import { getFavouritePlacesAction } from "../../reduxState/slices/BuddhaJourneySlices";
export const FavouritePlace = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    //faq page api call
    dispatch(getFavouritePlacesAction());
  }, [dispatch]);
  const stateValue = useSelector(
    (e) => e.buddhaJourneySlice.favouritePlaces.favouritePlacesData
  );
  if (typeof stateValue.title !== "string") {
    return null;
  }

  return (
    <>
      <Box className="favouritePlaceBlock">
        <DrawerAppBar />
        <TourSelectedCard img={favourite} headingName={stateValue.header} />
        <Box className="leftRightMargin">
          <Heading
            headingDestination={stateValue.title}
            subText={stateValue.desc}
            buttonText={holidayCardData.viewMore}
            isVisibleViewLink={false}
          />
          <Box className="holidayPlaces">
            {stateValue?.packageList.map((element, index) => {
              return (
                <HolidayDestCard
                  key={element.id}
                  im={holidayCardData.cardData[index].im}
                  ruppeeSymbol={element.ruppeeSymbol}
                  startPriceText={element.startPriceText}
                  destination={element.title}
                  price={element.amount}
                  howerbtnText="Explore"
                />
              );
            })}
          </Box>
        </Box>
      </Box>
      <Footer />
    </>
  );
};
