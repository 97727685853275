import { Box, CardMedia, Typography } from "@mui/material";
import React, { useEffect } from "react";
import "./GroupCard.scss";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { getDialogFlagAction } from "../../../../reduxState/slices/BuddhaJourneySlices";
import { useDispatch } from "react-redux";

const GroupCard = ({ groupName, participantCount, onSelect }) => {
  const dispatch = useDispatch();

  const handleSelect = () => {
    console.log('handleselect ==>', onSelect)
    onSelect && onSelect();
  };

  return (
    <Box className="group_card" onClick={handleSelect}>
      <Box className="img_text_group_card">
        <Box className="img_card">
          <CardMedia className="imageBox" image="" title="yellow" />
        </Box>
        <Box className="group_left_text">
          <Typography
            sx={{ color: "#14142B", marginLeft: "1rem" }}
            variant="h5"
            gutterBottom
          >
            {groupName}
            <Typography sx={{ color: "#6E7191" }} variant="body2">
              Participants {participantCount}
            </Typography>
          </Typography>
        </Box>
      </Box>
      <Box className="group_icons">
        <EditIcon
          onClick={() => dispatch(getDialogFlagAction(true))}
          sx={{ color: "#6E7191", marginRight: "1rem" }}
        />
        <DeleteIcon sx={{ color: "#6E7191" }} />
      </Box>
    </Box>
  );
};

export default GroupCard;
