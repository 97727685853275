import React, { useState } from "react";
import "./UserDetails.scss";
import { Box, Typography, TextField, Button } from "@mui/material";
import { Form, Formik, useField } from "formik";
import * as Yup from "yup";
import EditIcon from "@mui/icons-material/Edit";
const MyTextInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <Box>
      <TextField
        variant="outlined"
        className="input"
        {...field}
        {...props}
        label={label}
      />

      {meta.touched && meta.error ? (
        <Box className="error">{meta.error}</Box>
      ) : null}
    </Box>
  );
};
const userDetailValidationSchema = Yup.object({
  firstName: Yup.string()
    .max(10, "Must be 10 Characters or less")
    .required("Required"),
  lastName: Yup.string()
    .max(10, "Must be 10 Characters or less")
    .required("Required"),
  email: Yup.string().email("Invalid email address").required("Required"),
  phoneNumber: Yup.string()
    .matches(/^[0-9]{10}$/, "Phone number must be exactly 10 digits")
    .required("Required"),
});

const UserDetails = () => {
  const [file, setFile] = useState(null);
  function handleChange(event) {
    const selectedFile = event.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      const url = reader.result;
      setFile(url);
    };
    reader.onerror = (error) => {
      console.error("Error occurred while reading the file:", error);
    };
    if (selectedFile) {
      reader.readAsDataURL(selectedFile);
    }
  }
  return (
    <Box className="userDetailTab">
      <Box className="imgBlock">
        {file ? (
          <img src={file} alt="Uploaded" className="im" />
        ) : (
          <Box className="profile"></Box>
        )}

        <Box className="editIcon">
          <input
            id="file-input"
            type="file"
            accept="image/*"
            onChange={handleChange}
            hidden
          />

          <EditIcon
            onClick={() => {
              const fileInput = document.getElementById("file-input");
              fileInput.click(); // Trigger file input when the edit icon is clicked
            }}
            className="icon"
          />
        </Box>
      </Box>
      <Box className="changeProfile">
        <Typography color="#6E7191">Change Profile</Typography>
      </Box>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          phoneNumber: "",
        }}
        validationSchema={userDetailValidationSchema}
        onSubmit={(values) => console.log("values", values)}
      >
        <Form className="UserDeatailBlock">
          <Box className="UserDeatailInnerBlock">
            <Box className="NameField">
              <MyTextInput id="firstName" name="firstName" label="First Name" />

              <MyTextInput
                id="outlined-basic"
                name="lastName"
                label="Last Name"
              />
            </Box>
            <Box className="NameField">
              <MyTextInput id="outlined-basic" name="email" label="Email" />
              <MyTextInput
                id="outlined-basic"
                name="phoneNumber"
                label="Mobile Number"
              />
            </Box>
            <Button type="submit" variant="formButton" className="btn">
              Edit Profile
            </Button>
          </Box>
        </Form>
      </Formik>
    </Box>
  );
};
export default UserDetails;
