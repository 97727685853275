import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import UserDetails from "../UserDetails/UserDetails";
import ChangePassword from "../ChangePassword/ChangePassword";
import General from "../General/General";
import LandingPageCreation from "../LandingPageCreation/LandingPageCreation";
import BookmarkedListing from "../../../BookmarkedListing/BookmarkedListing";
import { useSelector } from "react-redux";
import AboutPageCreation from "../AboutPageCreation/AboutPageCreation";
import BlogCreation from "../BlogCreation/BlogCreation";
// import TourDetailsForm from "../TourDetailsForm/TourDetailsForm";
// import Itinerary from "../Itinerary/Itinerary"
// import AdditionalInfo from "../AdditionalInfo/AdditionalInfo";
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Box>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function TabForSetting() {
  const emailId = useSelector(
    (r) =>
      r.buddhaJourneySlice?.users?.SignInResponseValue?.data?.userLoggedIn
        ?.emailId
  );
  console.log("30-03-24 05", emailId);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            label="User Details"
            {...a11yProps(0)}
            sx={{ textTransform: "none", fontSize: "20px" }}
          />
          <Tab
            label="Change Password"
            {...a11yProps(1)}
            sx={{ textTransform: "none", fontSize: "20px" }}
          />
          <Tab
            label="General"
            {...a11yProps(2)}
            sx={{ textTransform: "none", fontSize: "20px" }}
          />
          <Tab
            label="Bookmarks"
            {...a11yProps(2)}
            sx={{ textTransform: "none", fontSize: "20px" }}
          />
          {emailId && emailId === "sivavintha23@mailinator.com" && (
            <Tab
              label="Landing Page Creation"
              {...a11yProps(2)}
              sx={{ textTransform: "none", fontSize: "20px" }}
            />
          )}
          {emailId && emailId === "sivavintha23@mailinator.com" && (
            <Tab
              label="About Page Creation"
              {...a11yProps(2)}
              sx={{ textTransform: "none", fontSize: "20px" }}
            />
          )}
          {emailId && emailId === "sivavintha23@mailinator.com" && (
            <Tab
              label="Blog Creation"
              {...a11yProps(2)}
              sx={{ textTransform: "none", fontSize: "20px" }}
            />
          )}
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <UserDetails />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <ChangePassword />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <General />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <BookmarkedListing />
      </CustomTabPanel>{" "}
      <CustomTabPanel value={value} index={4}>
        <LandingPageCreation />
      </CustomTabPanel>{" "}
      <CustomTabPanel value={value} index={5}>
        <AboutPageCreation />
      </CustomTabPanel>{" "}
      <CustomTabPanel value={value} index={6}>
        <BlogCreation />
      </CustomTabPanel>
    </Box>
  );
}
