import TermAndPolicy from "../../components/Reusable/TermAndPolicy/TermAndPolicy";
import React, { useEffect } from "react";
import { termAndCondition } from "../../Data";
import { useDispatch, useSelector } from "react-redux";
import { getTermAndConditionAction } from "../../reduxState/slices/BuddhaJourneySlices";
const TermAndCondition = () => {
  const dispatch = useDispatch();
  const stateValue = useSelector(
    (e) => e.buddhaJourneySlice.TermAndCondition.TermAndConditionData
  );
  useEffect(() => {
    //TermsofuseData page api call
    dispatch(getTermAndConditionAction());
  }, [dispatch]);

  if (typeof stateValue.image !== "string")
    return <TermAndPolicy arrayName={termAndCondition} />;
  else {
    termAndCondition[0] = stateValue;
    return <TermAndPolicy arrayName={termAndCondition} />;
  }
};

export default TermAndCondition;
