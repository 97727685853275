import React from "react";
import "./TitleBox.scss";
import { Box, Typography } from "@mui/material";
const TitleBox = ({ title }) => {
  return (
    <Box className="titleBox">
      <Box className="title">
        <Typography>{title}</Typography>
      </Box>
      <Box ml={6} className="items">
        <Typography>Added Items - 0</Typography>
      </Box>
    </Box>
  );
};

export default TitleBox;
