import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CallIcon from "@mui/icons-material/Call";
import logo from "../../../assets/images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getBlogListAction,
  successForgotPasswordAction,
  successPostResetPasswordAction,
  successUsersSignInAction,
  successUsersSignUpAction,
  successVerifyOtpAction,
  suscessforgotpassAction,
  suscessloginAction,
} from "../../../reduxState/slices/BuddhaJourneySlices";
import { Avatar, CardMedia, Menu, MenuItem, Tooltip } from "@mui/material";

// const settings = [`${dataLogin?.userLoggedIn?.firstName}`, "Setting", "Logout"];

const drawerWidth = 240;
const pages = [
  {
    id: 1,
    page: "About Us",
    link: "aboutus",
    // action:getAboutAction(),
  },
  // {
  //   id: 2,
  //   page: "FAQs",
  //   link: "faqs",
  //   // action:getServiceAction(),
  // },
  {
    id: 3,
    page: "Blog",
    link: "bloglisting",
    action: getBlogListAction(),
  },
  {
    id: 4,
    page: "Package",
    // link: "bloglisting",
    // action: getBlogListAction(),
  },
];
// const navItems = [
//   "Home",
//   "About",
//   "Trending Indian Destination",
//   "Trending Holidays Destinations",
//   "Contact Us",
// ];

function DrawerAppBar(props) {
  const dispatch = useDispatch();

  const dataLogin = useSelector(
    (r) => r.buddhaJourneySlice.users.SignInResponseValue.data
  );
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const settings = [
    `${
      dataLogin?.userLoggedIn?.firstName +
      " " +
      dataLogin?.userLoggedIn?.lastName
    }`,
    "Personal Chat",
    "Setting",
    "Logout",
  ];

  const token = useSelector(
    (r) => r.buddhaJourneySlice?.users?.SignInResponseValue?.data?.access?.token
  );
  console.log("30-03-24 01", token);
  // const { firstName, lastName } = dataLogin && dataLogin?.userLoggedIn;
  const navItems = [
    {
      id: 0,
      text:
        token &&
        `${
          dataLogin?.userLoggedIn?.firstName +
          " " +
          dataLogin?.userLoggedIn?.lastName
        }`,

      link: "/",
    },

    { id: 1, text: "Home", link: "/" },
    { id: 2, text: "About", link: "/aboutus" },
    {
      id: 3,
      text: "Trending Indian Destination",
      link: "/packagelisting",
    },
    {
      id: 4,
      text: "Trending Holidays Destinations",
      link: "/packagelisting",
    },
    { id: 5, text: "Contact Us", link: "/faqs#contact-us" },

    { id: 6, text: token && token ? "Setting" : null, link: "/setting" },
  ];
  const pages = [
    {
      id: 1,
      page: "About Us",
      link: "aboutus",
      // action:getAboutAction(),
    },
    // {
    //   id: 2,
    //   page: "FAQs",
    //   link: "faqs",
    //   // action:getServiceAction(),
    // },
    {
      id: 3,
      page: "Blog",
      link: "bloglisting",
      action: getBlogListAction(),
    },
    {
      id: 4,
      page: "Package",
      // link: "bloglisting",
      // action: getBlogListAction(),
    },
  ];
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  let navigate = useNavigate();
  const onLogoClick = () => {
    navigate("/");
  };
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  const clearCacheAndReload = () => {
    // Reload the page
    dispatch(successUsersSignInAction([]));
    dispatch(successUsersSignUpAction([]));
    dispatch(successForgotPasswordAction([]));
    dispatch(successVerifyOtpAction([]));
    dispatch(successPostResetPasswordAction([]));
    navigate("/");
  };

  const handleSettingClick = (setting) => {
    handleCloseUserMenu();
    switch (setting) {
      case "Personal Chat":
        navigate(`/chatPage/${dataLogin?.userLoggedIn?._id}`);
        break;
      case "Setting":
        navigate("/setting");
        break;
      case "Logout":
        clearCacheAndReload();
        break;
      default:
        break;
    }
  };
  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        Buddha Journey
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item.id} disablePadding>
            <ListItemButton
              sx={{ textAlign: "start" }}
              component={Link}
              to={item.link}
            >
              <ListItemText primary={item.text} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <Box sx={{ cursor: "pointer", marginTop: "5rem" }}>
        <img
          className="avatar"
          src={logo}
          alt="Buddha Logo"
          // onClick={() => onLogoClick()}
        />
      </Box>
      <Button
        sx={{ marginTop: "5rem" }}
        component="label"
        variant="none"
        startIcon={<CallIcon />}
      >
        <Link
          to="https://www.wa.link/du5y08"
          target="_blank"
        >
          <Typography sx={{ color: "black" }} variant="h7">
            +916204229390
          </Typography>
        </Link>
      </Button>
      {token && (
        <Button
          onClick={() => clearCacheAndReload()}
          sx={{ marginTop: "5rem" }}
          component="label"
          variant="none"
        >
          <Typography sx={{ color: "black" }} variant="h7">
            Logout
          </Typography>
        </Button>
      )}
      {!token && (
        <Button
          onClick={() => navigate("/signup")}
          sx={{ marginTop: "5rem" }}
          component="label"
          variant="none"
        >
          <Typography sx={{ color: "black" }} variant="h7">
            Sign Up
          </Typography>
        </Button>
      )}
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        component="nav"
        position="fixed"
        sx={{
          backgroundColor: "rgba(15, 15, 15,0.9)",
          pr: 2,
          pl: 2,
        }}
      >
        <Toolbar
          className="tool_bar"
          sx={{
            display: { sm: "flex" },
            justifyContent: "space-between",
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { xs: "block", md: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Box sx={{ display: { xs: "none", md: "block" }, cursor: "pointer" }}>
            <img
              className="avatar"
              src={logo}
              alt="Buddha Logo"
              onClick={() => onLogoClick()}
            />
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", sm: "none", md: "flex" },
              ml: 1,
            }}
          >
            {pages.map((element) => (
              <Button
                key={element.id}
                sx={{
                  color: "white",
                  textTransform: "none",
                }}
                // onClick={() => dispatch((element.action) ? element.action : null)}
                onClick={element.action ? () => dispatch(element.action) : null}
              >
                <Box mr={1} ml={1}>
                  <Link to={`/${element.link}`}>
                    {" "}
                    <Typography variant="navTabs"> {element.page} </Typography>
                  </Link>
                </Box>
              </Button>
            ))}
          </Box>

          <Box
            sx={{
              display: { xs: "none", sm: "none", md: "flex" },
            }}
          >
            <Button component="label" variant="none" startIcon={<CallIcon />}>
              <Link
                to="https://www.wa.link/du5y08"
                target="_blank"
              >
                <Typography variant="navTabs">+916204229390</Typography>
              </Link>
            </Button>

            {!token && (
              <Link to="/signin">
                <Button variant="outline" sx={{ mr: 2 }}>
                  Sign In
                </Button>
              </Link>
            )}
            {!token && (
              <Link to="/signup">
                <Button variant="dark" sx={{ mr: 2 }}>
                  Sign Up
                </Button>
              </Link>
            )}
            {token && (
              <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar alt="Remy Sharp" src="" />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {settings.map((setting) => (
                    <MenuItem
                      key={setting}
                      onClick={() => handleSettingClick(setting)}
                    >
                      <Typography textAlign="center">{setting}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              display: { xs: "flex", sm: "flex", md: "none" },
              alignItems: "flex-end",
              justifyContent: "flex-end",
            }}
          >
            {token && (
              <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar alt="Remy Sharp" src="" />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  {settings.map((setting) => (
                    <MenuItem
                      key={setting}
                      onClick={() => handleSettingClick(setting)}
                    >
                      <Typography textAlign="center">{setting}</Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </Box>
  );
}

DrawerAppBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default DrawerAppBar;
