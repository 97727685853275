import React from "react";
import "./UserHeader.scss";
import { Box, CardMedia, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import {
  getAdminGroupChatAction,
  getDialogFlagAction,
} from "../../../../reduxState/slices/BuddhaJourneySlices";
const UserHeader = () => {
  const dispatch = useDispatch();
  const dataLogin = useSelector(
    (r) => r.buddhaJourneySlice.users.SignInResponseValue.data
  );
  const handleChangeModal = () => {
    dispatch(getDialogFlagAction(true));
    dispatch(getAdminGroupChatAction("success"));
  };
  return (
    <>
      <Box className="UserHeader">
        <Box className="user_header_img_txt">
          <Box className="img_card">
            <CardMedia className="imageBox" image="" title="yellow" />
          </Box>
          <Box className="group_left_text">
            <Typography
              sx={{ color: "white", marginLeft: "1rem" }}
              variant="h5"
              gutterBottom
            >
              {dataLogin?.userLoggedIn?.firstName +
                " " +
                dataLogin?.userLoggedIn?.lastName}
              <Typography sx={{ color: "white" }} variant="body2">
                <CardMedia
                  sx={{
                    display: "inline-block",
                    backgroundColor: "green",
                    height: "10px",
                    borderRadius: "50%",
                    width: "10px",
                    marginRight: "1rem",
                  }}
                  className="activeIcon"
                  image=""
                  title="yellow"
                />
                I’m available ({dataLogin?.userLoggedIn?._id})
              </Typography>
            </Typography>
          </Box>
        </Box>

        <Box className="group_icons">
          <AddIcon
            onClick={handleChangeModal}
            sx={{ color: "white", marginRight: "1rem" }}
          />
        </Box>
      </Box>
    </>
  );
};

export default UserHeader;
