import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import PersonIcon from "@mui/icons-material/Person";
import "./UserChatModal.scss";
import EmailIcon from "@mui/icons-material/Email";
import ApartmentIcon from "@mui/icons-material/Apartment";
import ContactsIcon from "@mui/icons-material/Contacts";
import { Link } from "react-router-dom";
import { CardMedia } from "@mui/material";
import TextField from "@mui/material/TextField";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import {
  getAdminChatFlagAction,
  getDialogFlagAction,
} from "../../reduxState/slices/BuddhaJourneySlices";
import { useDispatch } from "react-redux";
import ChatHeader from "../../pages/ChatPage/ChatComponents/ChatHeader/ChatHeader";
import LoadMessageCard from "../../pages/ChatPage/ChatComponents/LoadMessageCard/LoadMessageCard";
import ChatField from "../../pages/ChatPage/ChatField/ChatField";
import FooterChat from "../../pages/ChatPage/ChatComponents/FooterChat/FooterChat";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "background.paper",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
  height: "80%",
  overflowY: "auto",
};
const styles = {
  textField: {
    width: "300px",
  },
  input: {
    borderBottom: "2px solid #0A9E88",
    "&::placeholder": {
      color: "#0A9E88", // Change the placeholder text color as needed
    },
  },
};
const UserChatModal = () => {
  const dispatch = useDispatch();
  //   const adminChatFlag = useSelector((e) => e.buddhaJourneySlice.adminChatFlag);
  //   const handleClose = () => {
  //     dispatch(getAdminChatFlagAction(false));
  //   };
  return (
    <>
      <Box sx={style}>
        <Box className="UserChatModal">
          <Box className="back_close">
            <Box className="back_icon">
              <ArrowBackIcon
                onClick={() => dispatch(getAdminChatFlagAction(false))}
              />
            </Box>
            <Box className="cross_icon">
              <CloseIcon
                onClick={() => dispatch(getAdminChatFlagAction(false))}
              />
            </Box>
          </Box>
          <Box className="user_header">
            <ChatHeader />
          </Box>
          <Box>
            <LoadMessageCard />
          </Box>
          <Box>
            <ChatField />
          </Box>

          <Box className="userChat">
            <ChatField />
          </Box>
          <Box>
            <FooterChat />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default UserChatModal;
