import React, { useEffect, useState } from "react";
import "./FooterChat.scss";
import { Box, CardMedia, TextField, Button } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { useSelector } from "react-redux";

const FooterChat = ({ socket, selectedRoom }) => {
  const [message, setMessage] = useState("");
  const dataLogin = useSelector((r) => r.buddhaJourneySlice.users);
  const userLoggedIn = dataLogin?.SignInResponseValue?.data?.userLoggedIn?._id;

  const handleSubmit = (e) => {
    e.preventDefault();
    if (message.trim()) {
      socket.emit("newMessage", {
        content: message,
        sender: userLoggedIn,
        room: selectedRoom,
      });
      setMessage("");
    }
  };

  useEffect(() => {
    console.log("message ===>", message);
  }, [message]);
  // import { useDispatch } from "react-redux";
  // import {
  //   getAttachmentFileDataAction,
  //   getChatAttachmentFlagAction,
  // } from "../../../../reduxState/slices/BuddhaJourneySlices";
  // import AWS from "aws-sdk";

  // const FooterChat = () => {
  //   const dispatch = useDispatch();
  //   const [file, setFile] = useState(null);
  //   const [allImages, setAllImages] = useState([]);
  //   // function handleChange(event) {
  //   //   const selectedFile = event.target.files[0];
  //   //   const reader = new FileReader();

  //   //   reader.onload = () => {
  //   //     const url = reader.result;
  //   //     console.log("27-03-24 01", url);

  //   //     // Get file extension
  //   //     const fileExtension = selectedFile.name.split(".").pop().toLowerCase();

  //   //     // Output based on file extension
  //   //     let fileType;
  //   //     if (fileExtension === "pdf") {
  //   //       fileType = "PDF";
  //   //     } else if (["mp4", "mov", "avi", "mkv"].includes(fileExtension)) {
  //   //       fileType = "Video";
  //   //     } else if (["doc", "docx"].includes(fileExtension)) {
  //   //       fileType = "Document";
  //   //     } else if (["jpg", "jpeg", "png", "gif"].includes(fileExtension)) {
  //   //       fileType = "Image";
  //   //     } else if (fileExtension === "zip") {
  //   //       fileType = "ZIP File";
  //   //     } else {
  //   //       fileType = "Unknown";
  //   //     }

  //   //     // Setting state with file URL and file type
  //   //     setFile({ url, fileType });
  //   //     dispatch(getAttachmentFileDataAction({ url, fileType }));
  //   //   };

  //   //   reader.onerror = (error) => {
  //   //     console.error("Error occurred while reading the file:", error);
  //   //   };

  //   //   if (selectedFile) {
  //   //     reader.readAsDataURL(selectedFile);
  //   //   }
  //   // }
  //   const handleChange = async (e) => {
  //     if (e.target.files.length === 0) return;

  //     const file = e.target.files[0];
  //     console.log("29-03-24 01", file);
  //     const S3_BUCKET = "postpublisher";
  //     const REGION = "us-east-1";

  //     AWS.config.update({
  //       accessKeyId: "AKIA2UC3EP44ICMIRE6Q",
  //       secretAccessKey: "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",
  //       region: REGION,
  //     });

  //     const s3 = new AWS.S3();

  //     try {
  //       const params = {
  //         Bucket: S3_BUCKET,
  //         Key: file.name.replace(/ /g, "_"),
  //         Body: file,
  //         // ContentType is removed
  //       };
  //       await s3.upload(params).promise();
  //       const s3Url = `https://s3.${AWS.config.region}.amazonaws.com/${params.Bucket}/${params.Key}`;
  //       console.log("29-03-24 04", s3Url);
  //       // Update the state with the S3 URL
  //       setAllImages([s3Url]);
  //       dispatch(getAttachmentFileDataAction(s3Url));
  //     } catch (error) {
  //       console.error("Error handling image:", error);
  //       // Handle error if needed
  //     }
  //   };

  //   console.log("29-03-24 02", allImages);

  return (
    <Box className="parent_footer_chat">
      <form className="footer_chat" onSubmit={handleSubmit}>
        <Box className="footer_chat">
          <Box className="input_box">
            <TextField
              InputProps={{
                style: {
                  borderRadius: "20px",
                  backgroundColor: "white",
                },
              }}
              fullWidth
              label="Type Your Message"
              id="fullWidth"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </Box>
          <Box className="send_icons">
            <Box className="attachemet_icon">
              <input
                id="file-input"
                type="file"
                accept="image/*"
                // onChange={handleChange}
                hidden
              />
              <AttachFileIcon
                sx={{
                  color: "#0a9e88",
                  height: "60px",
                  width: "60px",
                  transform: "rotate(90deg)",
                }}
                // onClick={() => dispatch(getChatAttachmentFlagAction(true))}
                onClick={() => {
                  const fileInput = document.getElementById("file-input");
                  fileInput.click(); // Trigger file input when the edit icon is clicked
                }}
                className="icon"
              />
            </Box>
            <Box className="imageBox">
              <Button type="submit">
                <SendIcon sx={{ color: "white", cursor: "pointer" }} />
              </Button>
            </Box>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default FooterChat;
