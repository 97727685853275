import { Box, Button, CardMedia, Link, Typography } from "@mui/material";
import React from "react";
import "./NotFound.scss";
import imgError from "../../assets/images/404.png";

const NotFound = () => {
  return (
    // <Box sx={{ textAlign: "center" }}>
    //   <Typography variant="boldFourtyEight">404: Page Not Found</Typography>
    // </Box>
    <Box className="pageNotFound">
      <Box className="leftSideImg">
        <CardMedia image={imgError} title="error img" component="img" />
      </Box>
      <Box className="rightSideText">
        <Box className="innerText">
          <Typography sx={{ color: "#0A9E88" }} variant="h1">
            404
          </Typography>
          <Typography variant="h4" gutterBottom>
            Page Not Found
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            We’re sorry, the page you requested could not be found Please go
            back to the homepage
          </Typography>

          <Box sx={{ marginTop: "2rem" }}>
            <Link href="/">
              <Button variant="darkNmedium">Go Home</Button>
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default NotFound;
