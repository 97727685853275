import "./Help.scss";
import { useState } from "react";
import { Box } from "@mui/material";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import HelpForm from "../HelpForm/HelpForm";
import { useDispatch, useSelector } from "react-redux";
import { getHelpBoxAction } from "../../../reduxState/slices/BuddhaJourneySlices";
const Help = () => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const showHelpBox = useSelector((r) => r.buddhaJourneySlice.helpBox);
  return (
    <Box>
      <Box
        className="helpBlock"
        onClick={() => dispatch(getHelpBoxAction(!showHelpBox))}
      >
        <Box className="innerHelp">
          <ChatBubbleIcon sx={{ color: "#fff" }} />
        </Box>
      </Box>
      {showHelpBox && (
        <HelpForm
          mainHeading="How can we help?"
          subHeading="We usually respond in a few hours"
          name="Name"
          firstInputName="Subject"
          mail="Email Address"
          mobile="Mobile no."
          comments="How can we help you?"
        />
      )}
    </Box>
  );
};

export default Help;
