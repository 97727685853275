import React, { useEffect, useState } from "react";
import SignInSignup from "../../SignInSignup";
import "./VerifyEmail.scss";
import { Box, Typography, Button } from "@mui/material";
import OtpInput from "react-otp-input";
import { useDispatch, useSelector } from "react-redux";
import {
  getverifyemailDataAction,
  postVerifyOtpAction,
} from "../../../../reduxState/slices/BuddhaJourneySlices";
import { useNavigate } from "react-router-dom";

const VerifyEmail = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const stateValue = useSelector(
    (e) => e.buddhaJourneySlice.verifyemail.verifyemailData
  );
  // const usersData = useSelector((e) => e.buddhaJourneySlice.users);
  const verifyOtpResponse = useSelector(
    (r) => r.buddhaJourneySlice.users.VerifyOtpResponseValue.status
  );
  let search = window.location.search;
  let params = search.split("?")[1];
  console.log("15-03-24 001 05", params);
  console.log("15-03-24 001 08", window.location.href);
  console.log("15-03-24 001 05", params);
  console.log("15-03-24 001 06", verifyOtpResponse);

  // console.log("15-03-24 001 02", OtpResponse);
  useEffect(() => {
    //TermsofuseData page api call
    dispatch(getverifyemailDataAction());
  }, [dispatch]);
  // console.log(stateValue, "data collected in verifymail");
  // useEffect(() => {
  //   first

  //   return () => {
  //     second
  //   }
  // }, [p])

  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  function validateForm() {
    if (/^\d{4}$/.test(otp)) {
      console.log("15-03-24 001 01", otp);
      setOtp(otp);
      setError("");
      const VerifyOtpData = {
        emailId: params,
        emailOTP: otp,
      };

      dispatch(postVerifyOtpAction(VerifyOtpData));
      console.log("otp is", otp);
    } else {
      setError("Enter a valid 4-digit PIN");
    }
  }
  useEffect(() => {
    if (verifyOtpResponse === true) {
      let search = window.location.search;
      let params = search.split("?")[1];

      navigate(`/reset?${params}`);
    }
  }, [verifyOtpResponse]);
  return (
    <Box className="verifyBlock">
      <Box className="leftPicBlock">
        <SignInSignup />
      </Box>
      <Box className="signup">
        <Box className="innerSignup">
          <Box className="createText">
            <Typography variant="boldFourty">{stateValue.title}</Typography>
          </Box>
          <Box className="letStarted">
            <Typography variant="regularTwentyFour">
              {stateValue.titleStarted}
            </Typography>
            <Typography variant="regularTwentyFour" color="primary">
              {params}
            </Typography>
          </Box>

          <Box sx={{ width: "100%" }}>
            <OtpInput
              inputStyle="otpStyle"
              value={otp}
              onChange={setOtp}
              numInputs={4}
              renderSeparator={<span>--</span>}
              renderInput={(props) => <input {...props} />}
            />
            {error && <Box className="error">{error}</Box>}
          </Box>

          <Box>
            <Button
              variant="contained"
              className="createBtn"
              type="submit"
              onClick={() => {
                validateForm();
              }}
            >
              {stateValue.createAccountButton}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default VerifyEmail;
