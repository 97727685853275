import { Box,Typography } from "@mui/material";
import DrawerAppBar from "../../components/Global/Navbar/Navbar";
import "./Setting.scss";
import TabForSetting from "./Components/Tabs/TabForSetting";
import Footer from "../../components/Global/Footer/Footer"
const Setting = () => {
  return (
   <>
      <DrawerAppBar />
      <Box className="settingPage">
      <Box className="settingBlock">
        <Typography variant="boldThirtyTwo" color="primary"> Setting</Typography>
      </Box>
      <TabForSetting/>

    </Box>
    <Footer/>
    </>
  );
};
export default Setting;
