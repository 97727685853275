import { Box, CardMedia, Typography } from "@mui/material";
import React from "react";
import DrawerAppBar from "../../components/Global/Navbar/Navbar";
import "./BlogThree.scss";
import { useSelector } from "react-redux";
import CenterText from "../../components/Reusable/CenterText/CenterText";
import BlogAdCard from "../../components/Reusable/BlogAdCard/BlogAdCard";
import IndianDestCard from "../../components/Reusable/IndianDestCard/IndianDestCard";
import Carousel from "react-multi-carousel";
import Footer from "../../components/Global/Footer/Footer";
import { useParams } from "react-router-dom";
import { blogCardData, responsiveIndianCard } from "../../Data.js";
const BlogThree = () => {
  const stateValue = useSelector(
    (e) => e.buddhaJourneySlice.blogList.blogListValue
  );
  const { id} = useParams();

  return (
    <Box className="blogThreeBlock">
      <DrawerAppBar />
      <Box>
        <CardMedia
          image={blogCardData.cardData[2].firstImage}
          title="BuddhaJournesdfdsfy"
          component="img"
        />
        <Box className="contentBlock">
          <Box className="headingBlock">
            <Box>
              <Typography variant="boldFourtyEightWhite" color="primary">
                {stateValue.cardData[id].blogHeading}
              </Typography>
            </Box>
          </Box>
          <Box className="imageTextBlock">
            <Box className="paraOne">
              <Typography color="secondary" variant="regularTwentyFour">
                {stateValue.cardData[id].paraOne}
              </Typography>
            </Box>
            <Box>
              <CardMedia
                image={blogCardData.cardData[id].img}
                title="BuddhaJourneyddd"
                component="img"
              />
            </Box>
            <Box className="paraTwo">
              <Typography color="secondary" variant="regularTwentyFour">
                {stateValue.cardData[id].paraTwo }
              </Typography>
            </Box>
            <Box>
              <Typography color="secondary" variant="regularTwentyFour">
                {stateValue.cardData[id].paraThree }
                
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <CenterText/>
      <Box className="bloglistMarginBottom">
            <Carousel responsive={responsiveIndianCard}>
              {blogCardData?.cardData.map((element) => {
                return (
                  <Box>
                    <IndianDestCard
                      key={element.id}
                      img={element.img}
                      para={element.para}
                      Rating={element.Rating}
                      CardHeading={element.CardHeading}
                      isTrue={element.isTrue}
                    />
                  </Box>
                );
              })}
            </Carousel>
          </Box>
          <Box className="blogAd">
              <BlogAdCard/>
          </Box>
          <Footer/>
    </Box>
  );
};

export default BlogThree;
