import { Box, CardMedia, Typography } from "@mui/material";
import React from "react";
import "./FeatureCard.scss";
import { featureCardData } from "../../../Data";

const FeatureCard = ({ data }) => {
  // console.log("data", data);
  return (
    <Box className="featureCardBlock">
      {data.map((element) => {
        const { id,heading, para } = element;
        return (
          <Box key={id}>
            <Box className="innerPadding">
              <Box className="innerImg">
                <CardMedia
                  image={featureCardData[id - 1].img}
                  // image={img}
                  title="BuddhaJourney"
                  className="image"
                  component="img"
                />
              </Box>
              <Box className="textBlock">
                <Box mb={1.3} mt={1.3}>
                  <Typography variant="footerHead" color="primary">
                    {heading}
                  </Typography>
                </Box>

                <Typography>{para}</Typography>
              </Box>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default FeatureCard;
