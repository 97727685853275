import { Box, Typography, Button, CardMedia } from "@mui/material";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import SignInSignup from "../../SignInSignup";
import fblogo from "../../../../assets/images/fblogo.png";
import googlelogo from "../../../../assets/images/googlelogo.png";
import "./SignUp.scss";
import {
  MyInput,
  validationSchema,
} from "../../../../components/Global/MyInput/MyInput";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  getCreateAccountDataAction,
  postUsersSignUpAction,
} from "../../../../reduxState/slices/BuddhaJourneySlices";
const SignUp = () => {
  const dispatch = useDispatch();
  const createAccount = useSelector(
    (e) => e.buddhaJourneySlice.createAccountData.createAccountValue
  );
  useEffect(() => {
    console.log("useEffect called signup");
    dispatch(getCreateAccountDataAction());
  }, []);
  return (
    <Box className="signupBlock">
      <Box className="leftPicBlock">
        <SignInSignup />
      </Box>
      <Box className="signup">
        <Formik
          initialValues={{
            username: "",
            email: "",
            password: "",
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            const userSinupData = {
              firstName: values.username,
              emailId: values.email,
              password: values.password,
            };
            console.log("14-03-24 01", values);
            dispatch(postUsersSignUpAction(userSinupData));
          }}
        >
          <Form className="innerSignup">
            <Box className="createText">
              <Typography variant="boldFourty">
                {createAccount?.title}
              </Typography>
            </Box>
            <Box className="letStarted">
              <Typography variant="regularTwentyFour">
                {createAccount?.titleStarted}
              </Typography>
            </Box>
            <Box className="alreadyMember">
              <Typography variant="regular" sx={{ color: "#fff" }}>
                {createAccount?.alreadyText}
              </Typography>{" "}
              <Link to="/signin">
                <Typography variant="regular" color="primary">
                  {createAccount?.alreadySignText}
                </Typography>
              </Link>
            </Box>
            <Box>
              <MyInput
                id="username"
                name="username"
                label={createAccount?.namePlaceholder}
                variant="filled"
                // className="inputWidth"
              />
            </Box>
            <Box>
              <MyInput
                id="email"
                name="email"
                label={createAccount?.emailIdPlaceholder}
                variant="filled"
              />
            </Box>
            <Box>
              <MyInput
                id="password"
                name="password"
                label={createAccount?.passwordPlaceholder}
                variant="filled"
              />
            </Box>
            <Box>
              <Button variant="contained" className="createBtn" type="submit">
                {createAccount?.createAccountButton}
              </Button>
            </Box>
            <Box className="lastRowText">
              <Typography color="#fff">
                By clicking &nbsp;{createAccount?.terms2} &nbsp;
                {createAccount?.terms3}{" "}
                <Link to="/termofuse" className="linkColor">
                  {" "}
                  &nbsp;{createAccount?.terms4}
                </Link>{" "}
                &nbsp;{createAccount?.terms5}
                <Link to="/privacypolicy" className="linkColor">
                  &nbsp; {createAccount?.terms6}{" "}
                </Link>
              </Typography>
            </Box>
            {/* <Box className="lastRowLogo">
              <CardMedia
                href={createAccount?.socialFacebook}
                component="img"
                image={fblogo}
                sx={{ marginRight: "16px" }}
                className="iconSize"
              />
              <CardMedia
                component="img"
                image={googlelogo}
                className="iconSize"
              />
            </Box> */}
          </Form>
        </Formik>
      </Box>
    </Box>
  );
};

export default SignUp;
