import { Box, Typography, Button } from "@mui/material";
import React, { useEffect } from "react";
import SignInSignup from "../../SignInSignup";
import * as Yup from "yup";
import "./Forgot.scss";
import { MyInput } from "../../../../components/Global/MyInput/MyInput";
import { Form, Formik } from "formik";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getForgotPasswordDataAction,
  postForgotPasswordAction,
  successForgotPasswordAction,
} from "../../../../reduxState/slices/BuddhaJourneySlices";
const Forgot = () => {
  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email address").required("Required"),
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const forgotPassword = useSelector(
    (e) => e.buddhaJourneySlice.forgotPasswordData.forgotPasswordValue
  );
  const forgotResponseData = useSelector((e) => e.buddhaJourneySlice.users);
  const forgotResponse = forgotResponseData.ForgotPasswordResponseValue.message;

  useEffect(() => {
    dispatch(getForgotPasswordDataAction());
  }, []);

  useEffect(() => {
    if (forgotResponse === "Mail Sent Successfully!") {
      navigate(
        `/verify?${forgotResponseData.ForgotPasswordResponseValue.data.emailId}`
      );
      dispatch(successForgotPasswordAction([]));
    }
  }, [forgotResponse]);
  return (
    <Box className="forgotblock">
      <Box className="leftPicBlock">
        <SignInSignup />
      </Box>
      <Box className="signup">
        <Formik
          initialValues={{
            email: "",
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            const forgotData = {
              emailId: values.email,
            };
            console.log("15-03-24 01", values);
            dispatch(postForgotPasswordAction(forgotData));
          }}
        >
          <Form className="innerSignup">
            <Box className="createText">
              <Typography variant="boldFourty">
                {forgotPassword?.title}
              </Typography>
            </Box>
            <Box className="letStarted">
              <Typography variant="regularTwentyFour" color="primary">
                {forgotPassword?.titleStarted}
              </Typography>
            </Box>
            <Box className="alreadyMember">
              <Typography variant="regular" sx={{ color: "#fff" }}>
                {forgotPassword?.alreadyText}
              </Typography>{" "}
            </Box>
            <Box>
              <MyInput
                id="email"
                name="email"
                label={forgotPassword?.emailIdPlaceholder}
                variant="filled"
              />
            </Box>
            <Box>
              <Button variant="contained" className="createBtn" type="submit">
                {forgotPassword?.createAccountButton}
              </Button>
            </Box>
          </Form>
        </Formik>
      </Box>
    </Box>
  );
};

export default Forgot;
