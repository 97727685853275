import { Box, Typography, TextField, Button, colors } from "@mui/material";
import React from "react";
import "./HelpForm.scss";
import { Form, Formik } from "formik";
import {
  MyTextField,
  validationSchema,
} from "../../Global/MyTextField/MyTextField";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getHelpBoxAction } from "../../../reduxState/slices/BuddhaJourneySlices";
// import {
//   MyTextField,
//   validationSchema,
// } from "../../../components/Global/MyTextField/MyTextField";
const HelpForm = (props) => {
  const {
    mainHeading,
    subHeading,
    firstInputName,
    mail,
    mobile,
    comments,
    name,
  } = props;
  const dispatch = useDispatch();
  const dataLogin = useSelector(
    (r) => r.buddhaJourneySlice.users.SignInResponseValue.data
  );
  const token = useSelector(
    (r) => r.buddhaJourneySlice?.users?.SignInResponseValue?.data?.access?.token
  );
  return (
    <Box className="helpForm">
      <Box className="headingSection">
        <Box className="heading_help">
          <Box>
            <Typography variant="boldSixteen" sx={{ color: "#fff" }}>
              {mainHeading}
            </Typography>
          </Box>
          {token && (
            <Link
              to={`/chatPage/${dataLogin?.userLoggedIn?._id}`}
              onClick={() => dispatch(getHelpBoxAction(false))}
            >
              <Box className="personal_chat_btn">
                <Button className="btn_chat">Chat</Button>
              </Box>
            </Link>
          )}
        </Box>
        <Box>
          <Typography variant="subText" sx={{ color: "#fff" }}>
            {subHeading}
          </Typography>
        </Box>
      </Box>
      <Formik
        initialValues={{
          fullName: "",
          subject: "",
          email: "",
          mobile: "",
          comment: "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          console.log("13-03-24 01", values);
        }}
      >
        <Form>
          <Box className="textFieldSection">
            <Box className="textFieldInnerBox">
              <Box>
                <Typography>
                  {name}
                  <span style={{ color: "red" }}>*</span>
                </Typography>

                <MyTextField
                  id="fullName"
                  name="fullName"
                  // variant="outlined"
                  className="supportTextFiled"
                />
              </Box>
              <Box>
                <Typography>{firstInputName}</Typography>
                <MyTextField
                  id="subject"
                  name="subject"
                  className="supportTextFiled"
                />
              </Box>
              <Box mt={2}>
                <Typography>{mail}</Typography>
                <MyTextField
                  id="email"
                  name="email"
                  className="supportTextFiled"
                />
              </Box>
              <Box mt={2}>
                <Typography>
                  {mobile}
                  <span style={{ color: "red" }}>*</span>
                </Typography>
                <MyTextField
                  id="mobile"
                  name="mobile"
                  className="supportTextFiled"
                />
              </Box>
              <Box mt={2}>
                <Typography>{comments}</Typography>
                <MyTextField
                  id="comment"
                  name="comment"
                  multiline
                  rows={5}
                  maxRows={6}
                  className="supportTextFiled"
                />
              </Box>
              <Box mt={2}>
                <Button variant="contained"> Sumbit</Button>
              </Box>
            </Box>
          </Box>
        </Form>
      </Formik>
    </Box>
  );
};

export default HelpForm;
