import { Box, CardMedia, Typography } from "@mui/material";
import React from "react";
import testimonial from "../../../assets/images/testimonial.png";
import doubleQuotes from "../../../assets/images/doubleQuotes.png";
import rectangle from "../../../assets/images/rectangle.png";
import "./Testimonials.scss";
import {useSelector } from "react-redux";
const Testimonials = () => {
  const stateValue = useSelector(
    (e) => e.buddhaJourneySlice.homepage.homepageData
  );
  return (
    <Box className="testimonialBlock">
      <Box className="whyUsText">
        <Typography variant="boldFourtyEight">
          {stateValue.whyToChooseUs.title}
        </Typography>
      </Box>
      <Box className="imageAndText">
        <Box className="testimonialImage">
          <CardMedia image={testimonial} title="green iguana" component="img" />
        </Box>
        <Box className="testimonialText">
          <CardMedia
            className="doubleQuotes"
            image={doubleQuotes}
            title="green iguana"
            component="img"
          />
          <Box className="theText">
          <Typography variant="regular">
          {stateValue.whyToChooseUs.whyData[0].para}
          </Typography>
          </Box>
          <Box className="writer">
            <Box>
              {" "}
              <CardMedia
                className=""
                image={rectangle}
                title="green iguana"
                component="img"
              />
            </Box>
            <Typography variant="writerFont">{stateValue.whyToChooseUs.whyData[0].author}</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Testimonials;
