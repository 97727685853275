import { Box, Grid, TextField, Autocomplete, Button } from "@mui/material";
import "./General.scss";
import { useState } from "react";
import { boolean } from "yup";
const General = () => {
  const [loc, setLoc] = useState("");
  const [tourGuide, setTourGuide] = useState("");
  const [language, setLanguage] = useState("");
  const [mode, setMode] = useState("");
  const [emailNotifiaction, setEmailNotification] = useState("");
  const [downloadReport, setDownloadReport] = useState("");
  const handleData = () => {
    console.log("Report =", downloadReport);
    console.log("tour Guide =", tourGuide);
    console.log("language =", language);
    console.log("Mode = ", mode);
    console.log("Notification = ", emailNotifiaction);
    console.log("location =", loc);
  };
  const handleReset=()=>{
    console.log("clicked")
    setLoc(" ")
    setTourGuide(" ")
    setLanguage(" ")
    setMode(" ")
    setEmailNotification(" ")
    setDownloadReport(" ")
  }
  return (
    <Box className="generalTab">
      <Box className="innerGeneral">
        <Box className="firstLine">
          <Autocomplete
            onChange={(event, value) => setLoc(value)}
            className="input"
            options={locationData}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Location" />}
          />{" "}
          <Autocomplete
            className="input"
            onChange={(event, value) => setTourGuide(value)}
            options={tourGuideData}
            sx={{ width: 300 }}
            renderInput={(params) => (
              <TextField {...params} label="Enable Tour Guide" />
            )}
          />{" "}
        </Box>

        <Box className="firstLine">
          <Autocomplete
            className="input"
            onChange={(event, value) => setLanguage(value)}
            options={languageData}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Language" />}
          />{" "}
          <Autocomplete
            className="input"
            onChange={(event, value) => setMode(value)}
            options={modeData}
            sx={{ width: 300 }}
            renderInput={(params) => <TextField {...params} label="Mode" />}
          />{" "}
        </Box>
        <Box className="firstLine">
          <Autocomplete
            className="input"
            onChange={(event, value) => setEmailNotification(value)}
            options={emailNotificationData}
            sx={{ width: 300 }}
            renderInput={(params) => (
              <TextField {...params} label="Email Notification" />
            )}
          />{" "}
          <Autocomplete
            className="input"
            onChange={(event, value) => setDownloadReport(value)}
            options={reportData}
            sx={{ width: 300 }}
            renderInput={(params) => (
              <TextField {...params} label="Download Report" />
            )}
          />{" "}
        </Box>
        <Box className="btnBlock">
          <Button type="reset" variant="outlined" className="btn" onClick={handleReset}>
            Discard
          </Button>
          <Button
            type="submit"
            variant="formButton"
            className="btn"
            onClick={handleData}
          >
            Update Password
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
export default General;
const locationData = ["kolkata", "Britan", "England", "London"];
const tourGuideData = ["Enable Tour Guide", "Disable Tour Guide"];
const languageData = ["English", "Hindi"];
const modeData = ["Light", "Dark"];
const emailNotificationData = [
  "Post Email Notificatiion On",
  "Post Email Notificatiion Off",
];
const reportData = ["true", "false"];
