import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { Typography, Box, Link } from "@mui/material";
import img from "../../../assets/images/himalaya.png";
import "./DeveloperCard.scss";
import { developersData } from "../../../Data";
const CardHeading = "Badal Mehta";
const para = "Sr.Software Developer";

const DeveloperCard = () => {
  return (
    <Box className="developerfirstCardBox">
      {developersData.map((item) => {
        return (
          <Box className="firstInnerBox">
            <CardMedia className="imageBox" image={item.image} title="green" />
            <CardContent className="cardContent">
              <Box className="headingSection">
                <Box className="iconNname">
                  <Typography
                    sx={{ fontWeight: "bold", fontSize: 20 }}
                    variant="cardHead"
                  >
                    {item.name}
                  </Typography>
                </Box>
                <Typography
                  sx={{ fontWeight: "regular" }}
                  className="sub-name"
                  variant="subText"
                >
                  {item.role}
                </Typography>
              </Box>
            </CardContent>
          </Box>
        );
      })}
    </Box>
  );
};

export default DeveloperCard;
