import "./App.css";
import { ThemeProvider } from "@mui/material/styles";
import { appTheme } from "./AppTheme";
import Router from "./routes";
import Help from "./components/Reusable/Help/Help";
import ToastBar from "./components/Global/ToastBar/ToastBar";
function App() {
  return (
    <ThemeProvider theme={appTheme}>
      <ToastBar />
      <Help />
      <Router />
    </ThemeProvider>
  );
}

export default App;
