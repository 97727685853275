import React from "react";
import "./BlogCreation.scss";
import { Box, Button, Typography } from "@mui/material";

import InputField from "../ComponentsForCreation/InputField/InputField";
import ImageUpload from "../ComponentsForCreation/ImageUpload/ImageUpload";
import DropdownField from "../ComponentsForCreation/DropdownField/DropdownField";
import DescriptionField from "../ComponentsForCreation/DescriptionField/DescriptionField";
import TitleBox from "../ComponentsForCreation/TitleBox/TitleBox";
const BlogCreation = () => {
  return (
    <Box className="BlogCreation">
      <Box>
        <Typography variant="semiBoldThirtyTwo"> Blogs</Typography>
      </Box>
      <Box className="viewAndManage">
        <Typography>View and manage all blogs here</Typography>
      </Box>
      <Box className="mainTitle">
        <TitleBox title="Blog Format One" />
        <Box className="inputFieldsSize">
          <InputField
            id="blogCardHeading"
            name="blogCardHeading"
            variant="outlined"
            label="Blog Card Heading(Short)"
          />
          <InputField label="Rating" />
          <InputField label="Blog Heading" />
        </Box>
        <DescriptionField label="Short Description" rows={1} />
        <Box className="inputFieldsSize">
          <DescriptionField label="Paragraph One" />
          <DescriptionField label="Paragraph Two" />
          <DescriptionField label="Paragraph Three" />
        </Box>
        <Box className="uploadImgBox">
          <Box className="uploadbox1">
            <ImageUpload />
          </Box>
          <Box className="uploadbox2">
            <ImageUpload />
          </Box>
        </Box>
      </Box>
      <Box className="mainTitle">
        <TitleBox title="Blog Format Two" />
        <Box className="inputFieldsSize">
          <InputField label="Blog Card Heading(Short)" />
          <InputField label="Rating" />
          <InputField label="Blog Heading" />
        </Box>
        <DescriptionField label="Short Description" rows={1} />
        <Box className="inputFieldsSize">
          <DescriptionField label="Paragraph One" />
          <DescriptionField label="Paragraph Two" />
          <DescriptionField label="Paragraph Three" />
        </Box>
        <Box className="uploadImgBox">
          <Box className="uploadbox1">
            <ImageUpload />
          </Box>
          <Box className="uploadbox2">
            <ImageUpload />
          </Box>
        </Box>
      </Box>
      <Box className="mainTitle">
        <TitleBox title="Blog Format Three" />
        <Box className="inputFieldsSize">
          <InputField label="Blog Card Heading(Short)" />
          <InputField label="Rating" />
          <InputField label="Blog Heading" />
        </Box>
        <DescriptionField label="Short Description" rows={1} />
        <Box className="inputFieldsSize">
          <DescriptionField label="Paragraph One" />
          <DescriptionField label="Paragraph Two" />
          <DescriptionField label="Paragraph Three" />
        </Box>
        <Box className="uploadImgBox">
          <Box className="uploadbox1">
            <ImageUpload />
          </Box>
          <Box className="uploadbox2">
            <ImageUpload />
          </Box>
        </Box>
      </Box>
      <Box className="mainTitle">
        <TitleBox title="Blog Format Four" />
        <Box className="inputFieldsSize">
          <InputField label="Blog Card Heading(Short)" />
          <InputField label="Rating" />
          <InputField label="Blog Heading" />
        </Box>
        <DescriptionField label="Short Description" rows={1} />
        <Box className="inputFieldsSize">
          <DescriptionField label="Paragraph One" />
          <DescriptionField label="Paragraph Two" />
          <DescriptionField label="Paragraph Three" />
        </Box>
        <Box className="uploadImgBox">
          <Box className="uploadbox1">
            <ImageUpload />
          </Box>
          <Box className="uploadbox2">
            <ImageUpload />
          </Box>
        </Box>
      </Box>
      <Box className="mainTitle">
        <TitleBox title="Blog Format Five" />
        <Box className="inputFieldsSize">
          <InputField label="Blog Card Heading(Short)" />
          <InputField label="Rating" />
          <InputField label="Blog Heading" />
        </Box>
        <DescriptionField label="Short Description" rows={1} />
        <Box className="inputFieldsSize">
          <DescriptionField label="Paragraph One" />
          <DescriptionField label="Paragraph Two" />
          <DescriptionField label="Paragraph Three" />
        </Box>
        <Box className="uploadImgBox">
          <Box className="uploadbox1">
            <ImageUpload />
          </Box>
          <Box className="uploadbox2">
            <ImageUpload />
          </Box>
        </Box>
      </Box>
      <Box className="mainTitle">
        <TitleBox title="Blog Format Six" />
        <Box className="inputFieldsSize">
          <InputField label="Blog Card Heading(Short)" />
          <InputField label="Rating" />
          <InputField label="Blog Heading" />
        </Box>
        <DescriptionField label="Short Description" rows={1} />
        <Box className="inputFieldsSize">
          <DescriptionField label="Paragraph One" />
          <DescriptionField label="Paragraph Two" />
          <DescriptionField label="Paragraph Three" />
        </Box>
        <Box className="uploadImgBox">
          <Box className="uploadbox1">
            <ImageUpload />
          </Box>
          <Box className="uploadbox2">
            <ImageUpload />
          </Box>
        </Box>
      </Box>
      <Box className="submit_reset">
        <Button
          sx={{
            backgroundColor: "white",
            color: "#0a9e88",
            border: "1px solid #0a9e88",
          }}
          type="reset"
          variant="formButton"
        >
          Reset
        </Button>
        <Button type="submit" variant="formButton">
          Submit
        </Button>
      </Box>
    </Box>
  );
};

export default BlogCreation;
