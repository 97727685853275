import { Box, Typography, TextField, Button } from "@mui/material";
import { Form, Formik, useField } from "formik";
import * as Yup from "yup";
import "./ChangePassword.scss";
const ChangePassword = () => {
  return (
    <Box className="ChangePasswordTab">
      <Box className="ChangePassword">
        <Box>
          <Typography variant="semiBoldThirtyTwo">Change Password</Typography>
        </Box>
        <Formik
          initialValues={{
            currentPassword: "",
            newPassword: "",
            confirmPassword: "",
          }}
          validationSchema={changePasswordValidationSchema}
          onSubmit={(values) => console.log("values", values)}
        >
          <Form className="changePasswordForm">
            <Box className="formInner">
              <MyTextInput
                id="currentPassword"
                name="currentPassword"
                label="Current Password"
              />
              <MyTextInput
                id="newPassword"
                name="newPassword"
                label="New Password"
              />
              <MyTextInput
                id="confirmPassword"
                name="confirmPassword"
                label="Confirm Password"
              />
              <Button type="submit" variant="formButton" className="btn">
                Update Password
              </Button>
            </Box>
          </Form>
        </Formik>
      </Box>
    </Box>
  );
};
export default ChangePassword;

const changePasswordValidationSchema = Yup.object({
  currentPassword: Yup.string().required("Current password is required"),
  newPassword: Yup.string()
    .required("New password is required")
    .min(8, "Password must be at least 8 characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]/,
      "Password must contain at least one uppercase,one lowercase, one digit, and one special character"
    ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
    .required("Please confirm your password"),
});

const MyTextInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <Box>
      <TextField
        variant="outlined"
        className="input"
        {...field}
        {...props}
        label={label}
      />

      {meta.touched && meta.error ? (
        <Box className="error">{meta.error}</Box>
      ) : null}
    </Box>
  );
};
