import React from "react";
import "./DescriptionField.scss";
import TextField from "@mui/material/TextField";
import { useField } from "formik";

const DescriptionField = ({ label, rows, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <TextField
      {...field}
      {...props}
      id="outlined-basic"
      label={label || "Description"}
      variant="outlined"
      multiline
      rows={rows || 5}
      className="descriptionField"
    />
  );
};

export default DescriptionField;
