import { TextField } from "@mui/material";
import React from "react";
import "./InputField.scss";
import { useField } from "formik";
const InputField = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <TextField
      {...field}
      {...props}
      className="textField"
      variant="outlined"
      label={label}
    />
  );
};

export default InputField;
