import { Box, Typography } from "@mui/material";
import React,{useEffect} from "react";
import "./PackagesListing.scss";
import DrawerAppBar from "../../components/Global/Navbar/Navbar";
import ImageBg from "../../components/Reusable/ImageBg/ImageBg";
import Footer from "../../components/Global/Footer/Footer";
import packagesListingBg from "../../assets/images/packagesListingBg.png";
import { indianCardData } from "../../Data";
import IndianDestCard from "../../components/Reusable/IndianDestCard/IndianDestCard";
import { useDispatch, useSelector } from "react-redux";
import { getTourListingAction } from "../../reduxState/slices/BuddhaJourneySlices";
const PackagesListing = () => {
  // const stateValue = useSelector(
  //   (e) => e.buddhaJourneySlice.homepage.homepageData
  // );
  const dispatch = useDispatch();
  useEffect(()=>{
    dispatch(getTourListingAction())
  },[]);
  const tourListingData=useSelector((data)=> data.buddhaJourneySlice.tourListing.tourListingData);
  return (
    <Box className="packageListingBlock">
      <DrawerAppBar />
      <ImageBg
        image={packagesListingBg}
        headingText={tourListingData.header}
        placeHolder={tourListingData.searchPlaceholder}
      />
      <Box className="leftRightMargin">
        <Box className="tourPackagesBlock">
          <Box>
            <Typography variant="boldFourtyEight">{tourListingData.title}</Typography>
          </Box>
          <Box>
            <Typography variant="boldTwentyEightPrimary">{tourListingData.bookmarked}</Typography>
          </Box>
        </Box>
        <Box className="cardCssOverRiding">
          {tourListingData?.packageList?.map((element) => {
            return (
              <IndianDestCard
                key={element.id}
                img={packagesListingBg}
                CardHeading={element.title}
                Rating={element.rating}
                days={element.batchDay}
                para={element.description}
                rate={element.amount}
                priceAndBook={true}
              />
            );
          })}
        </Box>
      </Box>

      <Footer />
    </Box>
  );
};

export default PackagesListing;
