import React, { useEffect, useState } from "react";
import "./ChatHeader.scss";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box, CardMedia, Modal, Typography } from "@mui/material";
import CompleteDetailsPackage from "../../../../Modal/completeDetailsPackage/CompleteDetailsPackage";
import ChatInfoModal from "../../../../Modal/ChatModuleModal/ChatInfoModal";
import { useDispatch, useSelector } from "react-redux";
import { getDialogFlagAction } from "../../../../reduxState/slices/BuddhaJourneySlices";
const ChatHeader = ({ selectedRoom }) => {
  const dispatch = useDispatch();

  const handleValueChange = (value) => {
    dispatch(getDialogFlagAction(true));
  };

  const chatGroups = useSelector((e) => e.buddhaJourneySlice.chatGroups);
  const [activeRoom, setActiveRoom] = React.useState({chatName: "Welcome Bot", participants: 1});

  useEffect(() => {
    if (selectedRoom) {
      const currentRoom = chatGroups.filter((grp) => grp._id == selectedRoom);
      console.log('room', currentRoom)
      if (currentRoom.length > 0) {
        setActiveRoom(...currentRoom);
      }
    }
  }, [selectedRoom]);

  return (
    <>
      <Box onClick={handleValueChange} className="chat_header">
        <Box className="img_text_group_card">
          <Box className="img_card">
            <CardMedia className="imageBox" image="" title="yellow" />
          </Box>
          <Box className="group_left_text">
            <Typography
              sx={{ color: "#14142B", marginLeft: "1rem" }}
              variant="h5"
              gutterBottom
            >
              {activeRoom && activeRoom.chatName}
              <Typography sx={{ color: "#6E7191" }} variant="body2">
                {activeRoom && activeRoom.participants ? activeRoom.participants : 1}
              </Typography>
            </Typography>
          </Box>
        </Box>
        <Box className="group_icons">
          <MoreVertIcon sx={{ color: "#14142B", marginRight: "1rem" }} />
        </Box>
      </Box>
    </>
  );
};

export default ChatHeader;
