import { Box, Button, CardMedia, Typography } from "@mui/material";
import React, { useEffect } from "react";
import DrawerAppBar from "../../components/Global/Navbar/Navbar";
import "./AboutUs.scss";
import abouttopright from "../../assets/images/abouttopright.svg";
import aboutusbody from "../../assets/images/aboutusbody.svg";
import BlogAdCard from "../../components/Reusable/BlogAdCard/BlogAdCard";
import Footer from "../../components/Global/Footer/Footer";
import FeatureCard from "./component/FeatureCard";
import { useDispatch, useSelector } from "react-redux";
import {
  getAboutDataAction,
  getaboutusAction,
  postCreateAboutusAction,
  successCreateAboutusAction,
} from "../../reduxState/slices/BuddhaJourneySlices";
import Heading from "../../components/Reusable/Heading/Heading";
import DeveloperCard from "../../components/Reusable/DeveloperCard/DeveloperCard";
const AboutUs = () => {
  const dispatch = useDispatch();
  const stateValue = useSelector(
    (e) => e.buddhaJourneySlice.aboutus.aboutusData
  );
  const emailId = useSelector(
    (r) =>
      r.buddhaJourneySlice?.users?.SignInResponseValue?.data?.userLoggedIn
        ?.emailId
  );
  const aboutApiData = useSelector(
    (e) => e.buddhaJourneySlice.about.getAboutResponseValue
  );
  console.log("16-03-24 04", aboutApiData);
  const updatedData = useSelector((r) => r.buddhaJourneySlice.tokens);
  let token = updatedData?.access?.token;
  window.localStorage.setItem("token", token);
  const dataLogin = useSelector((r) => r.buddhaJourneySlice.users);
  const signInResponse = dataLogin?.SignInResponseValue?.data?.access?.token;
  console.log("18-03-24 09", typeof signInResponse, signInResponse);
  useEffect(() => {
    //TermsofuseData page api call
    dispatch(getaboutusAction());
  }, [dispatch]);
  useEffect(() => {
    dispatch(getAboutDataAction());
  }, []);

  const onclickSubmit = (form) => {
    const createAboutData = {
      bodyData: {
        title: "We help you to discover11",
        titleEnd: "new places",
        img: "/assets/about/aboutBg.png",
        pageName: "About us",
        textData: [
          "Welcome to our travel website, where unforgettable adventures await you!",
          "We are passionate about creating extraordinary experiences and helping you explore the world’s most captivating destinations. With our extensive knowledge and expertise, we curate remarkable itineraries that cater to your unique interests and preferences. Whether you’re seeking cultural immersion, natural wonders, or thrilling adventures, we have something for everyone.",
          "Our dedicated team of travel experts ensures seamless planning, personalized attention, and impeccable service throughout your journey.Trust us to guide you through the enchanting landscapes, vibrant cultures, and hidden gems of our handpicked destinations. ",
          "Embark on a transformative travel experience with us and create memories that will last a lifetime. Let your wanderlust take flight and let us be your trusted companion on this remarkable journey.Welcome to our travel website, where unforgettable adventures await you!We are passionate about creating extraordinary experiences and helping you explore the world’s most captivating destinations. With our extensive knowledge and expertise, we curate remarkable itineraries that cater to your unique interests and preferences. Whether you’re seeking cultural immersion, natural wonders, or thrilling adventures, we have something for everyone.",
          "Our dedicated team of travel experts ensures seamless planning, personalized attention, and impeccable service throughout your journey.Trust us to guide you through the enchanting landscapes, vibrant cultures, and hidden gems of our handpicked destinations.",
        ],
        aboutUsFeature: [
          {
            id: "1",
            img: "./assets/about/transport.png",
            title: "Private Trasport",
            para: "Ensure a comfortable and personalized experience as you explore destinations. Trust us to enhance your journey with reliable, private transport options, ensuring a hassle-free and enjoyable travel experience aligns to your needs.",
          },
          {
            id: "2",
            img: "./assets/about/transport.png",
            title: "Special Activities",
            para: "Specialize in crafting moments that go beyond the ordinary. Elevate your journey with our handpicked selection of special activities, ensuring a truly exceptional travel experience.",
          },
          {
            id: "3",
            img: "./assets/about/transport.png",
            title: "Value for Money",
            para: " From budget-friendly accommodations to cost-effective travel packages, we ensure that every penny spent translates into a fulfilling and memorable experience. Trust us to provide you with unparalleled value for money as you embark on your adventures.",
          },
          {
            id: "4",
            img: "./assets/about/transport.png",
            title: "Optimal Timing",
            para: "Whether it's seamless flight connections, well-timed activities, or efficient itineraries, we prioritize your time. Trust us to deliver a travel experience where every moment is well-spent, maximizing enjoyment and minimizing hassles. Your journey, perfectly timed.",
          },
        ],
        teamDetails: [
          {
            id: "1",
            img: "./assets/about/transport.png",
            name: "Vasundharan Sharhma",
            designation: "CEO",
          },
          {
            id: "1",
            img: "./assets/about/transport.png",
            name: "Charanuin Sharhma",
            designation: "Marketing Head",
          },
        ],
      },
      token: signInResponse,
    };

    dispatch(postCreateAboutusAction(createAboutData));
    // setTimeout(() => {
    //   dispatch(getAboutDataAction());
    // }, 3000);
  };

  if (Object.keys(aboutApiData).length === 0) return <p>some error</p>;
  else {
    return (
      <>
        <Box className="aboutUsBlock">
          <DrawerAppBar />
          <Box className="topRightImage">
            <CardMedia
              image={abouttopright}
              title="BuddhaJourney"
              className="image"
              component="img"
            />
          </Box>
          {emailId && emailId === "sivavintha23@mailinator.com" && (
            <Button onClick={onclickSubmit} variant="contained">
              Create AboutUs
            </Button>
          )}
          <Box className="heading">
            <Box className="headingAboutUs">
              <Typography variant="boldSixtyEight" color="secondary">
                {aboutApiData[0]?.title}&nbsp;
              </Typography>
              <Typography variant="boldSixtyEight" color="primary">
                {aboutApiData[0]?.titleEnd}
                {console.log(aboutApiData[0]?.title.split().pop(), "splitt")}
              </Typography>
            </Box>
          </Box>
          <Box className="grpImage">
            <CardMedia
              image={aboutusbody}
              title="BuddhaJourney"
              className="bodyImage"
              component="img"
            />
          </Box>
          <Box className="leftRightSpace">
            <Box>
              <Typography variant="boldThirtyTwo" color="primary">
                {aboutApiData[0]?.pageName}
              </Typography>
            </Box>
            <Box className="paragraphBottomMargin">
              {aboutApiData[0]?.textData.map((element, index) => {
                return (
                  <Box mb={2} key={index} mt={2}>
                    <Typography variant="regularEighteen">{element}</Typography>
                  </Box>
                );
              })}
            </Box>

            <FeatureCard data={aboutApiData[0]?.aboutUsFeature} />
            <BlogAdCard />
            <Heading headingDestination="Meet Our Team." />
            <DeveloperCard />
          </Box>
        </Box>

        <Footer />
      </>
    );
  }
};

export default AboutUs;
