import "./AdditionalInfo.scss";
import React, { useEffect } from "react";
import { Box, CardMedia, Typography } from "@mui/material";
// import StarRateIcon from "@mui/icons-material/StarRate";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Carousel from "react-multi-carousel";
import { additionalInfoData } from "../../../../Data";
import { useDispatch, useSelector } from "react-redux";
import { getAdditionalInfoAction } from "../../../../reduxState/slices/BuddhaJourneySlices";
import resortOne from "../../../../assets/images/resortone.png"
import Rating from "./component/Rating";
const AdditionalInfo = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAdditionalInfoAction());
  }, []);
  const stateValue = useSelector(
    (data) => data.buddhaJourneySlice.additionalInfo.additionalInfoData
  );
  console.log(stateValue);
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1600 },
      items: 2,
    },
    desktopMini: {
      breakpoint: { max: 1600, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  if (stateValue?.aboutPackage?.headingAboutPackage === "About Package")
    return (
      <Box className="additional">
        {additionalInfoData.map((item, index) => (
          <Box className="additionalInfo">
            <Box mb={5}>
              <Box mb={2}>
                <Typography variant="boldThirtyTwo">
                  {stateValue.aboutPackage.headingAboutPackage}
                </Typography>
              </Box>
              <Box>
                {stateValue?.aboutPackage?.aboutPackageArrayData?.map(
                  (element, index) => (
                    <Typography key={index} mb={1}>
                      {element}
                    </Typography>
                  )
                )}
              </Box>
            </Box>
            <Box>
              <Box mb={5}>
                <Box mb={2}>
                  <Typography variant="boldThirtyTwo">
                  {stateValue?.bookingPolicy.headingBookingPolicy}
                  </Typography>
                </Box>
                <ul>
                  {stateValue?.bookingPolicy.bookinPolicyArrayData.map((element, index) => (
                    <li key={index}>
                      <Typography variant="regularSixteenLight">
                        {element}
                      </Typography>
                    </li>
                  ))}
                </ul>
              </Box>
            </Box>
            <Box mb={5}>
              <Box mb={2}>
                <Typography variant="boldThirtyTwo">
                  {stateValue?.listOfHotelsAndResorts.heading}
                </Typography>
              </Box>
              <Box className="carouselBlock">
                <Carousel responsive={responsive}>
                  {stateValue?.listOfHotelsAndResorts.arrayData.map((element, index) => (
                    <Box className="listOfHotels" key={index}>
                      <CardMedia
                        component="img"
                        src={resortOne}
                        className="imageOfHotels"
                      ></CardMedia>
                      <Box className="hotelNameAndRating">
                        <Box>
                          <Typography
                            variant="regularTwentyFour"
                            color="secondary"
                          >
                            {element.nameOfHote}
                          </Typography>
                        </Box>
                        <Box ml={3}>
                        <Rating value={element.rating} />
                        </Box>
                      </Box>
                      <Box className="locationAndName">
                        <LocationOnIcon color="primary" />
                        <Typography ml={1}>{element.address}</Typography>
                      </Box>
                    </Box>
                  ))}
                </Carousel>
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
    );
  else return <Box>...Loading</Box>;
};

export default AdditionalInfo;
