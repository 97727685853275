import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { useEffect } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";
import DrawerAppBar from "../../components/Global/Navbar/Navbar";
import "./Faqs.scss";
import Footer from "../../components/Global/Footer/Footer";
import blogbottomimg from "../../assets/images/faqImage.png";
import ImageBg from "../../components/Reusable/ImageBg/ImageBg";
import ContactUs from "../../components/Reusable/ContactUs/ContactUs";
import { useDispatch, useSelector } from "react-redux";
import { getFaqsAction } from "../../reduxState/slices/BuddhaJourneySlices";
const Faqs = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    //FAQS page api call
    dispatch(getFaqsAction());
  }, [dispatch]);
  const stateValue = useSelector((e) => e.buddhaJourneySlice.faqs.faqData);
  if (typeof stateValue.title !== "string") {
    return null;
  }
  return (
    <Box>
      <Box className="faqs">
        <DrawerAppBar />
        <ImageBg
          image={blogbottomimg}
          headingText={stateValue.title}
          placeHolder={stateValue.searchLabel}
        />
        <Box className="bottomWrapper">
          <Box className="title">
            <Typography variant="boldThirtyTwo" color="primary">
              {stateValue.basics.mainTitle}
            </Typography>
          </Box>
          <Box>
            {stateValue.basics.paraData.map((item) => {
              const { id, title } = item;
              return (
                <Box key={id}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>{title}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {item.paras.map((current) => {
                        return (
                          <Box className="para" key={current.id}>
                            <Typography>{current.para1}</Typography>
                          </Box>
                        );
                      })}
                    </AccordionDetails>
                  </Accordion>
                </Box>
              );
            })}
          </Box>
          <Box className="title">
            <Typography variant="boldThirtyTwo" color="primary">
              {stateValue.whyUs.mainTitle}
            </Typography>
          </Box>
          <Box>
            {stateValue.whyUs.paraData.map((item) => {
              return (
                <Box key={item.id}>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>{item.title}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {item.paras.map((current) => {
                        return (
                          <Box className="para" key={current.id}>
                            <Typography>{current.para1}</Typography>
                          </Box>
                        );
                      })}
                    </AccordionDetails>
                  </Accordion>
                </Box>
              );
            })}
          </Box>
        </Box>
        <Box id="contact-us" className="contactUsBlock">
          <ContactUs />
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};

export default Faqs;
