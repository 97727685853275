import React, { useEffect } from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import {
  Facebook,
  Instagram,
  LinkedIn,
  Twitter,
  WhatsApp,
} from "@mui/icons-material";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getFooterDataAction } from "../../../reduxState/slices/BuddhaJourneySlices";

export default function Footer() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getFooterDataAction());
  });
  const stateValue = useSelector(
    (data) => data.buddhaJourneySlice.footer.footerData
  );
  if (typeof stateValue.mainHeading !== "string") {
    return <>...loading</>;
  }
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: (theme) =>
          theme.palette.mode === "light" ? "#333333" : "#333333",
        p: 6,
      }}
    >
      <Container maxWidth="xl">
        <Grid container spacing={12}>
          <Grid item xs={12} sm={6} md={4}>
            <Box sx={{ pb: 3 }}>
              <Typography variant="footerHead" gutterBottom>
                {stateValue.mainHeading}
              </Typography>
            </Box>

            <Typography variant="footerText">{stateValue.textData}</Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <Box pb={3}>
              <Typography variant="footerHead" gutterBottom>
                {stateValue.explore.head}
              </Typography>
            </Box>
            <Box pb={2}>
              <Link href="/aboutus">
                <Typography variant="footerText">
                  {stateValue.explore.data[0].page}
                </Typography>
              </Link>
            </Box>

            <Box pb={2}>
              <Link href="/faqs#contact-us">
                <Typography variant="footerText">
                  {stateValue.explore.data[1].page}
                </Typography>
              </Link>
            </Box>
            <Box pb={2}>
              <Link href="/bloglisting">
                <Typography variant="footerText">
                  {stateValue.explore.data[2].page}
                </Typography>
              </Link>
            </Box>
            <Box pb={2}>
              <Link href="/favouriteplace">
                <Typography variant="footerText">
                  {stateValue.explore.data[3].page}
                </Typography>
              </Link>
            </Box>
            <Box pb={2}>
              <Link href="/faqs">
                <Typography variant="footerText">
                  {stateValue.explore.data[4].page}
                </Typography>
              </Link>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Box sx={{ pb: 3 }}>
              <Typography variant="footerHead" gutterBottom>
                {stateValue.Legal.head}
              </Typography>
            </Box>
            <Box pb={2}>
              <Link href="/termandcondition">
                <Typography variant="footerText">
                  {stateValue.Legal.data[0].page}
                </Typography>
              </Link>
            </Box>

            <Box pb={2}>
              <Link href="/privacypolicy">
                <Typography variant="footerText">
                  {stateValue.Legal.data[1].page}
                </Typography>
              </Link>
            </Box>
            <Box pb={2}>
              <Link href="/termofuse">
                <Typography variant="footerText">
                  {stateValue.Legal.data[2].page}
                </Typography>
              </Link>
            </Box>
            <Box pb={2}>
              <Link href="/cancellationpolicy">
                <Typography variant="footerText">
                  {stateValue.Legal.data[3].page}
                </Typography>
              </Link>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Box sx={{ pb: 3 }}>
              <Typography variant="footerHead" gutterBottom>
                {stateValue.Contact.head}
              </Typography>
            </Box>
            <Link
              href="https://www.facebook.com/profile.php?id=61558152372021"
              target="_blank"
            >
              <Facebook />
            </Link>
            <Link
              href="https://www.instagram.com/_buddha_journey_/"
              target="_blank"
              sx={{ pl: 1, pr: 1 }}
            >
              <Instagram />
            </Link>
            <Link
              href="https://twitter.com/i/flow/login?redirect_after_login=%2Fbuddha__journey"
              target="_blank"
            >
              <Twitter />
            </Link>
            <Link
              href="https://www.linkedin.com/company/buddha-journey/"
              target="_blank"
              sx={{ pl: 1, pr: 1 }}
            >
              <LinkedIn />
            </Link>
            <Link
              href="https://www.wa.link/du5y08"
              target="_blank"
            >
              <WhatsApp />
            </Link>
            <Box pt={2}>
              <Link
                color="inherit"
                href="https://Buddhagreen.com/"
                sx={{ color: "white" }}
              >
                {stateValue.Contact.data[0].page}
              </Link>{" "}
            </Box>
          </Grid>
        </Grid>
        <Box
          mt={5}
          align="center"
          sx={{ borderTop: 1, borderColor: "white", pt: 3 }}
        >
          <Typography variant="footerText">
         {"@" + new Date().getFullYear() + "  |  " + stateValue.rightReserved}
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}
