import React, { useEffect } from "react";
import "./SignIn.scss";
import { Box, Typography, Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import SignInSignup from "../../SignInSignup";
import { MyInput } from "../../../../components/Global/MyInput/MyInput";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  getloginDataAction,
  postUsersSignInAction,
  successUsersSignInAction,
} from "../../../../reduxState/slices/BuddhaJourneySlices";

const SignIn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const stateValue = useSelector((e) => e.buddhaJourneySlice.login.loginData);
  const loggedInUser = useSelector((r) => r.buddhaJourneySlice.user);
  console.log("15-03-24 100 02", loggedInUser);
  const updatedData = useSelector((r) => r.buddhaJourneySlice.tokens);
  let token = updatedData?.access?.token;
  window.localStorage.setItem("token", token);
  const dataLogin = useSelector((r) => r.buddhaJourneySlice.users);
  const signInResponse = dataLogin?.SignInResponseValue?.message;
  console.log("15-03-24 100 01 call", signInResponse);

  useEffect(() => {
    //TermsofuseData page api call
    dispatch(getloginDataAction());
  }, [dispatch]);
  useEffect(() => {
    // console.log("15-03-24 100 01 call");
    // const token = localStorage.getItem("token");
    // console.log("15-03-24 100 01", token);
    if (signInResponse === "Authenticated successfully") {
      navigate("/");
      // dispatch(successUsersSignInAction([]));
    }
  }, [signInResponse]);
  const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email address").required("Required"),
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]/,
        "Password must contain at least one uppercase, one lowercase, one digit, and one special character"
      ),
  });
  return (
    <Box className="signInblock">
      <Box className="leftPicBlock">
        <SignInSignup />
      </Box>
      <Box className="signup">
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            console.log(values);
            const loginData = {
              emailId: values.email,
              password: values.password,
            };
            dispatch(postUsersSignInAction(loginData));
          }}
        >
          <Form className="innerSignup">
            <Box className="createText">
              <Typography variant="boldFourty">{stateValue?.title}</Typography>
            </Box>
            <Box className="letStarted">
              <Typography variant="regularTwentyFour">
                {stateValue.titleStarted}
              </Typography>
            </Box>
            <Box className="alreadyMember">
              <Typography variant="regular" sx={{ color: "#fff" }}>
                {stateValue.alreadyText}
              </Typography>{" "}
              <Link to="/signup">
                {" "}
                <Typography variant="regular" color="primary">
                  {stateValue.alreadySignText}
                </Typography>
              </Link>
            </Box>
            <Box>
              <MyInput
                id="email"
                name="email"
                label={stateValue.emailIdPlaceholder}
                variant="filled"
              />
            </Box>
            <Box>
              <MyInput
                id="password"
                name="password"
                label={stateValue.Placeholder}
                variant="filled"
              />
            </Box>
            <Box className="forgotYourPass">
              <Link to="/forgot">
                <Typography color="primary">
                  {stateValue.forgotYoutTitle}
                </Typography>
              </Link>
            </Box>
            <Box>
              <Button variant="contained" className="createBtn" type="submit">
                {stateValue.createAccountButton}
              </Button>
            </Box>
          </Form>
        </Formik>
      </Box>
    </Box>
  );
};

export default SignIn;
