import TermAndPolicy from "../../components/Reusable/TermAndPolicy/TermAndPolicy";
import React, { useEffect} from "react";
import { termOfUse } from "../../Data";
import { useDispatch, useSelector } from "react-redux";
import { getTermsofuseAction } from "../../reduxState/slices/BuddhaJourneySlices";
const TermOfUse = () => {
  const dispatch = useDispatch();
  
  useEffect(() => {
    //TermsofuseData page api call
    dispatch(getTermsofuseAction());
  }, [dispatch]);
  
  const stateValue = useSelector(
    (e) => e.buddhaJourneySlice.Termsofuse.TermsofuseData
  );

  if (typeof stateValue.image !== "string")
    return <TermAndPolicy arrayName={termOfUse} />;
  else {
    termOfUse[0] = stateValue;
    return <TermAndPolicy arrayName={termOfUse} />;
  }
};

export default TermOfUse;
