import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import CloseIcon from "@mui/icons-material/Close";
import "./ImageUpload.scss";
import AWS from "aws-sdk";
const ImageUpload = ({ imgurl }) => {
  const [file, setFile] = useState(null);

  useEffect(() => {
    const uploadFile = async () => {
      console.log("09-02-24 call", file);
      const S3_BUCKET = "postpublisher";

      const REGION = "us-east-1";

      AWS.config.update({
        accessKeyId: "AKIA2UC3EP44ICMIRE6Q",
        secretAccessKey: "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",
        region: REGION,
      });
      const s3 = new AWS.S3({
        params: { Bucket: S3_BUCKET },
        region: REGION,
      });

      const params = {
        Bucket: S3_BUCKET,
        Key: file?.name.replace(/ /g, ""),
        Body: file,
        ContentType: file?.type,
      };
      console.log("09-02-24 call 01", params);

      try {
        await s3.putObject(params).promise();

        alert("File uploaded successfully.");
        const s3Url = `https://s3.${AWS.config.region}.amazonaws.com/${params.Bucket}/${params.Key}`;
        console.log("09-02-24 03", s3Url, params);
        imgurl(s3Url);
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    };
    uploadFile();
  }, [file]);

  const handleFileInputChange = (e) => {
    console.log("10-04-24 01", e);

    const files = e.target.files;
    handleFiles(files);

    const selectedFile = e.target.files[0];
    console.log("10-04-24 03", selectedFile);

    setFile(selectedFile);
    console.log("09-02-24 02", selectedFile?.name);
    console.log("09-02-24 02", files);
    // if (selectedFile) {
    //   console.log("09-02-24 05");
    //   uploadFile();
    // }
    // setTimeout(() => {
    //   console.log("09-02-24 04", selectedFile);

    // }, 3000);
  };

  const [droppedImages, setDroppedImages] = useState([]);
  const handleDragOver = (event) => {
    console.log("10-04-24 01", event);

    event.preventDefault();
  };
  const handleDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    handleFiles(files);
  };

  const handleFiles = (files) => {
    console.log("10-04-24 05", files);

    if (files.length > 0) {
      const file = files[0];
      const reader = new FileReader();

      reader.onload = (e) => {
        const imageDataURL = e.target.result;
        console.log("10-04-24 02", imageDataURL);
        const selectedFile = files[0];
        setFile(selectedFile);
        setDroppedImages([imageDataURL]);
      };

      reader.readAsDataURL(file);
    }
  };
  // const handleFileInputChange = (event) => {
  //   const files = event.target.files;
  //   handleFiles(files);
  // };

  const handleDragAndDropBoxClick = () => {
    // Trigger click event on the file input field
    document.getElementById("fileInput").click();
  };
  console.log("09-04-24 01", droppedImages);
  return (
    <Box>
      <Box
        className="dragAndDrop"
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onClick={handleDragAndDropBoxClick}
      >
        <input
          type="file"
          id="fileInput"
          accept="image/*"
          style={{ display: "none" }}
          onChange={handleFileInputChange}
        />
        <Box className="innerDrag">
          <UploadFileIcon />
          <Typography>Drag and drop or &nbsp;</Typography>{" "}
          <Typography color="primary">choose Image</Typography>
          <Typography>&nbsp;to upload</Typography>
        </Box>
      </Box>
      {/* Display dropped images */}
      <div
        style={{
          marginTop: "20px",
          display: "flex",
          flexWrap: "wrap",
          background: "#D6F5F4",
        }}
      >
        {droppedImages?.map((image, index) => (
          <div
            key={index}
            // style={{ marginRight: "10px", marginBottom: "10px",background:"#D6F5" }}
            className="imageByUser"
          >
            <img
              src={image}
              alt={`Dropped ${index}`}
              style={{
                width: "200px",
                height: "200px",
                objectFit: "cover",
              }}
            />
            <Box className="close" onClick={() => setDroppedImages([])}>
              <CloseIcon />
            </Box>
          </div>
        ))}
      </div>
    </Box>
  );
};

export default ImageUpload;
