import React, { useEffect } from "react";
import "./ResetPassword.scss";
import SignInSignup from "../../SignInSignup";
import { MyInput } from "../../../../components/Global/MyInput/MyInput";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Box, Typography, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  getforgotpassDataAction,
  postResetPasswordAction,
  successForgotPasswordAction,
  successVerifyOtpAction,
} from "../../../../reduxState/slices/BuddhaJourneySlices";
import { useNavigate } from "react-router-dom";
// import resetPassword from "../../../../Data/resetPassword.json";
const ResetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const stateValue = useSelector(
    (e) => e.buddhaJourneySlice.forgotpass.forgotpassData
  );
  const resetResponse = useSelector(
    (r) => r.buddhaJourneySlice.users.resetPasswordResponseValue.status
  );
  let search = window.location.search;
  let params = search.split("?")[1];
  useEffect(() => {
    //TermsofuseData page api call
    dispatch(getforgotpassDataAction());
  }, [dispatch]);
  const resetPassword = stateValue;
  console.log(stateValue, "data collected in fogortpass");

  const validationSchema = Yup.object({
    password: Yup.string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]/,
        "Password must contain at least one uppercase, one lowercase, one digit, and one special character"
      ),
    confirmPassword: Yup.string().oneOf(
      [Yup.ref("password"), null],
      "Passwords must match"
    ),
  });
  useEffect(() => {
    if (resetResponse === true) {
      let search = window.location.search;
      let params = search.split("?")[1];
      navigate(`/signin?${params}`);
    }
  }, [resetResponse]);
  return (
    <Box className="resetBlock">
      <Box className="leftPicBlock">
        <SignInSignup />
      </Box>
      <Box className="signup">
        <Formik
          initialValues={{
            password: "",
            confirmPassword: "",
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            const resetData = {
              emailId: params,
              password: values.confirmPassword,
            };
            console.log(values);
            dispatch(postResetPasswordAction(resetData));
            dispatch(successVerifyOtpAction([]));
            dispatch(successForgotPasswordAction([]));
          }}
        >
          <Form className="innerSignup">
            <Box className="createText">
              <Typography variant="boldFourty">
                {resetPassword.title}
              </Typography>
            </Box>
            <Box className="letStarted">
              <Typography variant="regularTwentyFour">
                {resetPassword.titleStarted}
              </Typography>
            </Box>

            <Box>
              <MyInput
                id="password"
                name="password"
                label={resetPassword.passowordPlaceholder}
                variant="filled"
                type="password"
              />
            </Box>
            <Box>
              <MyInput
                id="confirmPassword"
                name="confirmPassword"
                label={resetPassword.confirmCassowordPlaceholder}
                variant="filled"
                type="confirmPassword"
              />
            </Box>

            <Box>
              <Button variant="contained" className="createBtn" type="submit">
                {resetPassword.resetbtn}
              </Button>
            </Box>
          </Form>
        </Formik>
      </Box>
    </Box>
  );
};

export default ResetPassword;
