import React from "react";
import "./AboutPageCreation.scss";
import { Box, Button, Typography } from "@mui/material";

import InputField from "../ComponentsForCreation/InputField/InputField";
import ImageUpload from "../ComponentsForCreation/ImageUpload/ImageUpload";
import DescriptionField from "../ComponentsForCreation/DescriptionField/DescriptionField";
import TitleBox from "../ComponentsForCreation/TitleBox/TitleBox";
import { Form, Formik } from "formik";

const AboutPageCreation = () => {
  return (
    <Box className="AboutPageCreation">
      <Box>
        <Typography variant="semiBoldThirtyTwo"> About Page</Typography>
      </Box>
      <Box className="viewAndManage">
        <Typography>View and manage all post Here</Typography>
      </Box>

      <Formik
        initialValues={{
          firstTitle: "",
          secondTitle: "",
          thirdTitle: "",
          firstTitle: "",
          aboutDesc: "",
          aboutTitle: "",
          aboutDesc1: "",
        }}
        // validationSchema={userDetailValidationSchema}
        onSubmit={(values) => {
          console.log("values11", values);
        }}
      >
        <Form>
          <Box className="mainTitle">
            <Typography variant="boldSixteen">
              Add Main Title and Image
            </Typography>
            <Box className="inputFieldsSize">
              <InputField
                id="firstTitle"
                name="firstTitle"
                variant="outlined"
                label="First Title"
              />
              <InputField
                id="secondTitle"
                name="secondTitle"
                variant="outlined"
                label="Second Title"
              />
              <InputField
                id="thirdTitle"
                name="thirdTitle"
                variant="outlined"
                label="Page Name"
              />
            </Box>

            <DescriptionField
              id="aboutDesc"
              label="Description"
              name="aboutDesc"
              variant="outlined"
            />
            <ImageUpload />

            <TitleBox title="About Us Features Items" />
            <ImageUpload />
            <InputField
              id="aboutTitle"
              name="aboutTitle"
              label="Title"
              variant="outlined"
            />

            <DescriptionField
              id="aboutDesc1"
              label="Description"
              name="aboutDesc1"
              variant="outlined"
            />
          </Box>
          <Box className="submit_reset">
            <Button
              sx={{
                backgroundColor: "white",
                color: "#0a9e88",
                border: "1px solid #0a9e88",
              }}
              type="reset"
              variant="formButton"
            >
              Reset
            </Button>
            <Button type="submit" variant="formButton">
              Submit
            </Button>
          </Box>
        </Form>
      </Formik>
    </Box>
  );
};

export default AboutPageCreation;
