import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import "./ChatInfoModal.scss";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import ApartmentIcon from "@mui/icons-material/Apartment";
import ContactsIcon from "@mui/icons-material/Contacts";
import { Link } from "react-router-dom";
import { CardMedia } from "@mui/material";
import TextField from "@mui/material/TextField";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import {
  getAdminChatFlagAction,
  getAdminGroupChatAction,
  getDialogFlagAction,
} from "../../reduxState/slices/BuddhaJourneySlices";
import { useDispatch, useSelector } from "react-redux";
import AddBoxIcon from "@mui/icons-material/AddBox";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: "55%",
  bgcolor: "background.paper",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
  height: "80%",
  overflowY: "auto",
};
const styles = {
  textField: {
    width: "300px",
  },
  input: {
    borderBottom: "2px solid #0A9E88",
    "&::placeholder": {
      color: "#0A9E88", // Change the placeholder text color as needed
    },
  },
};
const ChatInfoModal = () => {
  const dispatch = useDispatch();
  const createGroup = useSelector(
    (e) => e.buddhaJourneySlice?.AdminChatGroup?.createGroup
  );

  const handleClick = () => {
    dispatch(getDialogFlagAction(false));
  };
  const handleClickSubmit = () => {
    dispatch(getAdminGroupChatAction({ AdminChatGroup: { createGroup: {} } }));
  };
  const handleChangeModal = () => {
    // dispatch(getDialogFlagAction(true));
    dispatch(getAdminGroupChatAction("success"));
  };
  return (
    <Box className="Chat_info_model">
      <Box sx={style}>
        <Box className="Info_img" sx={{ textAlign: "center" }}>
          <Typography variant="footerHead" color="primary">
            {createGroup && createGroup === "success"
              ? "Create Group"
              : "Group Info"}
          </Typography>
          <CardMedia className="imageBox" image="" title="yellow" />

          {createGroup && createGroup != "success" ? (
            <Typography
              sx={{ color: "#14142B", marginLeft: "1rem" }}
              variant="h5"
              gutterBottom
            >
              Work Group
              <Typography sx={{ color: "#6E7191" }} variant="body2">
                Participants 4
              </Typography>
            </Typography>
          ) : (
            <TextField
              disabled={createGroup && createGroup != "success" ? true : false}
              id="standard-disabled"
              label=""
              variant="standard"
              InputProps={{
                style: {
                  backgroundColor: "transparent", // Set background color to transparent
                  textAlign: "center", // Center align placeholder text
                  borderBottom: "2px solid #0A9E88",
                },
              }}
            />
          )}
        </Box>

        <Box className="desc_section">
          <Typography variant="boldSixteen">Add group description</Typography>
          <Box className="Add_grp_desc">
            <Box className="input_desc">
              <TextField
                id="standard-basic"
                label="Description"
                variant="standard"
                style={styles.textField}
                InputProps={{
                  style: styles.input,
                }}
                // maxRows={3}
              />
            </Box>
            <Box className="btn_desc" mt={1}>
              <Button variant="contained" sx={{ wordWrap: "break-word" }}>
                SAVE
              </Button>
            </Box>
          </Box>
        </Box>

        <Box className="addUser_section">
          <Box className="icon_text">
            <Box className="icons">
              <GroupAddIcon sx={{ color: "#0A9E88" }} />
            </Box>
            <Box className="text">
              <Typography variant="boldSixteen">Add members</Typography>
            </Box>
          </Box>

          <Box
            onClick={() => dispatch(getAdminChatFlagAction(true))}
            className="Add_members"
          >
            <Box className="members_list">
              <Box className="img_card">
                <CardMedia className="imageBox" image="" title="yellow" />
              </Box>
              <Box className="group_left_text">
                <Typography
                  sx={{
                    color: "black",
                    marginLeft: "1rem",
                    fontSize: "15px",
                  }}
                  variant="h6"
                  gutterBottom
                >
                  Aaron Hender...
                  <Typography
                    sx={{ color: "black", fontSize: "10px" }}
                    variant="body2"
                  >
                    I’m available
                  </Typography>
                </Typography>
              </Box>
            </Box>
            <Box className="btn_desc" mt={1}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#6F9E0A",
                  textTransform: "none",
                  wordWrap: "break-word",
                  "&.Mui-disabled": {
                    backgroundColor: "#6F9E0A",
                    color: "white",
                  },
                }}
                disabled
              >
                Group Admin
              </Button>
            </Box>
          </Box>
          <Box className="Add_members">
            <Box className="members_list">
              <Box className="img_card">
                <CardMedia className="imageBox" image="" title="yellow" />
              </Box>
              <Box className="group_left_text">
                <Typography
                  sx={{
                    color: "black",
                    marginLeft: "1rem",
                    fontSize: "15px",
                  }}
                  variant="h6"
                  gutterBottom
                >
                  Aaron Hender...
                  <Typography
                    sx={{ color: "black", fontSize: "10px" }}
                    variant="body2"
                  >
                    I’m available
                  </Typography>
                </Typography>
              </Box>
            </Box>
            <Box className="btn_desc" mt={1}>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#6F9E0A",
                  textTransform: "none",
                  wordWrap: "break-word",
                  "&.Mui-disabled": {
                    backgroundColor: "#6F9E0A",
                    color: "white",
                  },
                }}
                disabled
              >
                Group Admin
              </Button>
            </Box>
          </Box>
        </Box>
        <Box className="lastRow">
          {createGroup && createGroup != "success" && (
            <Box onClick={handleChangeModal} className="exit_icon_txt">
              <Box className="icons">
                <AddBoxIcon sx={{ color: "#0A9E88" }} />
              </Box>
              <Box className="text">
                <Typography sx={{ color: "#0A9E88" }} variant="boldSixteen">
                  Create Group
                </Typography>
              </Box>
            </Box>
          )}
          <Box className="buttonBlock">
            {createGroup && createGroup === "success" ? (
              <Box ml={2}>
                <Button
                  onClick={handleClickSubmit}
                  variant="contained"
                  sx={{ wordWrap: "break-word" }}
                >
                  SUBMIT
                </Button>
              </Box>
            ) : (
              <Box ml={2}>
                <Button
                  onClick={handleClick}
                  variant="contained"
                  sx={{ wordWrap: "break-word" }}
                >
                  CANCEL
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default ChatInfoModal;
