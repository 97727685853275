import { Box, Typography, CardMedia } from "@mui/material";
import React from "react";
import DrawerAppBar from "../../components/Global/Navbar/Navbar";
import TextOverImage from "../../components/Reusable/TextOverImage/TextOverImage";
import Footer from "../../components/Global/Footer/Footer";
import "./BlogOne.scss";
import { useSelector } from "react-redux";
import Carousel from "react-multi-carousel";
import { blogCardData, responsiveIndianCard } from "../../Data.js";
import IndianDestCard from "../../components/Reusable/IndianDestCard/IndianDestCard";
import BlogAdCard from "../../components/Reusable/BlogAdCard/BlogAdCard";
import CenterText from "../../components/Reusable/CenterText/CenterText";
import { useParams } from "react-router-dom";
import BlogTwo from "../BlogTwo/BlogTwo.jsx";
import BlogThree from "../BlogThree/BlogThree.jsx";
import BlogSix from "../BlogSix/BlogSix.jsx";
import BlogFive from "../BlogFive/BlogFive.jsx";
import BlogSeven from "../BlogSeven/BlogSeven.jsx";
const BlogOne = () => {
  const stateValue = useSelector(
    (e) => e.buddhaJourneySlice.blogList.blogListValue
  );
  console.log(stateValue, "data collected in blogone");
  const { id, category } = useParams();

  if (category === "typeOne")
    return (
      <Box className="blogOneBlock">
        <DrawerAppBar />

        <Box className="imgBlock">
          <Box>
            <TextOverImage
              pageHeading={stateValue.cardData[id].blogHeading}
              img={blogCardData.cardData[0].firstImage}
            />
          </Box>
        </Box>
        <Box className="upperImgTexBlock">
          <Box className="textImgBlock">
            <Box mb={4} className="firstTextBlock">
              <Typography color="secondary" variant="regularTwentyFour">
                {stateValue.cardData[id].paraOne}
              </Typography>
            </Box>
            <Box>
              <CardMedia
                image={blogCardData.cardData[0].img}
                title="BuddhaJourney"
                component="img"
              />
            </Box>
            <Box mt={4}>
              <Typography color="secondary" variant="regularTwentyFour">
                {stateValue.cardData[id].paraTwo}
              </Typography>
            </Box>
          </Box>
        </Box>
        <CenterText />
        <Box className="bloglistMarginBottom">
          <Carousel responsive={responsiveIndianCard}>
            {blogCardData?.cardData.map((element) => {
              return (
                <Box>
                  <IndianDestCard
                    key={element.id}
                    img={element.img}
                    para={element.para}
                    Rating={element.Rating}
                    CardHeading={element.CardHeading}
                    isTrue={element.isTrue}
                  />
                </Box>
              );
            })}
          </Carousel>
        </Box>
        <Box className="blogAd">
          <BlogAdCard />
        </Box>

        <Footer />
      </Box>
    );
  else if (category === "typeTwo") return <BlogTwo />;
  else if (category === "typeThree") return <BlogThree />;
  else if (category === "typeFour") return <BlogThree />;
  else if (category === "typeFive") return <BlogFive />;
  else if (category === "typeSix") return <BlogSix />;
  else if (category === "typeSeven") return <BlogSeven />;
};

export default BlogOne;
