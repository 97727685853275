import { createSlice } from "@reduxjs/toolkit";

export const intialStateData = {
  AdminChatGroup: {
    createGroup: "",
  },
  helpBox: false,
  dialogFlag: false,
  adminChatFlag: false,
  chatAttachmentFlag: false,
  attachFileData: null,
  users: {
    SignUpResponseValue: [],
    SignInResponseValue: [],
    ForgotPasswordResponseValue: [],
    VerifyOtpResponseValue: [],
    resetPasswordResponseValue: [],
  },
  user: null,
  tokens: {
    access: null,
    refresh: null,
  },
  blog: {
    getBlogResponseValue: [],
  },
  about: {
    getAboutResponseValue: [],
  },
  landing: {
    getLandingResponseValue: {},
  },
  createBlog: {
    blog: [],
  },
  createAboutus: {
    aboutus: [],
  },
  createLandingPage: {
    landingpage: [],
  },
  catFact: {
    catFactValue: "",
    loading: false,
  },
  blogList: {
    blogListValue: {},
    loading: false,
  },
  createAccountData: {
    createAccountValue: {},
    loading: false,
  },
  itineraryData: {
    itineraryDataValue: {},
    loading: false,
  },
  forgotPasswordData: {
    forgotPasswordValue: {},
    loading: false,
  },
  homepage: {
    homepageData: {},
    loading: false,
  },
  Termsofuse: {
    TermsofuseData: {},
    loading: false,
  },
  TermAndCondition: {
    TermAndConditionData: {},
    loading: false,
  },
  aboutus: {
    aboutusData: {},
  },
  faqs: {
    faqData: {},
    loading: false,
  },
  privacyPolicy: {
    privacyPolicyData: {},
    loading: false,
  },
  footer: {
    footerData: {},
    loading: false,
  },
  verifyemail: {
    verifyemailData: {},
    loading: false,
  },
  forgotpass: {
    // for reset password page data
    forgotpassData: {},
    loading: false,
  },
  login: {
    loginData: {},
    loading: false,
  },
  favouritePlaces: {
    favouritePlacesData: {},
    loading: false,
  },
  tourListing: {
    tourListingData: {},
    loading: false,
  },
  cancellationPolicy: {
    cancellationPolicyData: {},
    loading: false,
  },
  additionalInfo: {
    additionalInfoData: {},
    loading: false,
  },
  contactInfo: {
    contactInfoData: {},
    loading: false,
  },
  chatUsers: [],
  chatGroups: [],
  chatMessages: [],
};
export const BuddhaJourneySlice = createSlice({
  name: "buddhaJouney",
  initialState: intialStateData,
  reducers: {
    getCatAction: (state, action) => {
      console.log("payload", action);
      state.catFact = {
        ...state.catFact,
        loading: true,
      };
    },
    successCatAction: (state, action) => {
      console.log("success action payload", action.payload.fact);

      state.catFact = {
        ...state.catFact,
        catFactValue: action.payload.fact,
        loading: false,
      };
    },
    getAdminChatFlagAction: (state, action) => {
      state.adminChatFlag = action.payload;
    },
    getChatAttachmentFlagAction: (state, action) => {
      state.chatAttachmentFlag = action.payload;
    },
    getAttachmentFileDataAction: (state, action) => {
      state.attachFileData = action.payload;
    },
    getAdminGroupChatAction: (state, action) => {
      state.AdminChatGroup = {
        ...state.AdminChatGroup,
        createGroup: action.payload,
      };
    },
    getDialogFlagAction: (state, action) => {
      state.dialogFlag = action.payload;
    },
    getHelpBoxAction: (state, action) => {
      state.helpBox = action.payload;
    },
    getBlogDataAction: (state, action) => {
      // state.loader = true;
    },
    getAboutDataAction: (state, action) => {
      // state.loader = true;
    },
    getLandingDataAction: (state, action) => {
      // state.loader = true;
    },
    postUsersSignUpAction: () => {
      // state.loader = true;
    },
    postUsersSignInAction: (state, action) => {
      // state.loader = true;
    },
    postForgotPasswordAction: (state, action) => {
      // state.loader = true;
    },
    postResetPasswordAction: (state, action) => {},
    postVerifyOtpAction: (state, action) => {
      // state.loader = true;
    },
    postCreateBlogAction: (state, action) => {
      // state.loader = true;
    },
    postCreateAboutusAction: (state, action) => {
      // state.loader = true;
    },
    postCreateLandingPageAction: (state, action) => {
      // state.loader = true;
    },
    getBlogListAction: (state, action) => {
      console.log("bloglist acxtion called", action);
      state.blogList = {
        ...state.blogList,
        loading: true,
      };
    },
    getCreateAccountDataAction: (state, action) => {
      console.log("getCreateAccountDataAction called", action);
      state.createAccountData = {
        ...state.createAccountData,
        loading: true,
      };
    },
    getItineraryDataAction: (state, action) => {
      state.itineraryData = {
        ...state.itineraryData,
        loading: true,
      };
    },
    getForgotPasswordDataAction: (state, action) => {
      console.log("bloglist acxtion called", action);
      state.forgotPasswordData = {
        ...state.forgotPasswordData,
        loading: true,
      };
    },
    successUsersSignUpAction: (state, action) => {
      state.users = {
        ...state.users,
        SignUpResponseValue: action.payload,
      };
    },
    successForgotPasswordAction: (state, action) => {
      state.users = {
        ...state.users,
        ForgotPasswordResponseValue: action.payload,
      };
    },
    successPostResetPasswordAction: (state, action) => {
      state.users = {
        ...state.users,
        resetPasswordResponseValue: action.payload,
      };
    },
    successVerifyOtpAction: (state, action) => {
      state.loader = false;
      state.users = {
        ...state.users,
        VerifyOtpResponseValue: action.payload,
      };
    },
    successGetBlogAction: (state, action) => {
      state.loader = false;
      state.blog = {
        ...state.blog,
        getBlogResponseValue: action.payload,
      };
    },
    successGetAboutAction: (state, action) => {
      state.loader = false;
      state.about = {
        ...state.about,
        getAboutResponseValue: action.payload,
      };
    },
    successGetLandingAction: (state, action) => {
      state.loader = false;
      state.landing = {
        ...state.landing,
        getLandingResponseValue: action.payload,
      };
    },
    successBlogListAction: (state, action) => {
      state.blogList = {
        ...state.blogList,
        blogListValue: action.payload,
        loading: false,
      };
    },
    successCreateAccountDataAction: (state, action) => {
      console.log("successCreateAccountDataAction called", action);
      state.createAccountData = {
        ...state.createAccountData,
        createAccountValue: action.payload,
        loading: false,
      };
    },
    successItineraryDataAction: (state, action) => {
      state.itineraryData = {
        ...state.itineraryData,
        itineraryDataValue: action.payload,
        loading: false,
      };
    },
    successForgotPasswordDataAction: (state, action) => {
      state.forgotPasswordData = {
        ...state.forgotPasswordData,
        forgotPasswordValue: action.payload,
        loading: false,
      };
    },
    getHomePageAction: (state) => {
      state.homepage = {
        ...state.homepageData,
        loading: true,
      };
    },
    successHomepageAction: (state, action) => {
      state.homepage = {
        ...state.homepage,
        homepageData: action.payload,
        loading: false,
      };
    },
    getTermsofuseAction: (state) => {
      state.Termsofuse = {
        ...state.TermsofuseData,
        loading: true,
      };
    },
    successTermsofuseActionAction: (state, action) => {
      state.Termsofuse = {
        ...state.Termsofuse,
        TermsofuseData: action.payload,
        loading: false,
      };
    },
    getTermAndConditionAction: (state) => {
      state.TermAndCondition = {
        ...state.TermAndConditionData,
        loading: true,
      };
    },
    successTermAndConditionAction: (state, action) => {
      state.TermAndCondition = {
        ...state.TermAndCondition,
        TermAndConditionData: action.payload,
        loading: false,
      };
    },
    getaboutusAction: (state) => {
      state.aboutus = {
        ...state.aboutusData,
        loading: true,
      };
    },
    successaboutusAction: (state, action) => {
      state.aboutus = {
        ...state.aboutus,
        aboutusData: action.payload,
        loading: false,
      };
    },
    getFaqsAction: (state) => {
      state.faqs = {
        ...state.faqData,
        loading: true,
      };
    },
    successFaqsAction: (state, action) => {
      state.faqs = {
        ...state.faqs,
        faqData: action.payload,
        loading: false,
      };
    },
    getPrivacyPolicyAction: (state) => {
      state.privacyPolicy = {
        ...state.privacyPolicy,
        loading: true,
      };
    },
    successPrivacyPolicyAction: (state, action) => {
      state.privacyPolicy = {
        ...state.privacyPolicy,
        privacyPolicyData: action.payload,
        loading: false,
      };
    },
    getFooterDataAction: (state) => {
      state.footer = {
        ...state.footer,
        loading: true,
      };
    },
    suscessFooterAction: (state, action) => {
      state.footer = {
        ...state.footer,
        footerData: action.payload,
        loading: false,
      };
    },
    getverifyemailDataAction: (state) => {
      state.verifyemail = {
        ...state.verifyemailData,
        loading: true,
      };
    },
    suscessverifyemailAction: (state, action) => {
      state.verifyemail = {
        ...state.verifyemail,
        verifyemailData: action.payload,
        loading: false,
      };
    },
    getforgotpassDataAction: (state) => {
      state.forgotpass = {
        ...state.forgotpassData,
        loading: true,
      };
    },
    successCreateBlogAction: (state, action) => {
      state.createBlog = {
        ...state.createBlog,
        blog: action.payload,
      };
    },
    successCreateAboutusAction: (state, action) => {
      state.createAboutus = {
        ...state.createAboutus,
        aboutus: action.payload,
      };
    },
    successCreateLandingPageAction: (state, action) => {
      state.createLandingPage = {
        ...state.landingpage,
        landingpage: action.payload,
      };
    },
    suscessforgotpassAction: (state, action) => {
      state.forgotpass = {
        ...state.forgotpass,
        forgotpassData: action.payload,
        loading: false,
      };
    },
    getloginDataAction: (state) => {
      state.login = {
        ...state.loginData,
        loading: true,
      };
    },
    suscessloginAction: (state, action) => {
      state.login = {
        ...state.login,
        loginData: action.payload,
        loading: false,
      };
    },
    successUsersSignInAction: (state, action) => {
      state.loader = false;
      state.users = {
        ...state.users,
        SignInResponseValue: action.payload,
      };

      // state.user = action.payload.data.userLoggedIn;
      // state.tokens = {
      //   access: action.payload.data.access,
      //   refresh: action.payload.data.refresh,
      // };
    },
    getFavouritePlacesAction: (state) => {
      state.login = {
        ...state.loginData,
        loading: true,
      };
    },

    SuccessFavouritePlacesAction: (state, action) => {
      state.favouritePlaces = {
        ...state.favouritePlaces,
        favouritePlacesData: action.payload,
        loading: false,
      };
    },

    getTourListingAction: (state) => {
      state.tourListing = {
        ...state.tourListing,
        loading: true,
      };
    },
    successTourListingAction: (state, action) => {
      state.tourListing = {
        ...state.tourListing,
        tourListingData: action.payload,
        loading: false,
      };
    },
    getCancellationPolicyAction: (state) => {
      state.cancellationPolicy = {
        ...state.cancellationPolicy,
        loading: true,
      };
    },
    successCancellationPolicy: (state, action) => {
      state.cancellationPolicy = {
        ...state.cancellationPolicy,
        cancellationPolicyData: action.payload,
        loading: false,
      };
    },
    getAdditionalInfoAction: (state) => {
      state.additionalInfo = {
        ...state.additionalInfo,
        loading: true,
      };
    },
    successAdditionalInfoPolicy: (state, action) => {
      state.additionalInfo = {
        ...state.additionalInfo,
        additionalInfoData: action.payload,
        loading: false,
      };
    },
    getContaInfoAction: (state, action) => {
      state.contactInfo = {
        ...state.contactInfo,
        loading: true,
      };
    },
    successContactInfoAction: (state, action) => {
      state.contactInfo = {
        ...state.contactInfo,
        contactInfoData: action.payload,
        loading: false,
      };
    },
    getChatUsersAction: (state, action) => {},
    successChatUsersAction: (state, action) => {
      state.chatUsers = action.payload;
    },
    getChatGroupsAction: (state, action) => {},
    successChatGroupsAction: (state, action) => {
      state.chatGroups = action.payload;
    },
    getChatMessagesAction: (state, action) => {},
    successChatMessagesAction: (state, action) => {
      state.chatMessages = action.payload;
    },
  },
});

export const {
  getAttachmentFileDataAction,
  getChatAttachmentFlagAction,
  getAdminGroupChatAction,
  getAdminChatFlagAction,
  getDialogFlagAction,
  getHelpBoxAction,
  postCreateLandingPageAction,
  successCreateLandingPageAction,
  successCreateBlogAction,
  successCreateAboutusAction,
  postCreateBlogAction,
  postCreateAboutusAction,
  successGetBlogAction,
  successGetAboutAction,
  successGetLandingAction,
  getBlogDataAction,
  getAboutDataAction,
  getLandingDataAction,
  successVerifyOtpAction,
  postVerifyOtpAction,
  successPostResetPasswordAction,
  postResetPasswordAction,
  successForgotPasswordAction,
  postForgotPasswordAction,
  successUsersSignInAction,
  postUsersSignInAction,
  successUsersSignUpAction,
  postUsersSignUpAction,
  getCatAction,
  successCatAction,
  getBlogListAction,
  getCreateAccountDataAction,
  getItineraryDataAction,
  getForgotPasswordDataAction,
  successBlogListAction,
  successCreateAccountDataAction,
  successForgotPasswordDataAction,
  getHomePageAction,
  successHomepageAction,
  getTermsofuseAction,
  successTermsofuseActionAction,
  getTermAndConditionAction,
  successTermAndConditionAction,
  successItineraryDataAction,
  getaboutusAction,
  successaboutusAction,
  getFaqsAction,
  successFaqsAction,
  getPrivacyPolicyAction,
  successPrivacyPolicyAction,
  getFooterDataAction,
  suscessFooterAction,
  suscessverifyemailAction,
  getverifyemailDataAction,
  suscessforgotpassAction,
  getforgotpassDataAction,
  getloginDataAction,
  suscessloginAction,
  getFavouritePlacesAction,
  SuccessFavouritePlacesAction,
  getTourListingAction,
  successTourListingAction,
  getCancellationPolicyAction,
  successCancellationPolicy,
  getAdditionalInfoAction,
  successAdditionalInfoPolicy,
  getContaInfoAction,
  successContactInfoAction,
  getChatUsersAction,
  successChatUsersAction,
  getChatGroupsAction,
  successChatGroupsAction,
  getChatMessagesAction,
  successChatMessagesAction,
} = BuddhaJourneySlice.actions;
