import React from "react";
import "./ChatAttachmentModal.scss";
import { Box, Typography } from "@mui/material";
import CollectionsIcon from "@mui/icons-material/Collections";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "35%",
  bgcolor: "background.paper",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
  height: "60%",
  overflowY: "auto",
};
const styles = {
  textField: {
    width: "300px",
  },
  input: {
    borderBottom: "2px solid #0A9E88",
    "&::placeholder": {
      color: "#0A9E88", // Change the placeholder text color as needed
    },
  },
};
const ChatAttachmentModal = () => {
  return (
    <>
      <Box className="chat_attach_modal">
        <Box sx={style}>
          <Box className="attach_icons">
            <Box className="gallery_icons">
              <CollectionsIcon />
            </Box>
            <Box className="gallery_icons"></Box>
            <Box className="gallery_icons"></Box>
            <Box className="gallery_icons"></Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ChatAttachmentModal;
