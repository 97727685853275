import React from "react";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarHalfIcon from "@mui/icons-material/StarHalf";
import StarIcon from "@mui/icons-material/Star";
const Rating = ({ value }) => {
  const ratingStar = Array.from({ length: 5 }, (elem, index) => {
    let number = index + 0.5;
    return (
      <span key={index}>
        {value >= index + 1 ? (
          <StarIcon color="primary"/>
        ) : value >= number ? (
          <StarHalfIcon color="primary"/>
        ) : (
          <StarBorderIcon color="primary"/>
        )}
      </span>
    );
  });
  return <div>{ratingStar}</div>;
};

export default Rating;
