import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Bounce, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function ToastBar() {
  //   const dispatch = useDispatch();
  const dataLogin = useSelector((r) => r.buddhaJourneySlice.users);
  const signInResponse = dataLogin?.SignInResponseValue;
  const status = dataLogin?.SignInResponseValue?.status;
  console.log("15-03-24 100 01 call", signInResponse);
  //   const notify = () => toast(`${signInResponse}`);
  const notify = () =>
    status
      ? toast.success(`${signInResponse?.message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        })
      : toast.error(`${signInResponse?.message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
  React.useEffect(() => {
    if (signInResponse?.message) {
      notify();
    }
  }, [signInResponse]);

  return (
    <div>
      <ToastContainer />
    </div>
  );
}
