import { Box, Typography, CardMedia, Button, Modal } from "@mui/material";
import contact from "../../../assets/images/contact.png";
import React, { useState, useEffect } from "react";
import "./ContactUs.scss";
import ThankYou from "../../../Modal/ThankYou/ThankYou";
import * as yup from "yup";
import { MyInput } from "../../Global/MyInput/MyInput";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik } from "formik";
import { getContaInfoAction } from "../../../reduxState/slices/BuddhaJourneySlices";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};
const validationSchema = yup.object({
  username: yup
    .string()
    .required("Name is required")
    .min(3, "Name must be at least 3 characters"),
  email: yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),
  phoneNumber: yup
    .string()
    .matches(/^\d{10}$/, "Invalid phone number")
    .required("Phone number is required"),
});

const ContactUs = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const dispatch = useDispatch();

  useEffect(() => {
    //contact info page api call
    console.log("callid1");
    dispatch(getContaInfoAction());
  }, []);
  const stateValue = useSelector(
    (e) => e.buddhaJourneySlice.contactInfo.contactInfoData
  );
  console.log("statevakue contactInfo", stateValue);
  return (
    <>
      <Box className="contactUsBlock">
        <CardMedia
          component="img"
          src={contact}
          title="contact us"
          className="imageContact"
        />
        <Formik
          initialValues={{
            username: "",
            email: "",
            phoneNumber: "",
          }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            console.log(values);
            handleOpen();
          }}
        >
          <Form className="contactInnerBlock">
            <Box className="feelFreeTextBox">
              <Typography variant="boldFourtyEightWhite">
                {stateValue?.headingText}
              </Typography>
            </Box>
            <Box className="formBlock">
              <MyInput
                id="username"
                name="username"
                label={stateValue.namePlaceHolder}
                variant="filled"
                // className="inputWidth"
              />
              <Box>
                <MyInput
                  id="email"
                  name="email"
                  label={stateValue.emailPlaceHolder}
                  variant="filled"
                />
              </Box>

              <MyInput
                id="phoneNumber"
                name="phoneNumber"
                label={stateValue.mobilePlaceHolder}
                variant="filled"
              />
              <Box className="sumbitButtonBox">
                <Button type="submit" variant="formButton">
                  {stateValue.buttonText}
                </Button>
              </Box>
            </Box>
          </Form>
        </Formik>
      </Box>
      {open && (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <ThankYou />
          </Box>
        </Modal>
      )}
    </>
  );
};

export default ContactUs;
