import { Box, Typography } from "@mui/material";
import React, { useEffect } from "react";
import "./LoadMessageCard.scss";

const LoadMessageCard = () => {
  const [currentDate, setCurrentDate] = React.useState(null);

  useEffect(() => {
    const currentDate = new Date();

    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const month = months[currentDate.getMonth()];
    const day = currentDate.getDate();
    const year = currentDate.getFullYear();

    const formattedDate = `${month} ${day}, ${year}`;

    setCurrentDate(formattedDate);
  }, []);
  return (
    <Box className="load_message">
      <Box className="text">
        <Typography sx={{ color: "#0A9E88" }} variant="h6" gutterBottom>
          Load Earlier Message
        </Typography>
      </Box>
      <Box className="date_box">
        <Typography variant="subtitle1" gutterBottom>
          {currentDate && currentDate}
        </Typography>
      </Box>
    </Box>
  );
};

export default LoadMessageCard;
